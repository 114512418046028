import {
  ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
  ADMIN_BCU_DETAILS_ROUTES,
  ADMIN_BCUS,
  ADMIN_LOGS,
  ADMIN_REFERENCE_VALUES,
  ADMIN_REFERENCE_VALUES_ROUTES,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_USERS,
  DASHBOARD_ROUTES,
  INFORMATION,
  RESOURCES,
  RESOURCES_ROUTES,
  RETIREMENT_ROUTES,
  STATEMENTS,
  TRANSFER_REQUEST,
  TRANSFER_ROUTES,
} from '../../../../structure/routes/routes';
import React from 'react';

export type TabDef = {
  path: string;
  title: string;
  childRoutes?: string[];
  separator?: boolean;
  Badge?: React.FC<any>;
};

export const TABS: TabDef[] = [
  { path: INFORMATION, title: 'Introduction' },
  { 
    path: RESOURCES_ROUTES.DOCS, 
    title: 'Resources',
    childRoutes: [
      RESOURCES_ROUTES.DOCS,
      RESOURCES_ROUTES.FAQ,
      RESOURCES_ROUTES.FAQ_SUBROUTES.ACCOUNT_MANAGEMENT,
      RESOURCES_ROUTES.FAQ_SUBROUTES.BCU,
      RESOURCES_ROUTES.FAQ_SUBROUTES.CARBON,
      RESOURCES_ROUTES.FAQ_SUBROUTES.GENERAL,
      RESOURCES_ROUTES.FAQ_SUBROUTES.TECHNICAL,
      RESOURCES_ROUTES.HISTORY,
      RESOURCES_ROUTES.PRICING
    ],
  },
  { path: STATEMENTS, title: 'Public Retirements', separator: true },
  // user tabs
  { path: DASHBOARD_ROUTES.TRANSACTIONS, title: 'Dashboard' },
  { path: DASHBOARD_ROUTES.REGISTER, title: 'BCU Registration' },
  {
    path: DASHBOARD_ROUTES.TRANSFER,
    title: 'BCU Transfer',
    childRoutes: [
      TRANSFER_ROUTES.INFO_REQUESTS,
      TRANSFER_ROUTES.INFO_REQUEST,
      TRANSFER_ROUTES.CONTACT_LIST,
      TRANSFER_REQUEST,
    ],
  },
  {
    path: DASHBOARD_ROUTES.RETIREMENT,
    title: 'BCU Retirement',
    childRoutes: [RETIREMENT_ROUTES.RETIREMENT, RETIREMENT_ROUTES.CONTACT_LIST],
  },
  // admin tabs
  {
    path: ADMIN_BCUS,
    title: 'BCUs',
    childRoutes: [
      ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS,
      ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY,
    ],
  },
  { path: ADMIN_LOGS, title: 'Logs' },
  {
    path: ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN,
    title: 'Accounts',
    childRoutes: [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS],
  },
  { path: ADMIN_USERS, title: 'Users' },
  {
    path: ADMIN_SUBMISSIONS_LIST,
    title: 'Submissions List',
    childRoutes: [
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.ADMIN_BCU_HISTORY,
      ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE1_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE3_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.ADMIN_BCU_HISTORY,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE1_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE3_INFO,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.ADMIN_BCU_HISTORY,
      ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE,
    ],
  },
  {
    path: ADMIN_REFERENCE_VALUES,
    title: 'Reference Values',
    childRoutes: [
      ADMIN_REFERENCE_VALUES_ROUTES.CERTIFICATION_SCHEMES,
      ADMIN_REFERENCE_VALUES_ROUTES.FEEDSTOCK_CERTIFICATION,
      ADMIN_REFERENCE_VALUES_ROUTES.INCENTIVES,
    ],
  },
];
