import React from 'react';
import { Input, InputProps } from '../../Form/Input';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import './Search.scss';

export const Search: React.FC<Omit<InputProps, 'someBefore'>> = ({
  className,
  onChange,
  ...props
}) => {
  const debouncedOnChange = useDebouncedCallback(
    event => onChange?.(event),
    500,
  );

  return (
    <div className="search">
      <Input
        someBefore={
          <span className="search__icon material-icons-outlined">search</span>
        }
        className={classNames('search__input', className)}
        placeholder="Search"
        onChange={debouncedOnChange}
        {...props}
      />
    </div>
  );
};
