import { FC } from 'react';
import './BCURegistrationFormStyles.scss';
import GeneralForm from './GeneralForm';
import NeatForm from './NeatForm';
import SelfForm from './SelfForm';
import BatchForm from './BatchForm';
import SustainabilityForm from './SustainabilityForm';
import BlendAccountForm from './BlendAccountForm';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import { Alert } from 'antd';

interface IBCURegistrationFormProps {
  handleClick: () => void;
  step: number;
  neatProductSource: string;
  onFileUploadFirst: (file: File) => void;
  onDragAndDropFirst: (file: File | null) => void;
  onFileUploadSecond: (file: File) => void;
  onDragAndDropSecond: (file: File | null) => void;
  fileUpload: File | null;
  dragAndDrop: File | null;
  setBatchNumber: (batchNumber: number) => void;
  batchNumber: number;
  isDisabled: boolean;
}

const BCURegistrationForm: FC<IBCURegistrationFormProps> = ({
  handleClick,
  step,
  neatProductSource,
  onFileUploadFirst,
  onDragAndDropFirst,
  onFileUploadSecond,
  onDragAndDropSecond,
  fileUpload,
  dragAndDrop,
  setBatchNumber,
  batchNumber,
  isDisabled,
}) => {
  const baselines = useAppSelector(selectBaseLines);
  const fields = useAppSelector(state => state.form.fields);
  const selectedBaseline = (baselines || []).find(b => b.id === fields.sustainabilityCertification);
  const hasCORSIAStep = selectedBaseline && selectedBaseline.name.length > 0 && String(selectedBaseline.name).includes('CORSIA');

  return (
    <div className="form-reg">
      <h1 className="header-form">BCU Registration</h1>
      {isDisabled && (<>
        <Alert
          message="This action is forbidden"
          description="Certification expired - please use the contact form for assistance."
          type="error"
        />
      </>)}
      {!isDisabled && (<>
        {step === 1 && (
          <GeneralForm
            handleClick={handleClick}
            onFileUpload={onFileUploadFirst}
            onDragAndDrop={onDragAndDropFirst}
            dragAndDrop={dragAndDrop}
            fileUpload={fileUpload}
          />
        )}
        {step === 2 && (
          <SelfForm handleClick={handleClick} />
        )}
        {step === 3 && (
          <BlendAccountForm 
            handleClick={handleClick} 
            onFileUpload={onFileUploadSecond}
            onDragAndDrop={onDragAndDropSecond}  
          />
        )}
        {step === 4 && (
          <BatchForm
            handleClick={handleClick}
            setBatchNumber={setBatchNumber}
            batchNumber={batchNumber}
          />
        )}
        {step === 5 && hasCORSIAStep && (
          <SustainabilityForm handleClick={handleClick} />
        )}
      </>
    )}
    </div>
  );
};

export default BCURegistrationForm;
