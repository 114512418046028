import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Prisma } from '@prisma/client';

const initialState = {
  status: 'idle',
  credit: null,
  credits: [],
  incomingCredits: [],
};

const creditsReducer = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    setIncomingCredits: (state, action) => {
      state.incomingCredits = action.payload;
    },
    setDetailCredit: (state, action) => {
      state.credit = action.payload;
    },
    updateCredit: (state, action) => {
      const bcu = action.payload;
      const results: any = state.credits.map((item: any): any =>
        item.id === bcu.id ? bcu : item,
      );

      state.credits = results;
      if ((state.credit as any)?.id === bcu.id) {
        state.credit = bcu;
      }
    },
  },
});

export const { setCredits, setDetailCredit, setIncomingCredits, updateCredit } =
  creditsReducer.actions;

export type Transaction = Prisma.CreditTransactionGetPayload<{
  include: { credit: true; newCredit: true; company: true; sender: true };
}>;

export const selectCredits = (state: RootState): any[] => state.credits.credits;
export const selectIncomingCredits = (state: RootState): Transaction[] =>
  state.credits.incomingCredits;
export const selectCreditStatus = (state: RootState): string =>
  state.credits.status;
export const selectCreditDetail = (state: RootState): any =>
  state.credits.credit;

export default creditsReducer.reducer;
