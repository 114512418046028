import { Option } from '../../../../components/Form/Select/Select';
import {
  SubmissionsStatus,
  SubmissionsStatusBE,
  SubmissionsType,
} from '../../../../../structure/request/admin';

export const TYPES: Option[] = [
  {
    value: SubmissionsType.Registration,
    label: SubmissionsType.Registration,
  },
  {
    value: SubmissionsType.ProfileUpdate,
    label: SubmissionsType.ProfileUpdate,
  },
  {
    value: SubmissionsType.BCURetirement,
    label: SubmissionsType.BCURetirement,
  },
  {
    value: SubmissionsType.BCURegistration,
    label: SubmissionsType.BCURegistration,
  },
  { value: SubmissionsType.BCUTransfer, label: SubmissionsType.BCUTransfer },
];

export const STATUSES: Option[] = [
  { value: null, label: 'Status' },
  { value: SubmissionsStatusBE.Approved, label: SubmissionsStatus.Approved },
  { value: SubmissionsStatusBE.Declined, label: SubmissionsStatus.Rejected },
  {
    value: SubmissionsStatusBE.Pending,
    label: SubmissionsStatus.Consideration,
  },
];
