export const transformData = (formData: FormData) => {
  const registerRole = formData.get('registerRole');
  //GenForm
  const productType = formData.get('productType');
  const rawMaterial = formData.get('rawMaterial');
  const certificationScheme = formData.get('certificationScheme');
  const certificationBody = formData.get('certificationBody');
  const feedstockConversionProcess = formData.get('feedstockConversionProcess');
  const originalPOSNumber = formData.get('originalPOSNumber');
  const sustainabilityCertificationNumber = formData.get(
    'sustainabilityCertificationNumber',
  );
  const lowerHeatingValue = formData.get('lowerHeatingValue');
  //SelForm
  const supplierName = formData.get('supplierName');
  const supplierAddress = formData.get('supplierAddress');
  const supplierAddressNumber = formData.get('supplierAddressNumber');
  const supplierZip = formData.get('supplierZip');
  const supplierCity = formData.get('supplierCity');
  const deliveryCountry = formData.get('deliveryCountry');
  const supplierCountry = ' ';
  const chainCustodyModel = formData.get('chainCustodyModel');

  //BlendForm
  const blendingName = productType === 'Supplier' 
    ? formData.get('blendFieldName')
    : formData.get('finalBlendFieldName');
  const blendingAddress = productType === 'Supplier' 
    ? formData.get('blendFieldStreet1')
    : formData.get('finalBlendFieldStreet1');
  const blendingAddressNumber = productType === 'Supplier' 
    ? formData.get('blendFieldStreet2')
    : formData.get('finalBlendFieldStreet2');
  const blendingZip = productType === 'Supplier' 
    ? formData.get('blendFieldPost')
    : formData.get('finalBlendFieldPost');
  const blendingCity = productType === 'Supplier' 
    ? formData.get('blendFieldCity')
    : formData.get('finalBlendFieldCity');
  const blendingCountry = productType === 'Supplier' 
    ? formData.get('blendFieldCountry')
    : formData.get('finalBlendFieldCountry');      
  //BatchForm
  const batchInformation = formData.get('batchInformation');
  //Total
  const LCAGHGValue = formData.get('LCAGHGValue');
  const GHGEmissionReduction = formData.get('GHGEmissionReduction');
  const neatAmountInMJ = formData.get('neatAmountInMJ');
  const volume = formData.get('volume');

  // for (let i = 0; i < 1; i++) {
  //   const batch = {
  //     fieldID: formData.get(`fieldID${i}`),
  //     dateBlend: formData.get(`dateBlend${i}`),
  //     dateProd: formData.get(`dateProd${i}`),
  //     fieldTonnes: formData.get(`fieldTonnes${i}`),
  //     fieldMJ: formData.get(`fieldMJ${i}`),
  //     fieldBatchCountry: formData.get(`fieldBatchCountry${i}`),
  //     fieldCO2: formData.get(`fieldCO2${i}`),
  //     fieldGHG: formData.get(`fieldGHG${i}`),
  //     fieldBatchCountryOf: formData.get(`fieldBatchCountryOf${i}`),
  //     fieldSaf: formData.get(`fieldSaf${i}`),
  //     fieldRsb: formData.get(`fieldRsb${i}`),
  //     fieldDec: formData.get(`fieldDec${i}`),
  //   };
  //   batchInformation.push(batch);
  // }

  //SusForm
  const neatSAFProductionDate = formData.get('neatSAFProductionDate');
  const defaultILUCValue = formData.get('defaultILUCValue');
  const directLandUseChangeDisaggregatedValue = formData.get(
    'directLandUseChangeDisaggregatedValue',
  );
  const landfillDirectionCreditDisaggregatedValue = formData.get(
    'landfillDirectionCreditDisaggregatedValue',
  );
  const recyclingEmissionCreditDisaggregatedValue = formData.get(
    'recyclingEmissionCreditDisaggregatedValue',
  );
  const abatementBaselineScheme = formData.get('abatementBaselineScheme');
  //File
  const posFile = formData.get('posFile');

  if (registerRole !== 'Self-production') {
    // add extra fields
    //NeatForm(new fields)
    const acquisitionDate = formData.get('acquisitionDate');
    const entryInPOChainDate = formData.get('entryInPOChainDate');
    const fieldPoSID = formData.get('fieldPoSID');
    const posIssueDate = formData.get('posIssueDate');
    // new file
    const supplierFile = formData.get('supplierFile');
    const data = {
      //GenForm
      productType,
      rawMaterial,
      certificationScheme,
      certificationBody,
      registerRole,
      feedstockConversionProcess,
      originalPOSNumber,
      sustainabilityCertificationNumber,
      lowerHeatingValue,
      //SelForm
      supplierName,
      supplierAddress,
      supplierAddressNumber,
      supplierZip,
      supplierCity,
      deliveryCountry,
      supplierCountry,
      chainCustodyModel,
      //NeatForm(new fields)
      acquisitionDate,
      entryInPOChainDate,
      fieldPoSID,
      posIssueDate,
      //BlendForm
      blendingName,
      blendingAddress,
      blendingAddressNumber,
      blendingZip,
      blendingCity,
      blendingCountry,
      //BatchForm
      batchInformation,
      //SusForm
      neatSAFProductionDate,
      defaultILUCValue,
      directLandUseChangeDisaggregatedValue,
      landfillDirectionCreditDisaggregatedValue,
      recyclingEmissionCreditDisaggregatedValue,
      //Total
      LCAGHGValue,
      GHGEmissionReduction,
      neatAmountInMJ,
      volume,
      abatementBaselineScheme,
      //File
      supplierFile,
      posFile,
    };

    Object.keys(data).forEach(key => {
      const item = data[key as keyof typeof data];
      if (!item) delete data[key as keyof typeof data];
    });

    return data;
  }

  const data = {
    //GenForm
    productType,
    rawMaterial,
    certificationScheme,
    certificationBody,
    registerRole,
    feedstockConversionProcess,
    originalPOSNumber,
    sustainabilityCertificationNumber,
    lowerHeatingValue,
    //SelForm
    supplierName,
    supplierAddress,
    supplierAddressNumber,
    supplierZip,
    supplierCity,
    deliveryCountry,
    supplierCountry,
    chainCustodyModel,
    //NeatForm(new fields)
    // acquisitionDate,
    // entryInPOChainDate,
    // fieldPoSID,
    // posIssueDate,
    //BlendForm
    blendingName,
    blendingAddress,
    blendingAddressNumber,
    blendingZip,
    blendingCity,
    blendingCountry,
    //BatchForm
    batchInformation,
    //SusForm
    neatSAFProductionDate,
    defaultILUCValue,
    directLandUseChangeDisaggregatedValue,
    landfillDirectionCreditDisaggregatedValue,
    recyclingEmissionCreditDisaggregatedValue,
    //Total
    LCAGHGValue,
    GHGEmissionReduction,
    neatAmountInMJ,
    volume,
    abatementBaselineScheme,
    //File
    // supplierFile,
    posFile,
  };

  Object.keys(data).forEach(key => {
    const item = data[key as keyof typeof data];
    if (!item) delete data[key as keyof typeof data];
  });

  return data;
};
