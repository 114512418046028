import React from 'react';
import { Select } from 'antd';

import './MultiSelectStyles.scss';

export interface Option<T = any> {
  value: T;
  label: string;
}

interface MultiSelectProps {
  label?: string;
  error?: string;
  description?: string;
  required?: boolean;
  placeholder?: string;
  options: Option[];
  value?: string;
  onChange?: (value: any[]) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  error,
  description,
  required = false,
  placeholder,
  options,
  value,
  onChange,
}) => {
  const formattedPlaceholder = placeholder
    ? `${placeholder}${required ? ' *' : ''}`
    : undefined;

  const getValue = () => {
    if (value) {
      return value.split(", ");
    } else {
      return [];
    }
  }

  return (
    <div className="custom-select__wrapper">
      {label && (
        <label className="custom-select__label">
          <span>{label}</span>
          {required && <span>*</span>}
        </label>
      )}
      <Select
        mode="multiple"
        allowClear
        placeholder={formattedPlaceholder}
        value={getValue()}
        onChange={onChange}
        className="custom-select"
        options={options}
        style={{ width: '100%' }}
        dropdownStyle={{ zIndex: 1 }}
      />
      {error && <div className="custom-select__error">{error}</div>}
      {!error && description && (
        <div className="custom-select__description">{description}</div>
      )}
    </div>
  );
};
