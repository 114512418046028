import { Credit } from '@prisma/client';
import { ColumnsType } from 'antd/es/table';

import { formatDate } from '../../../core/models/contstants/format-date';
import { limitOfNumbers } from '../../../../helpers/limitOfNumbers';
import Status from '../../StatusComponent';

const formatVolume = (volume: number) =>
  `${volume ? limitOfNumbers(volume) : 0} BCU`;

export const getColumns = (
  onTitleClick: (item: any) => void,
  isFacilitator: boolean,
): ColumnsType<Credit> => [
  {
    title: 'BCU ID',
    key: 'BCUID',
    render: item => <span onClick={onTitleClick}>{item.BCUID}</span>,
    className: 'cursor-pointer',
    onCell: item => ({
      onClick: () => onTitleClick(item),
    }),
    sorter: (a, b) => a.BCUID.localeCompare(b.BCUID),
  },
  {
    title: 'Product',
    key: 'product',
    render: item => item.productType,
    sorter: (a, b) => a.productType.localeCompare(b.productType), 
  },
  {
    title: 'Feedstock',
    key: 'feedstock',
    render: item => item.rawMaterial,
    sorter: (a, b) => a.rawMaterial.localeCompare(b.rawMaterial),
  },
  {
    title: 'Scheme',
    key: 'scheme',
    render: item => item.certificationScheme,
    sorter: (a, b) => a.certificationScheme.localeCompare(b.certificationScheme), 
  },
  {
    title: 'Date of registration',
    key: 'registrationApproveDate',
    render: item => formatDate(item.registrationApproveDate),
    sorter: (a, b) => {
      const dateA = a.registrationApproveDate ? new Date(a.registrationApproveDate).getTime() : 0;
      const dateB = b.registrationApproveDate ? new Date(b.registrationApproveDate).getTime() : 0;
      return dateA - dateB;
    },    
  },
  {
    title: (
      <span>
        CO<sub>2</sub>eq reduction (t)
      </span>
    ),
    key: 'tonnesOfCO2eq',
    render: item => (item.tonnesOfCO2eq || 0).toFixed(3),
    sorter: (a, b) => a.tonnesOfCO2eq - b.tonnesOfCO2eq,
  },
  {
    title: 'Volume',
    key: 'volume',
    render: item => formatVolume(item.volume),
    sorter: (a, b) => a.volume - b.volume, 
  },
  isFacilitator
    ? {
        title: 'BCU Holder',
        key: 'bcuHolder',
        render: item => item.bcuHolder || '',
        sorter: (a, b) => (a.bcuHolder || '').localeCompare(b.bcuHolder || ''),
      }
    : {
        title: 'Facilitator',
        key: 'facilitator',
        render: item => item.facilitator?.name || '',
        sorter: (a, b) => (a.facilitatorId  || '').localeCompare(b.facilitatorId  || ''),
      },
  {
    title: 'Status',
    key: 'status',
    render: item => <Status statusOfComponent={item.status} />,
  },
];
