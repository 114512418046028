import React, { FC, useMemo } from 'react';
import { Credit, CreditStatus } from '@prisma/client';

import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';
import { SupplierPage } from './Pages/SupplierPage';
import { CorporatePage } from './Pages/CorporatePage';
import { AirlinePage } from './Pages/AirlinePage';
import { ForwarderPage } from './Pages/ForwarderPage';
import { useGetCreditsQuery } from '../../API';
import { IntermediaryPage } from './Pages/IntermediaryPage';
import { TransferContext } from './context';

export interface CreditOption {
  value: string;
  label: string;
  credit: any;
}

export const Transfer: FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const { data: credits } = useGetCreditsQuery();

  const [selectedBcu, setSelectedBcu] = React.useState<CreditOption>();

  const creditsOptions: CreditOption[] = useMemo(
    () =>
      (
        credits?.filter(
          ({ status }: Credit) => status === CreditStatus.Active,
        ) || []
      ).map((credit: any) => ({
        credit,
        value: credit.id,
        label: credit.BCUID,
      })),
    [credits],
  );

  const pageComponents = {
    [CompanyType.Supplier]: SupplierPage,
    [CompanyType.Intermediary]: IntermediaryPage,
    [CompanyType.Airline]: AirlinePage,
    [CompanyType.Corporate]: CorporatePage,
    [CompanyType.Forwarder]: ForwarderPage,
  };

  const companyType = useMemo(
    () =>
      user?.company?.type === CompanyType.Facilitator
        ? selectedBcu?.credit?.company?.type
        : user?.company?.type,
    [user?.company?.type, selectedBcu],
  );

  const PageComponent = useMemo(
    () =>
      pageComponents[companyType as keyof typeof pageComponents] ||
      SupplierPage,
    [pageComponents, companyType],
  );

  return (
    <div>
      <TransferContext.Provider value={{ setSelectedBcu, selectedBcu }}>
        <PageComponent creditsOptions={creditsOptions} />
      </TransferContext.Provider>
    </div>
  );
});
