import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UserRole } from '@prisma/client';
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { MultiValue } from 'react-select';

import { Table } from '../../../../components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import { isInTypes } from '../../../../../helpers/types';
import {
  fetchBaselines,
  fetchBaselinesCreate,
  fetchBaselinesDelete,
  fetchBaselinesEdit,
} from '../../../../../API/credits';
import { ConfirmationPrompt } from '../../../../components/ConfirmationPrompt';
import { Role } from '../../../../../structure/models/user/user';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { useNotification } from '../../../../../hooks/useNotification';

import { getColumns } from './models/columns.constant';

export const CertificationSchemes: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const baseLines = useAppSelector(selectBaseLines);
  const user = useAppSelector(selectUser);

  const isAuditor = useMemo(
    () => (user && user.role === Role.Auditor) || false,
    [user, Role],
  );
  const [localData, setLocalData] = useState<any[]>([]);
  const [editedRowId, setEditedRowId] = useState<number | null>(null);
  const [deletedRowId, setDeletedRowId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onEditClick = (id: number) => setEditedRowId(id);

  const onEditChange = (
    data: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => {
    const { name, value } = data;
    setLocalData(
      localData.map(item =>
        item.id === id
          ? { ...item, [name]: +value ? +value : !value ? 0 : value }
          : item,
      ),
    );
  };

  const onDelete = (id: number) => {
    setDeletedRowId(id);
  };

  const onConfirmationClosed = (state: boolean) => {
    if (!state) {
      return setDeletedRowId(null);
    }

    return dispatch(fetchBaselinesDelete(deletedRowId!))
      .then(() => {
        toast.success({
          message: 'Success',
          description: 'Reference values has been updated',
        });
      })
      .catch(() => {
        toast.error({
          message: 'Error',
          description: 'Reference values update failed',
        });
      })
      .finally(() => {
        setDeletedRowId(null);
        handleCloseModal();
      });
  };

  const onEditSave = () => {
    if (!localData.length) {
      setEditedRowId(null);
      return;
    }
    return dispatch(
      fetchBaselinesEdit(
        editedRowId!,
        localData.find(({ id }) => id === editedRowId),
      ),
    )
      .then(() => {
        toast.success({
          message: 'Success',
          description: 'Reference values has been updated',
        });
      })
      .catch(() => {
        toast.error({
          message: 'Error',
          description: 'Reference values update failed',
        });
      })
      .finally(() => {
        setEditedRowId(null);
      });
  };

  useEffect(() => {
    setLocalData(baseLines);
  }, [baseLines]);

  useEffect(() => {
    dispatch(fetchBaselines());
  }, []);

  const isEdited = !isInTypes(user!.role, [UserRole.Auditor]);

  const columns = useMemo(
    () =>
      getColumns(onEditChange, onDelete, true, isAuditor).filter(
        col => !col.hidden,
      ),
    [getColumns, onEditChange, onDelete, isAuditor],
  );

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCreate = useCallback(
    (values: { scheme: string; baselineValue: number }) => {
      return dispatch(
        fetchBaselinesCreate({
          ...values,
          fuelEmissionFactor: 0,
        }),
      )
        .then(() => {
          toast.success({
            message: 'Success',
            description: 'Reference Values has been created',
          });
        })
        .catch(() => {
          toast.error({
            message: 'Error',
            description: 'Reference Values create failed',
          });
        })
        .finally(() => {
          setDeletedRowId(null);
          handleCloseModal();
        });
    },
    [],
  );

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  return (
    <div>
      <ConfirmationPrompt
        onClosed={onConfirmationClosed}
        show={deletedRowId !== null}>
        <p>Are you sure you want to delete this baseline value?</p>
      </ConfirmationPrompt>
      {isAuditor || (
        <Button
          className="certification-action"
          type="primary"
          onClick={handleOpenModal}>
          <span>Create Certification Scheme</span>
        </Button>
      )}
      <Table
        isEdit={!isAuditor}
        isDefaultTableEdit
        showHeader
        editedRow={editedRowId as any}
        columns={columns}
        data={[...(localData || [])].sort((a, b) => b?.id - a?.id)}
        onEditClick={onEditClick}
        onEditSave={onEditSave}
      />
      <Modal
        title="Create Certification Scheme"
        open={isModalOpen}
        footer={false}
        onCancel={handleCloseModal}>
        <Form onFinish={handleCreate} form={form}>
          <div>
            <Form.Item label="Scheme" name="name" rules={[{ required: true }]}>
              <Input placeholder="Scheme Name" type="text" />
            </Form.Item>
            <Form.Item
              label="Baseline Value"
              name="baselineValue"
              rules={[{ required: true, type: 'number' }]}>
              <InputNumber<number>
                step={0.01}
                formatter={val => (val || 0)?.toString()?.replaceAll(',', '')}
                placeholder="Baseline Value"
              />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
