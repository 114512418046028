export const TABLES = {
  SUBMISSION: "SubmissionTable",
  BCU: "BCUTable",
  ACCOUNTS: "AccountsTable",
  USERS: "Users",
  TRANSACTIONS: "Transactions",
  ADMIN_TRANSACTIONS: "AdminTransactions",
  LOGS: "Logs",
  USER_BALANCE: "userBalance",
  CONTACT_LIST: "contactList",
  STATEMENTS: "statements",
  RETIREMENT: "retirement",
  FEES: "fees", 
};
