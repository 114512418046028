import { DATE_FORMAT, formatDate } from '../../core/models/contstants/format-date';
import { TRetirementStatementTableEntry } from '../Statements';
import { Column } from '../../components/DefaultTable/types';
import classNames from 'classnames';
import { DownloadLink } from '../../components/DownloadLink/DownloadLink';

// width looks weird because it's supposed to align expandable content with the parent table
export const RETIREMENT_STATEMENTS_SUBTABLE = [
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    dataIndex: 'BCUID',
    sorter: true,
    width: '18%',
  },
  {
    title: 'Statement ID',
    keyItem: 'RSID',
    dataIndex: 'RSID',
    sorter: true,
    width: '13.1%',
    render: (item: any) => <span>{item || '-'}</span>,
  },
  {
    title: 'Date of issue',
    keyItem: 'issueDate',
    dataIndex: 'issueDate',
    sorter: true,
    width: '13.1%',
    render: (item: any) => <span>{formatDate(item, DATE_FORMAT, true)}</span>,
  },
  {
    title: 'Product',
    keyItem: 'productType',
    dataIndex: 'productType',
    sorter: true,
    width: '9.8%',
  },
  {
    title: 'Amount BCUs',
    keyItem: 'amount',
    dataIndex: 'amount',
    sorter: true,
    width: '13%',
    render: (item: any) => (
      <span>
        {parseFloat(item || '0').toLocaleString('en-US', {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}
      </span>
    ),
  },
  {
    title: 'Scope 1',
    keyItem: 'scope1',
    dataIndex: 'scope1',
    sorter: true,
    width: '10%',
    render: (item: any) => (
      <span>
        {typeof item === 'string'
          ? item
          : item > 1
          ? `${item} companies`
          : item === 0
          ? '-'
          : `${item} company`}
      </span>
    ),
  },
  {
    title: 'Scope 3',
    keyItem: 'scope3',
    dataIndex: 'scope3',
    sort: true,
    width: '10%',
    render: (item: any, record: any) => (
      <span>
        {[record.scope3, record.scope3Logistic].filter(i => !!i).join(', ')}
      </span>
    ),
  },
  {
    title: 'Certificate',
    keyItem: 'RSID',
    dataIndex: 'sid',
    width: '11.5%',
    render: (item: any, record: any) => {
      if (!item) {
        return null;
      }

      return (
        <span>
          <DownloadLink id={item} name={`${record.RSID}.pdf`} />
        </span>
      );
    },
  },
];

export const RETIREMENT_STATEMENTS_COLUMNS: Column<TRetirementStatementTableEntry>[] =
  [
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      dataIndex: 'BCUID',
      width: '15%',
      render: (item: Partial<TRetirementStatementTableEntry>) => {
        if (item.credits && item.credits.length > 0) {
          return (
            <div className={classNames('c-table__row', { isPointer: true })}>
              <span className="c-table__arrow"></span>
              <strong>
                {item.BCUID} ({item.credits.length})
              </strong>
            </div>
          );
        }
        return <div className="c-table__row">{item.BCUID}</div>;
      },
    },
    {
      title: 'Statement ID',
      keyItem: 'RSID',
      dataIndex: 'RSID',
      width: '15%',
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {item.RSID || '-'}
        </div>
      ),
    },
    {
      title: 'Date of issue',
      keyItem: 'issueDate',
      dataIndex: 'issueDate',
      width: '10%',
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {formatDate(item.issueDate)}
        </div>
      ),
    },
    {
      title: 'Product',
      keyItem: 'productType',
      dataIndex: 'productType',
      width: '10%',
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {item.productType}
        </div>
      ),
    },
    {
      title: 'Amount BCUs',
      keyItem: 'amount',
      dataIndex: 'amount',
      width: '10%',
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {parseFloat(item.amount || '0').toLocaleString('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
        </div>
      ),
    },
    {
      title: 'Scope 1',
      keyItem: 'scope1',
      dataIndex: 'scope1',
      width: '10%',
      render: (item: any) => (
        <div
          className={classNames('c-table__row', {
            isPointer: item.credits?.length,
          })}>
          {typeof item.scope1 === 'string'
            ? item.scope1
            : item.scope1 > 1
            ? `${item.scope1} companies`
            : item.scope1 === 0
            ? '-'
            : `${item.scope1} company`}
        </div>
      ),
    },
    {
      title: 'Scope 3',
      keyItem: 'scope3',
      dataIndex: 'scope3',
      width: '10%',
      render: (item: any) => {
        if (item.credits) {
          if (item.credits.length === 0) {
            return (
              <div
                className={classNames('c-table__row', {
                  isPointer: item.credits?.length,
                })}>
                -
              </div>
            );
          }

          const size = item.scope3 + item.scope3Logistic;

          if (size === 0) {
            return (
              <div
                className={classNames('c-table__row', {
                  isPointer: item.credits?.length,
                })}>
                -
              </div>
            );
          }

          return (
            <div
              className={classNames('c-table__row', {
                isPointer: item.credits?.length,
              })}>
              {`${size} ${size > 1 ? 'companies' : 'company'}`}
            </div>
          );
        }

        if (item.scope3 || item.scope3Logistic) {
          return (
            <div
              className={classNames('c-table__row', {
                isPointer: item.credits?.length,
              })}>
              {[item.scope3, item.scope3Logistic].filter(i => !!i).join(', ')}
            </div>
          );
        }

        return (
          <div
            className={classNames('c-table__row', {
              isPointer: item.credits?.length,
            })}>
            -
          </div>
        );
      },
    },
    {
      title: 'Certificate',
      keyItem: 'RSID',
      dataIndex: 'RSID',
      width: '10%',
      render: (item: any) => {
        if (!item) {
          return null;
        }

        return (
          <div
            className={classNames('c-table__row', {
              isPointer: item.credits?.length,
            })}>
            <DownloadLink id={item} name={`${item}.pdf`} />
          </div>
        );
      },
    },
  ];

export const StatementsColumns = () => RETIREMENT_STATEMENTS_COLUMNS;
