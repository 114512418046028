import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import {
  AccountFee,
  CompanyType,
} from '../../../../../structure/models/company/company';
import { selectedRow } from '../../../../../store/reducers/admin/adminReducer';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { startOfDay, startOfYear, endOfMonth } from 'date-fns';
import {
  useCreateCompanyFeeMutation,
  useDeleteCompanyFeeMutation,
  useEditCompanyFeeMutation,
  useGetCompanyFeesQuery,
} from '../../../../../API';
import { formatDate } from '../../../../core/models/contstants/format-date';
import {
  DatePicker,
  InputNumber,
  Switch,
  Tag,
  Table,
  Typography,
  Popconfirm,
  TablePaginationConfig,
  Button,
  Flex,
  Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useNotification } from '../../../../../hooks/useNotification';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isInTypes } from '../../../../../helpers/types';
import { UserRole } from '@prisma/client';
import { TABLES } from '../../../../../constants/tables';
import { useCombinedFilter } from '../../../../../hooks/useLocalStorageFilter';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

const DateFnsDatePicker = DatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
);
const RangePicker = DateFnsDatePicker.RangePicker;

export const AccountFeeInformation: FC = () => {
  const toast = useNotification();
  const user = useAppSelector(selectUser);
  const account = useAppSelector(selectedRow);

  const [deleteCompanyFee] = useDeleteCompanyFeeMutation();
  const [editCompanyFee] = useEditCompanyFeeMutation();
  const [createCompanyFee] = useCreateCompanyFeeMutation();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  const [page, setPage] = useCombinedFilter(TABLES.FEES, 'page', 1);
  const [limit, setLimit] = useCombinedFilter(TABLES.FEES, 'limit', 50);

  const {
    data: feesResult,
    isLoading,
    isFetching,
  } = useGetCompanyFeesQuery({
    companyId: account!.id,
    page: page || 1,
    perPage: limit || 50,
  });

  const fees: AccountFee[] = feesResult?.results || [];

  useEffect(() => {
    setTableParams(t => ({
      ...t,
      pagination: {
        ...t.pagination,
        total: feesResult?.count || 0,
      },
    }));
  }, [feesResult]);

  const [editedRow, setEditedRow] = useState<AccountFee | null>(null);
  const isEditable = !isInTypes(user!.role, [UserRole.Auditor]);
  const isEditing = (record: AccountFee) => record.id === editedRow?.id;
  const isSupplier = account && account.type === CompanyType.Supplier;
  const supplierFields = [
    'volumeFeePaid',
    'volumeFee',
    'bcuIssued',
    'issueRate',
  ];

  useEffect(() => {
    if (!editedRow) {
      return;
    }

    setEditedRow({
      ...editedRow,
      volumeFee: parseFloat(
        ((editedRow?.issueRate || 0) * (editedRow?.bcuIssued || 0)).toFixed(2),
      ),
    });
  }, [editedRow?.bcuIssued, editedRow?.issueRate]);

  const onEdit = (record: AccountFee) => {
    setEditedRow(JSON.parse(JSON.stringify(record)));
  };

  const onCancel = () => {
    setEditedRow(null);
  };

  const columns: ColumnType<AccountFee>[] = [
    {
      title: 'Period',
      dataIndex: 'period',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${typeof item.period !== 'undefined' ? item.period : ''}`;
        }

        return (
          <>
            <InputNumber
              placeholder="0"
              type="number"
              style={{ maxWidth: 110 }}
              value={editedRow ? editedRow.period : item.period}
              min={1}
              controls={false}
              onChange={value =>
                setEditedRow({
                  ...(editedRow || item),
                  period: parseInt((value || 0).toFixed(0), 10),
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Dates',
      dataIndex: 'date',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${formatDate(item.start!)} - ${formatDate(item.end!)}`;
        }

        return (
          <>
            <div className="d-flex flex-row flex-nowrap align-items-center">
              <RangePicker
                allowEmpty={[false, false]}
                format="dd.MM.yyyy"
                value={[
                  editedRow ? new Date(editedRow.start) : new Date(item.start),
                  editedRow ? new Date(editedRow.end) : new Date(item.end),
                ]}
                onChange={(dates) => {
                  const [start, end] = dates || [null, null];

                  setEditedRow({
                    ...(editedRow || item),
                    start: startOfDay(start || new Date()),
                    end: startOfDay(end || new Date()),
                  });
                }}
              />
            </div>
          </>
        );
      },
    },
    {
      title: 'Annual fee',
      dataIndex: 'accountFee',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${
            typeof item.accountFee !== 'undefined'
              ? item.accountFee.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ''
          }`;
        }

        return (
          <>
            <InputNumber
              type="number"
              placeholder="0.00"
              style={{ maxWidth: 110 }}
              value={editedRow ? editedRow.accountFee : item.accountFee}
              min={0}
              controls={false}
              decimalSeparator="."
              onChange={value =>
                setEditedRow({
                  ...(editedRow || item),
                  accountFee: parseFloat((value || 0).toFixed(2)),
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Annual paid',
      dataIndex: 'accountFeePaid',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return (
            <Tag
              bordered={false}
              color={item.accountFeePaid ? 'green' : 'default'}>
              {item.accountFeePaid ? 'Yes' : 'No'}
            </Tag>
          );
        }

        return (
          <Switch
            defaultChecked={item.accountFeePaid}
            onChange={value =>
              setEditedRow({ ...(editedRow || item), accountFeePaid: value })
            }
          />
        );
      },
    },
    {
      title: 'BCU issued',
      dataIndex: 'bcuIssued',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${
            typeof item.bcuIssued !== 'undefined'
              ? item.bcuIssued.toLocaleString('en-US', {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })
              : ''
          }`;
        }

        return (
          <>
            <InputNumber
              type="number"
              placeholder="0.000"
              value={editedRow ? editedRow.bcuIssued : item.bcuIssued}
              min={0}
              style={{ maxWidth: 110 }}
              addonBefore={
                <Tooltip
                  title="Leave this field blank if you want it to be calculated automatically based on the period dates."
                  color={'#07aca6'}>
                  <InfoCircleOutlined
                    rev=""
                    style={{
                      position: 'relative',
                      zIndex: 1,
                      color: '#5C6D76',
                    }}
                  />
                </Tooltip>
              }
              controls={false}
              required={false}
              onChange={value =>
                setEditedRow({
                  ...(editedRow || item),
                  bcuIssued: value || NaN,
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'BCU issued rate',
      dataIndex: 'issueRate',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${
            typeof item.issueRate !== 'undefined'
              ? item.issueRate.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ''
          }`;
        }

        return (
          <>
            <InputNumber
              type="number"
              placeholder="0.00"
              style={{ maxWidth: 110 }}
              value={editedRow ? editedRow.issueRate : item.issueRate}
              min={0}
              controls={false}
              decimalSeparator="."
              onChange={value =>
                setEditedRow({
                  ...(editedRow || item),
                  issueRate: parseFloat((value || 0).toFixed(2)),
                })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Volume fee',
      dataIndex: 'volumeFee',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return `${
            typeof item.volumeFee !== 'undefined'
              ? item.volumeFee.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ''
          }`;
        }

        return (
          <>
            <InputNumber
              placeholder="0.00"
              type="number"
              style={{ maxWidth: 110 }}
              value={editedRow ? editedRow.volumeFee : item.volumeFee}
              min={0}
              disabled
              controls={false}
              decimalSeparator="."
            />
          </>
        );
      },
    },
    {
      title: 'Volume paid',
      dataIndex: 'volumeFeePaid',
      render: (_: any, item: AccountFee) => {
        const editable = isEditing(item);

        if (!editable) {
          return (
            <Tag
              bordered={false}
              color={item.volumeFeePaid ? 'green' : 'default'}>
              {item.volumeFeePaid ? 'Yes' : 'No'}
            </Tag>
          );
        }

        return (
          <Switch
            defaultChecked={item.volumeFeePaid}
            onChange={value =>
              setEditedRow({ ...(editedRow || item), volumeFeePaid: value })
            }
          />
        );
      },
    },
    ...(isEditable
      ? [
          {
            title: '',
            dataIndex: 'action',
            width: 100,
            render: (_: any, item: AccountFee) => {
              const editable = isEditing(item);

              if (!editable) {
                return (
                  <>
                    <Typography.Link
                      onClick={() => onEdit(item)}
                      style={{ marginRight: 8 }}>
                      <span className="material-icons-outlined">edit</span>
                    </Typography.Link>
                    <Popconfirm
                      title="Are you sure you want to delete this entry?"
                      okText="Yes"
                      okType="danger"
                      cancelText="No"
                      onConfirm={() => onDelete(item.id)}>
                      <Typography.Link>
                        <span className="material-icons-outlined">delete</span>
                      </Typography.Link>
                    </Popconfirm>
                  </>
                );
              }

              return (
                <>
                  <Typography.Link
                    onClick={() => (editedRow ? onSubmit(editedRow) : false)}
                    style={{ marginRight: 8 }}>
                    <span className="material-icons-outlined">check</span>
                  </Typography.Link>
                  <Popconfirm
                    title="Are you sure you want to cancel?"
                    okText="Yes"
                    okType="danger"
                    cancelText="No"
                    onConfirm={() => onCancel()}>
                    <Typography.Link>
                      <span className="material-icons-outlined">cancel</span>
                    </Typography.Link>
                  </Popconfirm>
                </>
              );
            },
          },
        ]
      : []),
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AccountFee> | SorterResult<AccountFee>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const onCreate = async () => {
    createCompanyFee({
      companyId: account.id,
      data: {
        createdAt: new Date(),
        period: 1,
        companyId: account.id,
        start: startOfDay(startOfYear(new Date())),
        end: startOfDay(endOfMonth(new Date())),
        bcuIssued: NaN,
        accountFee: 0,
        volumeFee: 0,
        issueRate: 0,
        accountFeePaid: false,
        volumeFeePaid: false,
      } as Partial<AccountFee>,
    }).then((result: any) => {
      if (!!result.error) {
        toast.error({
          message: 'Error',
          description: 'Account Fee create failed',
        });
      } else {
        toast.success({
          message: 'Success',
          description: 'Account Fee has been created',
        });
      }
    });
  };

  const onSubmit = async (item: AccountFee) => {
    if (!item) {
      return;
    }

    await editCompanyFee({
      companyId: account.id,
      feeId: item.id,
      data: item,
    }).then((result: any) => {
      if (!!result.error) {
        toast.error({
          message: 'Error',
          description: (
            <>
              Account Fee update failed
              {result.error.message.length > 0 ? ':' : ''}
              {result.error.message && (
                <ul style={{ marginLeft: 0, paddingLeft: 0, marginBottom: 0 }}>
                  {result.error.message.map((m: string, idx: number) => (
                    <li key={idx}>{m[0].toUpperCase() + m.substring(1)}</li>
                  ))}
                </ul>
              )}
            </>
          ),
        });
      } else {
        toast.success({
          message: 'Success',
          description: 'Account Fee has been updated',
        });
        setEditedRow(null);
      }
    });
  };

  const onDelete = async (itemId: number) => {
    deleteCompanyFee({
      companyId: account.id,
      feeId: itemId,
    }).then((result: any) => {
      if (!!result.error) {
        handlePageChange(1);
        toast.error({
          message: 'Error',
          description: 'Account Fee delete failed',
        });
      } else {
        toast.success({
          message: 'Success',
          description: 'Account Fee has been deleted',
        });
      }
    });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  return (
    <div
      className="account-detail__table"
      style={{ marginLeft: 20, marginRight: 20 }}>
      {isEditable && (
        <Flex justify="end">
          <Button
            disabled={isLoading || isFetching}
            type="primary"
            shape="round"
            size="large"
            onClick={onCreate}>
            Add new fee
          </Button>
        </Flex>
      )}
      <Table
        rowKey={record => record.id || ''}
        loading={isLoading || isFetching}
        dataSource={fees}
        onChange={handleTableChange}
        pagination={{
          current: page,
          pageSize: limit,
          total: feesResult?.count,
          showSizeChanger: true,
          onChange: handlePageChange,
          onShowSizeChange: (_, size) => handleLimitChange(size),
        }}
        columns={
          isSupplier
            ? columns
            : columns.filter(
                ({ dataIndex }) =>
                  !dataIndex || !supplierFields.includes(dataIndex as string),
              )
        }
        rowClassName="editable-row"
      />
    </div>
  );
};
