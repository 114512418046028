import { ColumnType } from 'antd/lib/table';
import { CreditEvent } from '@prisma/client';

import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../../../core/models/contstants/format-date';
import Status from '../../../../../components/StatusComponent';
import { BCU_EVENTS } from '../../../../../../constants/bcu';

export const bcuLogsColumns: ColumnType<any>[] = [
  {
    title: 'BCU Event Date and time',
    sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    defaultSortOrder: 'ascend',
    dataIndex: 'approveDate',
    render: (_, item) => formatDate(item.createdAt, DATE_WITH_FULL_MONTH),
  },
  {
    title: 'Initiator',
    dataIndex: 'triggeredBy',
    render: (_, item) => {
      if (item?.initiatorName && item.triggeredBy === "User") {
        const [firstName, lastName] = item.initiatorName.split(' ');
        if (lastName) {
          return `${lastName} ${firstName}`;
        } else {
          return firstName;
        }
      }
      return item.triggeredBy;
    },
  },
  {
    title: 'Account Name',
    dataIndex: 'accountName',
    render: (_, item) => item.accountName,
  },
  {
    title: 'BCU Event',
    dataIndex: 'creditEvent',
    render: (_, item) => BCU_EVENTS[item.creditEvent as CreditEvent],
  },
  {
    title: 'BCU ID',
    dataIndex: 'BCUID',
    render: (_, item) => item?.BCUID,
  },
  {
    title: 'Pre Event Status',
    dataIndex: 'preEventStatus',
    render: (_, item) => (
      <Status statusOfComponent={item?.preEventStatus || ''} />
    ),
  },

  {
    title: 'Post Event Status',
    dataIndex: 'postEventStatus',
    render: (_, item) => (
      <Status statusOfComponent={item?.postEventStatus || ''} />
    ),
  },
  {
    title: 'Amount of BCUs',
    dataIndex: 'amount',
    render: (_, item) => item.amount,
  },
  {
    title: 'BCU Holder',
    dataIndex: 'bcuHolder',
    render: (_, item) => item.bcuHolder,
  },
];
