import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, notification, Select } from 'antd';
import { CompanyType, Prisma } from '@prisma/client';

import { useAppDispatch } from '../../../store/hooks';
import { fetchUpdateCreditFacilitator } from '../../../API/credits';
import { Action } from '../UserBalance/UserBalance';

import './Facilitator.scss';

interface FacilitatorProps {
  credit: Prisma.CreditGetPayload<{ include: { company: true } }>;
  contactList: Prisma.ContactGetPayload<{ include: { to: true } }>[];
}

const Facilitator: React.FC<FacilitatorProps> = ({ credit, contactList }) => {
  const [facilitator, setFacilitattor] = useState<string | null>();

  const dispatch = useAppDispatch();

  const facilitatorsOptions = useMemo(
    () =>
      contactList
        ?.filter(
          (contact: Prisma.ContactGetPayload<{ include: { to: true } }>) =>
            contact.to.type === CompanyType.Facilitator,
        )
        .map(
          (contact: Prisma.ContactGetPayload<{ include: { to: true } }>) => ({
            value: contact.to.id,
            label: contact.to.name,
          }),
        ),
    [contactList],
  );

  useEffect(() => {
    setFacilitattor(credit.facilitatorId);
  }, [credit.facilitatorId]);

  const handleManageFacilitator = useCallback(
    async (action: Action, facilitatorId?: string) => {
      try {
        dispatch(
          fetchUpdateCreditFacilitator(credit.id, action, facilitatorId),
        );

        notification.success({
          message: 'BCU Facilitator is updated successfully',
        });
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    },
    [credit, facilitator, dispatch, fetchUpdateCreditFacilitator],
  );

  const handleAssign = useCallback(() => {
    if (facilitator) {
      handleManageFacilitator(Action.Assign, facilitator);
    }
  }, [facilitator, handleManageFacilitator]);

  const handleUnassign = useCallback(() => {
    handleManageFacilitator(Action.Unassign);
  }, [handleManageFacilitator]);

  return (
    <div className="facilitator-tab">
      <div className="facilitator-tab__header">
        <span>BCU ID</span>
        <span>{credit.BCUID}</span>
      </div>
      <div className="facilitator-tab__main">
        <span className="facilitator-tab__title">
          Assign BCU to Facilitator
        </span>
        <div className="facilitator-tab__select-block">
          <Select
            className="select-facilitator"
            options={facilitatorsOptions}
            onChange={value => setFacilitattor(value)}
            value={facilitator}
          />
          <div className="facilitator-tab__actions">
            <Button onClick={handleAssign}>Assign</Button>
            <Button onClick={handleUnassign} danger>
              Stop Assignment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Facilitator);
