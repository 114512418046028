import React, { CSSProperties, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import classNames from 'classnames';
import { useAntPagination } from './helpers';
import { Search } from './Search/Search';
import { useAppDispatch } from '../../../store/hooks';

import './GenericTable.scss';

interface GenericTableProps<T> {
  tableName: string;
  tableTitle: string;
  columns: any[];
  dataSource: T[];
  rowKey: string;
  currentPage?: number;
  pageSize?: number;
  total?: number;
  rowSelection?: any;
  isSearch?: boolean;
  search?: any;
  onClear?: any;
  onSearch?: any;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}

export const GenericTable = <T extends object>({
  tableName,
  tableTitle,
  columns,
  dataSource,
  rowKey,
  rowSelection,
  isSearch,
  search,
  onClear,
  onSearch,
  className,
  style,
  children,
}: GenericTableProps<T>) => {
  const { currentPage, limit, handlePageChange } = useAntPagination(
    tableName,
    10,
  );

  useEffect(() => {
    if (search) {
      handlePageChange(1, 10);
    }
  }, [search]);

  const tablePagination = useMemo(() => {
    return {
      current: currentPage,
      pageSize: limit,
      onChange: handlePageChange,
      showSizeChanger: true,
    };
  }, [currentPage, limit, handlePageChange]);

  return (
    <>
      <div className={classNames('generic-table', className)} style={style}>
        <Table
          className="custom-table"
          columns={columns}
          dataSource={dataSource}
          rowKey={rowKey}
          rowSelection={rowSelection}
          pagination={tablePagination}
          title={() => {
            if (children) {
              return (
                <div className="generic-table_header">
                  <div className="generic-table_left">
                    <h1 className="generic-table_title">{tableTitle}</h1>
                    {children}
                  </div>

                  {isSearch && (
                    <Search
                      isClearable={Boolean(search)}
                      defaultValue={search ? search : ''}
                      onClear={onClear}
                      onChange={onSearch}
                    />
                  )}
                </div>
              );
            }
          }}
        />
      </div>
    </>
  );
};
