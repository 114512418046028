import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CompanyType } from '@prisma/client';
import classNames from 'classnames';

import { SortItem, SortOrder } from './SortItem/SortItem';
import { usePaginator } from './Pagination/PaginationHook';
import { PaginationHookProps } from './Pagination/models/pagination-hook-props.interface';
import { BaseButtonSubmit } from '../Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { Table as AntTable, Spin, Pagination } from 'antd';
import { Modal } from '../Modal/Modal';
import {
  ErrorBlocks,
  FormActionHookProps,
  useFormActions,
} from '../Form/FormHook';
import { useAppDispatch } from '../../../store/hooks';
import {
  setSelectedRow,
  setVisiblePopUp,
} from '../../../store/reducers/admin/adminReducer';

import './EditableTableStyle.scss';
import './TableStyle.scss';

export interface Column<T> {
  title?: string | ReactNode;
  sort?: boolean;
  width?: string;
  sorter?: (a: T, b: T) => number;
  render: (item: T, index: number) => React.ReactElement | string | any;
  keyItem: string;
  hidden?: boolean;
  meta?: {
    style: Object;
  };
}

export interface EditableColumn<T> extends Column<T> {
  renderEdit?: (item: T, index: number) => React.ReactElement;
}

interface Props<T>
  extends React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {
  columns: Column<T>[];
  showHeader?: boolean;
  data: T[];
  rowClass?: string | ((item: T) => string);
  rowKey?: (item: T) => any;
  paginator?: React.ReactElement;
  onSort?: (key: string) => void;
  sort?: SortOrder;
  loading?: boolean;
  onRowClick?: (item: T) => void;
  tableClassName?: string;
  isPopup?: boolean;
  isEdit?: boolean;
  isEditDisabled?: boolean;
  isDefaultTableEdit?: boolean;
  editedRow?: string;
  onEditClick?: (id: any) => void;
  onEditSave?: () => void;
  variant?:
    | 'users'
    | 'logs'
    | 'accounts'
    | 'bcuList'
    | 'statements'
    | 'submissionList'
    | 'referenceValue';
}

interface PaginatedTableProps<TData = any>
  extends Omit<Props<TData>, 'paginator' | 'onSort' | 'sort' | 'data'>,
    Omit<PaginationHookProps<TData>, 'defaultSortBy'> {}

interface EditablePaginatedTableProps<TData = any>
  extends Omit<PaginatedTableProps<TData>, 'columns' | 'onSubmit'>,
    FormActionHookProps {
  getColumns: (
    showErrorBlocks: ErrorBlocks,
    onChange?: (value: { name: string; value: string }, id: string) => void,
    type?: CompanyType,
  ) => EditableColumn<TData>[];
  hasChanges: (formData: FormData, item: TData) => boolean;
  onEditChange?: (value: { name: string; value: string }, id: string) => void;
  onEditSave?: () => void;
  isEditDisabled?: boolean;
  type?: CompanyType;
  tableName?: string;
}

export const Table = <T extends { id: string | number }>({
  className,
  showHeader,
  columns,
  data,
  rowKey = item => item.id,
  paginator,
  onSort,
  sort,
  loading = false,
  onRowClick,
  tableClassName,
  variant,
  isPopup = true,
  isDefaultTableEdit = true,
  isEdit,
  isEditDisabled,
  editedRow,
  onEditSave,
  onEditClick,
  ...props
}: React.PropsWithChildren<Props<T>>) => {
  const dispatch = useAppDispatch();

  const handleShowPopup = (item: T) => {
    if (
      variant &&
      (variant === 'accounts' || variant === 'bcuList' || variant === 'users')
    ) {
      dispatch(setSelectedRow(item));
      dispatch(setVisiblePopUp(true));
    }
  };

  const onItemClick = (item: T) => {
    if (isPopup) handleShowPopup(item);
  };

  const handleTableChange = (sorter: any) => {
    const sortField = Array.isArray(sorter) ? sorter[0]?.field : sorter.field;
    if (onSort) {
      onSort(sortField);
    }
  };

  const formattedColumns = isDefaultTableEdit
    ? columns.map(({ renderEdit, render, ...column }: EditableColumn<T>) => ({
        ...column,
        render: (item: T, index: number) =>
          Boolean(item.id === editedRow && renderEdit)
            ? renderEdit!(item, index)
            : render(item, index),
      }))
    : columns;

  if (isEdit && onEditClick) {
    formattedColumns.push({
      title: 'Edit',
      keyItem: 'edit',
      render: (item: T, index: number) => (
        <div style={{ cursor: 'pointer' }}>
          {editedRow === item.id ? (
            <span
              className={'material-icons-outlined'}
              onClick={onEditSave}
            >
              done
            </span>
          ) : (
            <span
              className="material-icons-outlined"
              onClick={() => onEditClick(item.id)}
            >
              edit
            </span>
          )}
        </div>
      ),
    });
  }
  
  const antdColumns = formattedColumns.map(column => ({
    title: column.title,
    dataIndex: column.keyItem,
    key: column.keyItem,
    width: column?.width,
    render: (text: any, item: T, index: number) => column.render(item, index),
    sorter: column.sort
      ? (a: T, b: T) =>
          a[column.keyItem as keyof T] > b[column.keyItem as keyof T] ? 1 : -1
      : false,
  }));

  return (
    <div className={classNames('table', className)}>
      <div className={classNames('table-wrapper', tableClassName)}>
        <Spin spinning={loading}>
          <AntTable
            columns={antdColumns}
            dataSource={data}
            rowKey={rowKey}
            pagination={false}
            onRow={item => ({
              onClick: () => {
                onRowClick?.(item);
                onItemClick(item);
              },
            })}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(sorter)
            }
          />
        </Spin>

        {paginator && <div className="table-pagination">{paginator}</div>}
      </div>
    </div>
  );
};

export const PaginatedTable = <TData extends { id: string | number }>({
  columns,
  fetchData,
  filters,
  selectData,
  selectStatus,
  tableName,
  ...props
}: React.PropsWithChildren<PaginatedTableProps<TData>>) => {
  const firstSortColumn = columns.find((column: Column<TData>) => column.sort);
  const { data, sort, loading, onChangeSort, paginationNavigation, showBy } =
    usePaginator({
      fetchData,
      filters,
      columns,
      defaultSortBy: firstSortColumn?.keyItem ?? '',
      selectData,
      selectStatus,
      tableName,
    });

  return (
    <Table
      onSort={onChangeSort}
      paginator={
        <>
          {paginationNavigation}
          {showBy}
        </>
      }
      data={data.results}
      sort={sort}
      columns={columns}
      loading={loading}
      showHeader
      {...props}
    />
  );
};

// TODO change this shit
export const EditablePaginatedTable = <TData extends { id: string }>({
  getColumns,
  className,
  type,
  onEditChange,
  hasChanges,
  validate,
  transformAndDispatchData,
  ...props
}: PropsWithChildren<EditablePaginatedTableProps<TData>>) => {
  const [editableItem, setEditableItem] = useState<TData | null>(null);
  const [nextItem, setNextItem] = useState<TData | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { onSubmit, showErrorsBlock, loading, hasErrors } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  const triggerSubmit = () => {
    ref.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  };

  useEffect(() => {
    if (!loading && !hasErrors) {
      setEditableItem(nextItem);
      setNextItem(null);
    }
  }, [loading]);

  const ref: any = useRef();

  const editableColumns = getColumns(showErrorsBlock, onEditChange, type).map(
    ({ renderEdit, render, ...column }: EditableColumn<TData>) => ({
      ...column,
      render: (item: TData, index: number) => {
        return Boolean(item.id === props.editedRow && renderEdit)
          ? renderEdit!(item, index)
          : render(item, index);
      },
    }),
  );

  return (
    <form onSubmit={onSubmit} ref={ref}>
      <PaginatedTable
        className={classNames('editable-table', className)}
        columns={editableColumns}
        {...props}
      />
      <Modal
        header={<h2>Do you want to save your changes?</h2>}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        centered>
        <div className="action-buttons centered">
          <BaseButtonSubmit
            onClick={() => {
              setModalVisible(false);
              setEditableItem(nextItem);
              setNextItem(null);
            }}>
            Don't save
          </BaseButtonSubmit>
          <BaseButtonSubmit
            active
            itemType="submit"
            onClick={() => {
              setModalVisible(false);
              triggerSubmit();
            }}>
            Save
          </BaseButtonSubmit>
        </div>
      </Modal>
    </form>
  );
};
