import { FC, useEffect } from 'react';
import { Table } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectCreditDetail } from '../../../../store/reducers/user/creditsReducer';
import { bcuLogsColumns } from '../../../dashboards/AdminDashboard/BCUList/Tabs/helpers/BcuLogs.columns'; 
import { selectBcuLogs } from '../../../../store/reducers/user/bcuLogsReducer';
import { fetchUserCreditLogs } from '../../../../API/bcu-list';

export const CreditBCUEvents: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectCreditDetail);
  const bcuLogs = useAppSelector(selectBcuLogs);

  useEffect(() => {
    if (id) {
      dispatch(
        fetchUserCreditLogs({
          id,
        }),
      );
    }
  }, [id]);

  return (
    <div>
      <Table
        rowKey={record => record.id || ''}
        dataSource={bcuLogs || []}
        pagination={false}
        columns={bcuLogsColumns}
      />
    </div>
  );
};
