import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import axios from 'axios';
import { mockData } from '../../../app/home/information.mock';

interface InformationState {
  content: string;
}

const initialState: InformationState = {
  content: '',
};

const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    setInformation: (state, action: PayloadAction<string>) => {
      state.content = action.payload;
    },
  },
});

export const { setInformation } = informationSlice.actions;

export const fetchInformation = (): AppThunk => async (dispatch, getState) => {
  const { information } = getState();
  
  if (!information.content) {
    try {
      const response = await axios.get('https://rsb.org/?rest_route=/wp/v2/pages/21791');
      const content = response?.data?.content?.rendered || '';
      dispatch(setInformation(content));
    } catch (e) {
      console.warn(e);
      dispatch(setInformation(mockData.content.rendered));
    }
  }
};

export const selectInformation = (state: RootState) => state.information.content;

export default informationSlice.reducer;
