import { actions } from './fetchProvider/fetch'; 
import { URL_USER_BCU_LOGS } from './urls/urls';
import { AppThunkDispatch } from '../store/store';
import { setBcuLogs, setBcuLogsStatus } from '../store/reducers/user/bcuLogsReducer';

export const fetchUserCreditLogs =
  ({ id, page, limit, ...query }: any) =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setBcuLogsStatus('loading'));

    return actions
      .get(URL_USER_BCU_LOGS.replace(':id', id))
      .then((resp: any) => {
        dispatch(setBcuLogsStatus('idle'));
        dispatch(setBcuLogs(resp.data.data));

        return resp.data.data;
      })
      .catch(e => {
        dispatch(setBcuLogsStatus('failed'));

        throw e;
      });
  };