import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const STORAGE_KEY = 'tableFilters';

const getStoredFilters = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : {};
};

const setStoredFilters = (tableName: string, filterKey: string, value: string | number | null) => {
  const storedFilters = getStoredFilters();
  if (!storedFilters[tableName]) {
    storedFilters[tableName] = {};
  }
  storedFilters[tableName][filterKey] = value;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(storedFilters));
};

export const useCombinedFilter = (
  tableName: string,
  filterKey: string,
  initialValue: string | number | null = null
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] = useState<any>(() => {
    const storedFilters = getStoredFilters();
    const savedValue = storedFilters[tableName]?.[filterKey];
    const urlParam = searchParams.get(filterKey);

    return savedValue !== undefined 
      ? savedValue 
      : urlParam !== null 
        ? urlParam 
        : initialValue;
  });

  useEffect(() => {
    const storedFilters = getStoredFilters();
    const savedValue = storedFilters[tableName]?.[filterKey] ?? initialValue;
    const urlParam = searchParams.get(filterKey);

    setFilterValue(savedValue !== undefined ? savedValue : urlParam);
  }, [tableName, filterKey, initialValue, searchParams]);

  useEffect(() => {
    if (tableName && filterKey) {
      setStoredFilters(tableName, filterKey, filterValue);
      const newSearchParams = new URLSearchParams(searchParams);

      if (filterValue === null || filterValue === '') {
        newSearchParams.delete(filterKey);
      } else {
        newSearchParams.set(filterKey, filterValue);
      }

      setSearchParams(newSearchParams, { replace: true });
    }
  }, [tableName, filterKey, filterValue, searchParams, setSearchParams]);

  return [filterValue, setFilterValue] as const;
};
