import { Link } from 'react-router-dom';
import './StartUsingSectionStyles.scss';

export const StartUsingSection = () => {
  return (
    <div className='start-section'>
      <div className='start-inner'>
        <h3 className='start-inner-title'>
          Start using the RSB Book & Claim Registry
        </h3>

        <button className='start-btn'>
          <Link to="/registration" target="_blank" rel="noopener noreferrer">
            Sign Up
          </Link>
        </button>
      </div>
    </div>
  );
}
