import DownloadIcon from '../../../../assets/DownloadIcon.svg';
import Arrow from '../../../../assets/ArrowRightOutlined.svg';

import './Docs.scss';

const DOCS_LINKS = [
    {
        title: 'RSB Registry Rulebook (on request for RSB partners)',
        icon: null,
    },
    {
        title: 'RSB Registry Video (launch event)',
        icon: Arrow,
        link: 'https://www.youtube.com/watch?v=ZkGjGFbdgJg&ab_channel=RoundtableonSustainableBiomaterials'
    },
    {
        title: 'RSB Registry Presentation (launch event)',
        icon: Arrow,
        link: 'https://rsb.org/wp-content/uploads/2023/11/23-11-02-RSB-Registry-Launch.pdf'
    },
    {
        title: 'RSB Registry Declaration of Commitment (registration company letter)',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2024/12/24-10-08-rsb-bc-registry-declaration-of-commitment_v.2.1.docx'
    },
    {
        title: 'RSB Registry Terms & Conditions',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2023/10/RSB-BC-Registry-Terms-and-Conditions.pdf'
    },
    {
        title: 'RSB Registry Fee Schedule',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2024/02/24-02-23-RSB-BC-Registry-Fee-Schedule-v.1.1.pdf'
    },
    {
        title: 'RSB Book & Claim Manual',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2024/06/RSB-PRO-20-001-001-RSB-Book-and-Claim-Manual-3.0.pdf'
    },
    {
        title: 'Proof of Sustainability (PoS) Excel template',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2023/11/Template-RSB-POS_Book-Claim-v3.xlsx'
    },
    {
        title: 'Proof of Compliance (PoC) Excel template',
        icon: DownloadIcon,
        link: 'https://rsb.org/wp-content/uploads/2023/11/Template-RSB-POC_Book-Claim-v3.xlsx'
    },
    {
        title: 'RSB Book & Claim Trader certification information',
        icon: DownloadIcon,
        link: 'https://rsb.org/certification/certification-schemes/rsb-book-and-claim-trader-certification/'
    }
];

export const Docs: React.FC = () => {
    return (
        <div className="docs-wrap">
            <div className="docs-head-wrap">
                <h3 className="docs-title">Documentation</h3>
                <p className="docs-desc">Detailed Documentation and Resources for Effective Book & Claim Integration</p>
            </div>
            <div className="docs-links">
                {DOCS_LINKS.map(({ title, icon, link }) => {
                    return icon 
                        ? (
                            <a
                                className="docs-link"
                                key={title}
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="docs-link-icon-wrap">
                                    <img className="docs-link-icon" alt="icon" src={icon} />
                                </div>
                                <span>{title}</span>
                            </a>
                        ) : (
                            <div className="docs-link no-decorations">
                                <div className="docs-link-icon-wrap"></div>
                                <span>{title}</span>
                            </div>
                        )
                })}
            </div>
        </div>
    );
};
