import { DeleteOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { MultiValue } from 'react-select';

export const getFeedstockCertsColumns = (
  handleDelete: (id: number) => void,
  onChange?: (
    value: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => void,
  isAuditor?: boolean,
) => {
  const onValueChange = (name: string, value: string, id: number) => {
    const formattedValue = Array.isArray(value)
      ? value.map(item => item.label)
      : value;
    onChange && onChange({ name, value: formattedValue }, id);
  };
  return [
    {
      title: 'Name',
      keyItem: 'name',
      editable: true,
      render: (item: any) => <span>{item.name}</span>,
      renderEdit: (item: any) => (
        <Input
          name="name"
          placeholder="Scheme Name"
          value={item.name}
          onChange={e => onValueChange('name', e.target.value, item.id)}
        />
      ),
    },
    {
      title: 'Remove',
      keyItem: 'name',
      hidden: isAuditor,
      render: (item: any) => (
        <DeleteOutlined onClick={() => handleDelete && handleDelete(item.id)} />
      ),
    },
  ];
};
