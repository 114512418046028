import { Credit, Prisma } from '@prisma/client';
import { createContext, Dispatch } from 'react';
import { CreditOption } from '../transfer/Transfer';

export type RetirementContextType = {
  setSelectedBcu: Dispatch<CreditOption | undefined>;
  selectedBcu: CreditOption | undefined;
};

export const RetirementContext = createContext<RetirementContextType | null>(
  null,
);
