import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../../store/reducers/admin/submissionsReducer';
import { ScopeInfo } from '../../../../components/UserBalance/Tabs/ScopeInfo';

export const AdminScope3Info: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  if (!details) return null;

  const transaction = details?.creditTransaction;
  const credit = transaction?.newCredit || transaction?.credit;
  const scope3Anonymity = transaction?.scope3Anonymity;

  return (
    <>
      {credit && (
        <ScopeInfo
          scope="scope3"
          credit={{
            ...credit,
            scope3Anonymity,
            scope1Company: credit?.scope1Company || transaction?.scope1Company,
            scope3Company: credit?.scope3Company || transaction?.scope3Company,
          }}
        />
      )}
    </>
  );
};
