import React from 'react';
import { useNavigate } from 'react-router-dom';

import Facilitator from '../../Facilitator/Facilitator';
import { useAppSelector } from '../../../../store/hooks';
import { selectCreditDetail } from '../../../../store/reducers/user/creditsReducer';
import { BCU_DETAILS_ROUTES } from '../../../../structure/routes/routes';
import { selectUser } from '../../../../store/reducers/user/userReducer';
import { useGetContactListQuery } from '../../../../API';

export const UserFacilitator: React.FC = () => {
  const credit = useAppSelector(selectCreditDetail);
  const user = useAppSelector(selectUser);

  const { data } = useGetContactListQuery();

  const navigate = useNavigate();

  if (credit?.companyId !== user?.companyId) {
    navigate(BCU_DETAILS_ROUTES.SCOPE3);
  }

  return (
    <div>
      <Facilitator credit={credit} contactList={data} />
    </div>
  );
};
