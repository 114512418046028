import React, { useEffect } from 'react';

import Facilitator from '../../../../components/Facilitator/Facilitator';
import { useAppSelector } from '../../../../../store/hooks';
import { selectAdminCredit } from '../../../../../store/reducers/admin/bcuListReducer';
import { useGetAdminContactListQuery } from '../../../../../API';

export const AdminFacilitator: React.FC = () => {
  const credit = useAppSelector(selectAdminCredit);

  const { data } = useGetAdminContactListQuery({ id: credit.companyId });

  return (
    <div>
      <Facilitator credit={credit} contactList={data} />
    </div>
  );
};
