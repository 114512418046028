import React from 'react';
import './SelectStyle.scss';
import classNames from 'classnames';
import RSelect, { GroupBase, StylesConfig, ThemeConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface Option<T = any> {
  value: T;
  label: string;
}

export const defaultStyles: StylesConfig = {
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    borderRadius: '8px',
    boxShadow: 'none',
  }) as any,
  singleValue: styles => ({
    ...styles,
    color: '#455A64',
  }) as any,
  menu: styles => ({
    ...styles,
    zIndex: 99999,
  }) as any,
};

export const defaultSelectTheme: ThemeConfig = baseTheme => {
  return {
    ...baseTheme,
    borderRadius: 8,
    colors: {
      primary: '#07ACA6',
      primary25: 'rgba(7, 172, 166, .25)',
      primary50: 'rgba(7, 172, 166, .5)',
      primary75: 'rgba(7, 172, 166, .75)',
      danger: '#DE350B',
      dangerLight: '#FFBDAD',
      neutral0: 'hsl(0, 0%, 100%)',
      neutral5: 'hsl(0, 0%, 95%)',
      neutral10: 'hsl(0, 0%, 90%)',
      neutral20: 'hsl(0, 0%, 80%)',
      neutral30: 'hsl(0, 0%, 70%)',
      neutral40: 'hsl(0, 0%, 60%)',
      neutral50: 'hsl(0, 0%, 50%)',
      neutral60: 'hsl(0, 0%, 40%)',
      neutral70: 'hsl(0, 0%, 30%)',
      neutral80: 'hsl(0, 0%, 20%)',
      neutral90: 'hsl(0, 0%, 10%)',
    },
    spacing: {
      ...baseTheme.spacing,
      baseUnit: 6,
    },
  };
};

export const colourStyles: StylesConfig<Option> = {
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    backgroundColor: `rgba(69, 90, 100, 0.1)`,
    borderRadius: '8px',
    border: 'none',
    borderColor: 'transparent !important',
    boxShadow: 'none',
    minHeight: '40px',
  }) as any,
  placeholder: styles => ({
    ...styles,
    color: '#757779',
    fontSize: '14px',
  }) as any,
  singleValue: styles => ({
    ...styles,
    color: '#455A64',
    paddingLeft: '6px',
  }) as any,
  menu: styles => ({
    ...styles,
    zIndex: 2,
  }) as any,
};

export type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = StateManagerProps<Option, IsMulti, Group> & {
  label?: string;
  error?: string;
  description?: string;
  isClearable?: boolean;
  required?: boolean;
  isMulti?: boolean;
};

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  error,
  label = '',
  description,
  isClearable = true,
  required = false,
  placeholder: rawPlaceholder,
  value,
  onChange,
  defaultValue,
  options,
  isMulti,
  ...props
}: SelectProps<Option, IsMulti, Group>) {
  const { className } = props;
  const placeholder = rawPlaceholder
    ? `${rawPlaceholder} ${required ? ' *' : ''}`
    : undefined;

  const onChangeValue = (value: any) => {
    if (onChange) {
      if (value === null) {
        return onChange({ label: '', value: '' } as any, {} as any);
      }
      return onChange(value as any, {} as any);
    }
  };

  return (
    <div className="custom-select__wrapper">
      {label && (
        <label className="custom-select__label">
          <span>{label}</span>
          {required && <span>*</span>}
        </label>
      )}
      <RSelect
        // @ts-ignore
        styles={colourStyles}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={onChangeValue}
        className={classNames('custom-select', className, {
          custom_select_lock: props.isDisabled,
          'custom-select__error-input': error,
        })}
        placeholder={placeholder}
        options={options}
        icon={<div>test</div>}
        {...(defaultValue !== undefined
          ? {
              defaultValue: options?.find(
                (opt: any) => opt.value === defaultValue,
              ),
            }
          : {})}
        {...(value !== undefined
          ? {
              value: !value
                ? placeholder
                : options?.find((opt: any) => opt.value === value),
            }
          : {})}
        {...props}
      />
      {error && <div className="custom-select__error">{error}</div>}
      {!error && description && (
        <div className="custom-select__description">{description}</div>
      )}
    </div>
  );
}
