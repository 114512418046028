import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATEMENTS } from '../../../../structure/routes/routes';

import NextButton from '../../../components/Buttons/NextButton/NextButton';
import { FormikErrors, useFormik } from 'formik';
import {
  DefaultSelect,
  SelectOption,
} from '../../../components/Form/DefaultSelect/DefaultSelect';
import {
  useGetContactListQuery,
  useSaveRetirementMutation,
} from '../../../../API';
import { TransferInfoEntry } from '../../../components/TransferInfoEntry/TransferInfoEntry';
import { InformationRequestForm } from '../../InformationRequestForm';
import { CreditOption } from '../../../transfer/Transfer';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { CompanyType } from '../../../../structure/models/company/company';
import { useNotification } from '../../../../hooks/useNotification';
import {
  AmountSelectorOptions,
  useAmountSelector,
} from '../../../transfer/helpers/use-amount-selector';
import { RetirementContext } from '../../context';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/reducers/user/userReducer';

type FormValues = {
  BCUID: string;
  company: string;
  volume: string;
  volumeType: string;
};

interface CorporatePageProps {
  creditsOptions: CreditOption[];
}

export const ForwarderPage: React.FC<CorporatePageProps> = ({
  creditsOptions,
}) => {
  const transferContext = useContext(RetirementContext);
  const toast = useNotification();
  const navigate = useNavigate();
  const { data: contactListData } = useGetContactListQuery();
  const user = useAppSelector(selectUser);
  const [show, setShow] = useState(false);
  const [selectedBCU, setSelectedBCU] = useState<CreditOption | undefined>(
    transferContext?.selectedBcu,
  );
  const [saveRetirement] = useSaveRetirementMutation();

  useEffect(() => {
    if (transferContext) {
      transferContext.setSelectedBcu(selectedBCU);
    }
  }, [selectedBCU]);

  useEffect(() => {
    handleBcuIdChange(transferContext?.selectedBcu);
  }, [transferContext]);

  const contactListOptions: SelectOption<string>[] = (contactListData || [])
    .filter(
      (entry: any) =>
        entry?.to?.type === CompanyType.Corporate &&
        !entry.isCreatedByFacilitator,
    )
    .map((entry: any) => ({
      label: entry.to?.name,
      value: entry.toId,
    }));

  const form = useFormik<FormValues>({
    initialValues: {
      volume: '',
      volumeType: AmountSelectorOptions.BCU.value,
      BCUID: '',
      company: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validate: values => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.BCUID) {
        errors.BCUID = 'BCU ID field is required.';
      }

      const volumeValue = Number(values.volume.replaceAll(',', ''));

      let maxValue;

      switch (values.volumeType) {
        case AmountSelectorOptions.BCU.value:
          maxValue = parseFloat(selectedBCU?.credit?.volume || '0');
          break;
        case AmountSelectorOptions.CO2.value:
          maxValue = parseFloat(selectedBCU?.credit?.tonnesOfCO2eq || '0');
          break;
        case AmountSelectorOptions.MJ.value:
          maxValue = Math.round(selectedBCU?.credit?.neatAmountInMJ || '0');
          break;
        default:
          maxValue = parseFloat(selectedBCU?.credit?.volume || '0');

          break;
      }

      if (
        values.volume.replaceAll(',', '') === '' ||
        isNaN(volumeValue) ||
        volumeValue < 0 ||
        volumeValue > maxValue
      ) {
        errors.volume = `Value must be a number between 0 and ${
          maxValue.toLocaleString('en-US', {
            maximumFractionDigits:
              values.volumeType === AmountSelectorOptions.MJ.value ? 0 : 3,
            minimumFractionDigits:
              values.volumeType === AmountSelectorOptions.MJ.value ? 0 : 3,
          }) || '?'
        }`;
      }

      return errors;
    },
    onSubmit: async values => {
      const response: any = await saveRetirement({
        id: values.BCUID,
        amount: parseFloat(values.volume.replaceAll(',', '')) || 0,
        amountType: values.volumeType || AmountSelectorOptions.BCU.value,
        ...(values.company ? { scope3: values.company } : {}),
      });

      if (response && !response.error) {
        toast.success({
          message: 'Success',
          description: 'Your retirement request has been sent',
        });
        navigate(STATEMENTS);
        return;
      }

      if (response.error) {
        toast.error({
          message: 'Error',
          description: response.error?.message || 'Unknown error',
        });
        return;
      }
    },
  });

  const handleBcuIdChange = (option: any) => {
    if (option && option.value) {
      form.setFieldValue('BCUID', option.value);
      setSelectedBCU(option);
    } else {
      form.setFieldValue('BCUID', '');
      setSelectedBCU(undefined);
    }
  };

  const handleSubmit = async () => {
    const errors = await form.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    setShow(true);
  };

  const handleClose = async (state: boolean) => {
    setShow(false);

    if (!state) {
      return;
    }

    return form.submitForm();
  };

  const ifRequestAvailable = (
    bcu: CreditOption | undefined,
    node: ReactNode,
  ): ReactNode => {
    if (!bcu || bcu.credit.hasPendingRequests === true) {
      return (
        <div className="c-transfer-info__blocked">
          <span className="material-icons-outlined is-icon-primary">
            schedule
          </span>
          <span>There is an ongoing request for the selected BCU ID</span>
        </div>
      );
    }
    return node;
  };

  const { amountSelector, amountType } = useAmountSelector(
    selectedBCU,
    form,
    undefined,
    'Value to be retired',
  );

  useEffect(() => {
    form.setFieldValue('volumeType', amountType, false);
  }, [amountType]);

  return (
    <>
      <div className="c-transfer">
        <div className="c-transfer__container">
          <form className="c-transfer__form">
            <div className="c-transfer__row">
              <div className="c-transfer__col">
                <div className="c-transfer__form__header">BCU Retirement</div>

                <DefaultSelect
                  inputId="BCUID"
                  name="BCUID"
                  label="BCU ID"
                  required
                  error={form.errors.BCUID}
                  options={creditsOptions as any}
                  onChange={handleBcuIdChange}
                  value={form.values.BCUID}
                />

                {amountSelector}

                <DefaultSelect
                  inputId="company"
                  name="company"
                  label="Scope 3 - corporate end-user"
                  description="Complete if BCU is retired on behalf of a scope 3 corporate end-user"
                  placeholder="Company"
                  isClearable
                  isSearchable
                  options={contactListOptions}
                  onChange={(option: any) =>
                    form.setFieldValue('company', option ? option.value : '')
                  }
                  value={
                    contactListOptions.find(
                      v => v.value === form.values.company,
                    ) || null
                  }
                />
              </div>

              <div className="c-transfer__col">
                {selectedBCU && (
                  <div className="c-transfer-info">
                    <div
                      className={`c-transfer-info__header ${
                        selectedBCU.credit.scope1CompanyId
                          ? 'is-success'
                          : 'is-error'
                      }`}>
                      {selectedBCU.credit.scope1CompanyId
                        ? 'Retirement available'
                        : 'Retirement unavailable'}
                    </div>
                    {!selectedBCU.credit.scope1CompanyId && (
                      <div className="c-transfer-info__subheader">
                        Please request a scope 1 transport service provider to
                        add the required information prior to retirement
                      </div>
                    )}
                    {selectedBCU.credit?.scope1CompanyId &&
                      selectedBCU.credit?.scope3CompanyId &&
                      selectedBCU.credit?.scope3Company.name && (
                        <TransferInfoEntry
                          label="Scope 3 - corporate end-user (on behalf)"
                          icon="info"
                          iconClass="is-icon-primary"
                          value={selectedBCU.credit?.scope3Company.name}
                        />
                      )}
                    <div className="c-transfer-info__header">
                      Scope 1 - transport service provider info
                    </div>
                    <TransferInfoEntry
                      label="Company name"
                      value={selectedBCU.credit.scope1Company?.name}
                      iconClass={
                        selectedBCU.credit.scope1CompanyId
                          ? 'is-icon-success'
                          : 'is-icon-error'
                      }></TransferInfoEntry>
                    <TransferInfoEntry
                      label="Country where BCU is claimed"
                      value={selectedBCU.credit.creditClaimCountry}
                      iconClass={
                        selectedBCU.credit.creditClaimCountry
                          ? 'is-icon-success'
                          : 'is-icon-error'
                      }></TransferInfoEntry>
                    <TransferInfoEntry
                      label="Reporting"
                      value={selectedBCU.credit.reporting}
                      iconClass={
                        selectedBCU.credit.reporting
                          ? 'is-icon-success'
                          : 'is-icon-error'
                      }></TransferInfoEntry>
                    {!selectedBCU.credit.scope1CompanyId &&
                      ifRequestAvailable(
                        selectedBCU,
                        <InformationRequestForm id={form.values.BCUID} />,
                      )}
                  </div>
                )}
                <div className="c-transfer-info">
                  <div className="c-transfer-info__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none">
                      <path
                        d="M12 12L12 16.8M12 8.4422V8.40001M2.4 18L2.4 5.9999C2.4 4.01168 4.01178 2.3999 6 2.3999L18 2.3999C19.9882 2.3999 21.6 4.01168 21.6 5.9999L21.6 18C21.6 19.9882 19.9882 21.6 18 21.6H6C4.01178 21.6 2.4 19.9882 2.4 18Z"
                        stroke="#07ACA6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="c-transfer-info__text">
                    <p>
                      Once you have submitted the retirement, it will be
                      reviewed by the RSB System Admin. This process may take up
                      to 48 hours.
                    </p>
                    <p>
                      When the retirement is complete, all entities mentioned
                      associated with the BCU will receive an email
                      notification.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="align-right mt-5">
              <NextButton
                disabled={!selectedBCU || !selectedBCU.credit.scope1CompanyId}
                handleClick={handleSubmit}
                text="Submit"
                style={{ margin: '0', width: '147px' }}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmationPrompt onClosed={handleClose} show={show}>
        {selectedBCU?.credit.facilitatorId &&
        user?.company.type !== CompanyType.Facilitator ? (
          <p>
            The BCU is assigned to the {selectedBCU?.credit.facilitator.name}{' '}
            for administration. Do you want to proceed regardless?
          </p>
        ) : (
          <p>
            Please confirm the retirement (full or split amount) of{' '}
            <strong>{selectedBCU?.credit.BCUID}</strong>?
          </p>
        )}
      </ConfirmationPrompt>
    </>
  );
};
