import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useGetInformationRequestsQuery } from '../../../../API';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';
import { IStatusProps } from '../../../components/StatusComponent';
import { OnFilterChange } from '../../../components/DefaultTable/TableFilters';
import { CreditInfoRequestStatus } from '@prisma/client';
import { Search } from '../../../components/GenericTable/Search/Search';
import { DATE_WITH_TIME_FORMAT, formatDate } from '../../../core/models/contstants/format-date';
import { useAntPagination } from '../../../components/GenericTable/helpers';

import './InfoRequestsPage.scss';

const mapItemStatusToComponent = (
  status: string,
): IStatusProps['statusOfComponent'] => {
  switch (status) {
    case CreditInfoRequestStatus.Approved:
      return 'Approved';
    case CreditInfoRequestStatus.Denied:
      return 'Denied';
    case CreditInfoRequestStatus.Pending:
    default:
      return 'Pending';
  }
};

export const getColumns = (onFilter: (values: any) => void) => {
  return [
    {
      title: 'Date and time',
      sort: true,
      dataIndex: 'date',
      keyItem: 'date',
    },
    {
      title: 'BCU ID',
      dataIndex: 'bcuId',
      keyItem: 'bcuId',
    },
    {
      title: 'From/by',
      dataIndex: 'fromBy',
      keyItem: 'fromBy',
    },
    {
      title: 'Status',
      keyItem: 'status',
      dataIndex: 'statusValue',
      filters: [
        { text: 'Approved', value: CreditInfoRequestStatus.Approved },
        { text: 'For consideration', value: CreditInfoRequestStatus.Pending },
        { text: 'Rejected', value: CreditInfoRequestStatus.Denied },
      ],
      filterMultiple: true,
      onFilter: (value: any, record: any) => record.statusValue === value,
      render: (_: any, record: any) => record.statusComponent
    },
  ]
};

export const InfoRequestsPage: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const { data } = useGetInformationRequestsQuery();
  const [items, setItems] = useState(data?.results || []);
  const [search, setSearch] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const onRowClicked = async (item: any) => {
    navigate(TRANSFER_ROUTES.INFO_REQUEST.replace(':id', item.id));
  };

  const onDateSort = (data: any) => {
    return [...data].sort(
      (curr, next) =>
        new Date(next.credit.registrationApproveDate || new Date()).getTime() -
        new Date(curr.credit.registrationApproveDate || new Date()).getTime(),
    );
  };

  const applyFilters = (data: any, search: string, filterValues: any) => {
    const sortedData = onDateSort(data);
    const tableData = getTableData(sortedData);
  
    const filteredBySearch = (tableData || []).filter((item: any) => {
      return `${item.bcuId} ${item.fromBy}`
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  
    const filteredByStatus = filterValues.length
      ? filteredBySearch.filter((item: any) => filterValues.includes(item.status))
      : filteredBySearch;
  
    return filteredByStatus;
  };

  const getTableData = (sortedData: any) => {
    return sortedData.map((item: any) => {
      const statusValue = item.status; 

      return {
        id: item.id,
        date: item?.credit.registrationApproveDate
          ? formatDate(item?.credit.registrationApproveDate, DATE_WITH_TIME_FORMAT)
          : null,
        fromBy: item?.company?.name,
        bcuId: item.newCredit?.BCUID || item.credit?.BCUID,
        statusValue,
        statusComponent: (
          <div
            className={classNames('status-account--wrapper', {
              pending: statusValue === CreditInfoRequestStatus.Pending,
              approved: statusValue === CreditInfoRequestStatus.Approved,
              denied: statusValue === CreditInfoRequestStatus.Denied,
            })}
          >
            <span className="status-account--text">
              {mapItemStatusToComponent(statusValue)}
            </span>
          </div>
        ),
      }
    })
  }

  const onSearch = (e: any) => {
    setSearch(e.target.value);
    const filteredItems = applyFilters(data, e.target.value, selectedStatuses);
    setItems(filteredItems);
  };

  const onFilter = ({ values }: OnFilterChange) => {
    const filteredItems = applyFilters(data, search, values);
    setItems(filteredItems);
    setSelectedStatuses(values as []);
  };

  const columns = getColumns(onFilter);

  const onClear = () => setSearch('');

  useEffect(() => {
    const sortedData = onDateSort(data?.results || []);
    const tableData = getTableData(sortedData);

    setItems(tableData || []);
  }, [data]);

  const { currentPage, limit, handlePageChange } = useAntPagination(
    "Scope1InfoRequest",
    10,
  );

  const tablePagination = useMemo(() => {
    return {
      current: currentPage,
      pageSize: limit,
      onChange: handlePageChange,
      showSizeChanger: true,
    }
  }, [currentPage, limit, handlePageChange])
  
  return (
    <>
      <div className='search-wrap'>
        <div className='incoming-title'></div>
        <Search
          isClearable={Boolean(search)}
          defaultValue={search ? search : ''}
          onClear={onClear}
          onChange={onSearch}
        />
      </div>
      <Table
        rowKey={(record: any) => record.id} 
        columns={columns}
        dataSource={items as any || []}
        pagination={tablePagination}
        onRow={(record: any) => ({
          onClick: () => onRowClicked(record),
        })}
      />
    </>
  );
});
