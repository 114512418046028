import React from 'react';
import { Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import {
  fetchSubmissionsTransactionInfo,
  fetchTransactionApprove,
  fetchTransactionReject,
} from '../../../../API/admin/submissions';
import { ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES } from '../../../../structure/routes/routes';
import { useAppSelector } from '../../../../store/hooks';
import { selectSubmissionsDetails } from '../../../../store/reducers/admin/submissionsReducer';
import { SubmissionsType } from '../../../../structure/request/admin';

import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';

export const AdminTransactionsLayout: React.FC = () => {
  const details = useAppSelector(selectSubmissionsDetails);

  const isCertificateExpired = details && details?.company?.certificateValidityEndDate && (new Date(details?.company?.certificateValidityEndDate || Date.now()) <= new Date());

  const tooltip = <strong style={{color: '#D64751'}}>Certification expired</strong>;

  const BCU_CREDITS_TABS = [
    {
      title: 'Company Info',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO,
    },
    {
      title: 'Users Info',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO,
    },
    {
      title: (<>
        <span>BCU Values</span>
        {isCertificateExpired && (<Popover content={tooltip}>
              <InfoCircleFilled style={{ marginLeft: '7px', fontSize: 16, color: '#D64751', position: 'relative' }} />
        </Popover>)}
      </>),
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_VALUES,
    },
    {
      title: (<>
        <span>BCU Info</span>
        {isCertificateExpired && (<Popover content={tooltip}>
              <InfoCircleFilled style={{ marginLeft: '7px', fontSize: 16, color: '#D64751', position: 'relative' }} />
        </Popover>)}
      </>),
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO,
    },
    {
      title: 'Scope 1 Info',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE1_INFO,
    },
    {
      title: 'Scope 3 Info',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE3_INFO,
    },
    {
      title: 'BCU Events',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.ADMIN_BCU_HISTORY,
    },
    {
      title: 'Note',
      path: ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE,
    },
  ];

  return (
    <AdminSubmissionsDetailsLayout
      fetchData={fetchSubmissionsTransactionInfo}
      fetchReject={fetchTransactionReject}
      fetchApprove={fetchTransactionApprove}
      tabs={BCU_CREDITS_TABS}
      type={SubmissionsType.BCURegistration}
    />
  );
};
