import { FC, useMemo } from 'react';
import './batchStyles.scss';
import { useAppSelector } from '../../../../../../store/hooks';
import SummaryField from '../../SummaryField';
import { BatchPreviewData } from '../../../../BCUData/BCUData';

interface IBatchSumProps {
  title: string;
}

const BatchSum: FC<IBatchSumProps> = ({ title }) => {
  const batchSummary = useAppSelector(state => state.form.batchForm);
  const formValues = useAppSelector(state => state.form.fields);

  const modifiedBatchSummary = batchSummary.map(batch => {
    return {
      ...batch,
      fieldBatchCountry: formValues.fieldBatchCountry,
    };
  });

  const {
    fieldID,
    fieldTonnes,
    fieldMJ,
    dateProd,
    dateBlend,
    entryBlendDate,
    fieldCO2,
    fieldGHG,
    productDeliveryDate,
    fieldBatchCountryOf,
    fieldSaf,
    fieldRsb,
    fieldDec
  } = batchSummary[0];

  const isBlendDateAvailable = useMemo(() => formValues.radioButton === "Self-production", [formValues]);
  const modifiedDecField = useMemo(() => {
    return fieldDec.replace(/,(\S)/g, ', $1');
  }, [fieldDec]);
  
  if (batchSummary.length === 1) {
    return (
      <div className="batch-form--sum1">
        <h2 className="batch-form--title">{title}</h2>
        <SummaryField
          nameProp="Batch ID end product"
          selected={fieldID}
          border
        />
        <SummaryField
          nameProp="Neat amount in tonnes"
          selected={fieldTonnes}
          border
        />
        <SummaryField
          nameProp="Neat amount in MJ"
          selected={fieldMJ}
          border
        />
        <SummaryField
          nameProp="Year of neat product production"
          selected={dateProd}
          border
        />
        {isBlendDateAvailable && (
          <SummaryField
            nameProp="Date of blending"
            selected={dateBlend}
            border
          />
        )}
        <SummaryField
          nameProp="Date of entry in tracking system"
          selected={entryBlendDate}
          border
        />
        <SummaryField
          nameProp={
            <span>
              LCA GHG value (gCO<sub>2</sub>eq/MJ)
            </span>
          }
          selected={fieldCO2}
          border
        />
        <SummaryField
          nameProp="GHG Emission Reduction (%)"
          selected={fieldGHG}
          border
        />
        <SummaryField
          nameProp="Date of end product delivery to buyer"
          selected={productDeliveryDate}
          border
        />
        <SummaryField
          nameProp="Country of physical end product delivery"
          selected={fieldBatchCountryOf}
          border
        />
        <SummaryField
          nameProp="Airport of physical end product delivery"
          selected={fieldSaf}
          border
        />
        <SummaryField
          nameProp="Additional claim as allowed under the RSB certification system"
          selected={fieldRsb}
          border
        />
        <SummaryField
          nameProp="Declaration of incentives and tax credits"
          selected={modifiedDecField}
        />
      </div>
    );
  }

  return (
    <div className="batch-form--sum1">
      <h2 className="batch-form--title">{title}</h2>
      <div style={{ padding: '10px 0' }}>
        <BatchPreviewData batches={modifiedBatchSummary} />
      </div>
    </div>
  );
};

export default BatchSum;
