import React, { useMemo } from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import { useAntPagination } from '../GenericTable/helpers';

export const BaseTable: React.FC<TableProps<any>> = ({ pagination, ...props }) => {
    const { currentPage, limit, handlePageChange } = useAntPagination(
      'BaseTable',
      10,
    );

    const tablePagination = useMemo(() => {
      return {
        current: currentPage,
        pageSize: limit,
        onChange: handlePageChange,
        showSizeChanger: true,
      }
    }, [currentPage, limit, handlePageChange])

    return (
        <AntdTable
            pagination={tablePagination}
            {...props}
        />
    )
};