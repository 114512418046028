import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { ErrorBlocks } from '../components/Form/FormHook';
import { useNavigate } from 'react-router-dom';
import { LOGIN, STATEMENTS } from '../../structure/routes/routes';
import { Password } from '../components/Form/Password';
import { AppThunk } from '../../store/store';
import { SetPasswordData } from '../../structure/request/change-password';

import './ChangePasswordFormStyle.scss';
import { useNotification } from '../../hooks/useNotification';
import { Divider } from 'antd';

interface Props<T extends SetPasswordData> {
  isReset?: boolean;
  isSetPassword?: boolean;
  fetchData: (data: T) => AppThunk<Promise<void>>;
  token?: string;
}

export const ChangePasswordForm = <T extends SetPasswordData>({
  isReset = false,
  isSetPassword = false,
  fetchData,
  token,
}: Props<T>) => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const oldPassword = 'oldPassword';
  const password = 'password';
  const confirmPassword = 'confirmPassword';

  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<ErrorBlocks>();
  const [loading, setLoading] = useState(false);

  const validator = (values: any) => {
    const passwordError =
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        values[password],
      );
    const oldPasswordError = !values[oldPassword];
    const confirmPasswordErrors = values[password] !== values[confirmPassword];
    const errors = {} as any;

    if (passwordError) {
      errors[password] =
        'Password must contain 8 characters, a special sign, number, lower case letter, and capital letter.';
    }

    if (!isReset && !isSetPassword && oldPasswordError) {
      errors[oldPassword] = 'Old password is required.';
    }

    if (confirmPasswordErrors) {
      errors[confirmPassword] = 'Password must be the same as above.';
    }

    return errors;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const currentErrors = validator(values);
  
    if (
      currentErrors[password] ||
      (isReset && isSetPassword && currentErrors[oldPassword]) ||
      currentErrors[confirmPassword]
    ) {
      setErrors(currentErrors);
      return;
    }
  
    const data = {
      password: values[password],
      ...(!isReset && !isSetPassword
        ? { oldPassword: values[oldPassword] }
        : {}),
      ...(token ? { token } : {}),
    };
  
    setLoading(true);
  
    try {
      await dispatch(fetchData(data as any));
  
      toast.success({
        message: 'Success',
        description:
          isReset && isSetPassword
            ? 'Password has been changed'
            : 'Password has been set',
      });
  
      navigate(!token ? STATEMENTS : LOGIN);
    } catch (error: unknown) {
      if (error instanceof Error) {
        const errorMessage =
          (error as any).response?.data?.message || error.message || 'An error occurred';
        
        toast.error({
          message: 'Error',
          description: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };  

  const onFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      errors &&
      (errors[password] || errors[oldPassword] || errors[confirmPassword])
    ) {
      const currentErrors = validator(values);
      setErrors(currentErrors);
    }
  }, [values]);

  const passwordField = 'New Password';
  const oldPasswordField = 'Old Password';
  const confirmPasswordField = 'Confirm new password';

  return (
    <div className="change-password-screen">
      <div className="change-password-screen__form">
        <h1>
          {isReset
            ? 'Reset Your Password'
            : isSetPassword
            ? 'Set Your Password'
            : 'Change Your Password'}
        </h1>
        <form onSubmit={onSubmit}>
          <div className="group-reset-password">
            {(!isReset && !isSetPassword) && (
              <>
                <Password
                  label={oldPasswordField}
                  placeholder={oldPasswordField}
                  required
                  autoComplete="one-time-code"
                  name={oldPassword}
                  error={errors && errors[oldPassword]}
                  onChange={onFieldChange}
                />

                <Divider style={{ marginTop: '25px', marginBottom: '15px' }} />
              </>
            )}

            <Password
              label={passwordField}
              placeholder={passwordField}
              required
              autoComplete="one-time-code"
              name={password}
              error={errors && errors[password]}
              onChange={onFieldChange}
            />
            <Password
              label={confirmPasswordField}
              placeholder={confirmPasswordField}
              required
              autoComplete="one-time-code"
              name={confirmPassword}
              error={errors && errors[confirmPassword]}
              onChange={onFieldChange}
            />
          </div>
          <BaseButtonSubmit
            className="change-password-screen__form-submit"
            active
            lock={loading}
            loading={loading}>
            {isReset ? 'Reset Password' : 'Confirm Password'}
          </BaseButtonSubmit>
        </form>
      </div>
    </div>
  );
};
