import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Prisma } from '@prisma/client';

export type AccountUser = Prisma.UserGetPayload<{ include: { company: true }}>;

interface UserState {
  userData?: AccountUser;
  account?: any;
  token?: string;
  status: 'idle' | 'loading' | 'failed';
}

const storedToken = localStorage.getItem('accessToken');

const initialState: UserState = {
  status: 'idle',
  account: null,
  token: storedToken ? storedToken : undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;

      localStorage.setItem('accessToken', state.token);
    },
    setUserData: (state, action: PayloadAction<AccountUser>) => {
      state.userData = action.payload;
    },
    setCompanyData: (state, action) => {
      state.userData!.company = action.payload;
    },
    setUserAccountData: (state, action: PayloadAction<AccountUser>) => {
      state.account = action.payload;
    },
    setCompanyInfo: (state, action) => {
      if (action.payload && action.payload.length) {
        state.userData!.company = action.payload;
      }
    },
    logout: state => {
      state.userData = undefined;
      state.token = undefined;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('tableFilters');
    },
    reset: state => {
      // do nothing, just complete the action. This is used to reset the state
    }
  },
});

export const {
  changeToken,
  setUserData,
  setUserAccountData,
  logout,
  reset,
  setCompanyData,
  setCompanyInfo,
} = userSlice.actions;
export const selectStatus = (state: RootState) => state.user.status;
export const selectToken = (state: RootState) => state.user.token;
export const selectUser = (state: RootState) => state.user.userData;
export const selectUserAccountData = (state: RootState) => state.user.account;

export default userSlice.reducer;
