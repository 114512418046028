import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { notification, Button, Modal, Dropdown } from 'antd';
import { Credit } from '@prisma/client';
import { Select } from '../Form/Select/Select';

import { getColumns } from './models/columns.constant';
import {
  selectCredits,
  setDetailCredit,
} from '../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  Company,
  CompanyType,
} from '../../../structure/models/company/company';
import { limitOfNumbers } from '../../../helpers/limitOfNumbers';
import { selectUserAccountData } from '../../../store/reducers/user/userReducer';
import { fetchCompanyInfo } from '../../../API/company';
import { BalanceContext } from '../../../contexts/balance.context';
import { useGetContactListQuery } from '../../../API';
import { Contact } from '../ContactList';
import {
  fetchCredits,
  fetchUpdateCreditFacilitator,
} from '../../../API/credits';
import { TABLES } from '../../../constants/tables';
import { compareCreditsByUpdatedAt } from '../../../helpers/sort';
import { ViewElement } from '../ViewElement/ViewElement';
import { useCombinedFilter } from '../../../hooks/useLocalStorageFilter';
import { BCUStatuses } from '../../../structure/models/bcu/bcu';
import { DateQueryTypes, DateSelect } from '../Table/DateSelect';
import { BALANCE_STATUSES, EXPIRE_DATE_RANGE, REGISTRATION_YEARS } from './models/bcu.constant';
import { Search } from '../Table/Search/Search';
import { useSearchParamsState } from '../Table/Pagination/PaginationHook';
import { GenericTable } from '../GenericTable/GenericTable';
import { MultiSelect } from '../Form/MultiSelect/MultiSelect';

import './UserBalanceStyle.scss';

export enum Action {
  Assign = 'assign',
  Unassign = 'unassign',
}

interface Props {
  company: Company;
  className?: string;
  style?: CSSProperties | undefined;
}

export const UserBalance: React.FC<Props> = ({ company, className, style }) => {
  const [selectedRows, setSelectedRows] = useState<Credit[]>([]);

  const [modal, contextHolder] = Modal.useModal();

  const { isOpened, setIsOpened } = useContext(BalanceContext);
  const { data } = useGetContactListQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const credits = useAppSelector(selectCredits);
  const account = useAppSelector(selectUserAccountData);

  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );

  const [status, setStatus] = useCombinedFilter(
    TABLES.USER_BALANCE,
    'status',
    `${BCUStatuses.Active}, ${BCUStatuses.Suspended}, ${BCUStatuses.Transit}`,
  ) as any;
  const [registrationYear, setRegistrationYear] = useCombinedFilter(
    TABLES.USER_BALANCE,
    'registration',
    null,
  ) as any;
  const [startDate, setStartDate] = useCombinedFilter(
    TABLES.USER_BALANCE,
    'startDate',
    '',
  ) as any;
  const [endDate, setEndDate] = useCombinedFilter(
    TABLES.USER_BALANCE,
    'endDate',
    '',
  ) as any;

  const sortedCredits = useMemo(
    () => [...credits].sort(compareCreditsByUpdatedAt),
    [credits],
  );

  const isFacilitator = useMemo(
    () => company?.type === CompanyType.Facilitator,
    [company],
  );

  const balance = useMemo(
    () => (account?.balance ? limitOfNumbers(account.balance) : 0),
    [account],
  );

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalEmissions, setTotalEmissions] = useState(0);

  useEffect(() => {
    setIsFilterApplied(!isFilterApplied);
  }, [status, startDate, endDate, registrationYear, search]);

  useEffect(() => {
    if (credits[0]?.totalEmissions) {
      setTotalEmissions(credits[0]?.totalEmissions);
    }
  }, [credits[0]?.totalEmissions])

  const facilitatorsOptions = useMemo(
    () =>
      data
        ?.filter(
          (contact: Contact) => contact.to.type === CompanyType.Facilitator,
        )
        .map((contact: Contact) => ({
          key: contact.to.id,
          label: contact.to.name,
        })),
    [data],
  );
  
  const createStatusArray = () => {
    if (status === '')
      return null;

    return status.split(',').map((s: string) => s.trim());
  };

  const FILTER_PARAMS = {
    search,
    status: createStatusArray(),
    registrationYear,
    startDate,
    endDate,
  };


  if (!account) {
    dispatch(fetchCompanyInfo());
  }

  useEffect(() => {
    dispatch(fetchCredits(FILTER_PARAMS));
  }, [search, status, startDate, endDate, registrationYear, credits.length]);

  const toggleCollapse = () => setIsOpened(!isOpened);

  const handleActionClick = useCallback(
    async (action: Action, facilitatorId?: string) => {
      if (!selectedRows.length) {
        notification.error({
          message: 'Please select BCUs',
        });
        return;
      }

      try {
        for (const credit of selectedRows) {
          await dispatch(
            fetchUpdateCreditFacilitator(credit.id, action, facilitatorId),
          );
        }

        notification.success({
          message: 'Success',
        });
      } catch (error: any) {
        notification.error({
          message: 'Error',
          description: error.message,
        });
      }
    },
    [selectedRows],
  );

  const handleUnassignClick = useCallback(() => {
    modal.confirm({
      title: 'Unassign Facilitator',
      icon: null,
      content:
        'Selected BCUs will be unassigned from any Facilitator. Do you want to proceed?',
      onOk: () => {
        handleActionClick(Action.Unassign);
        setSelectedRows([]);
      },
      okText: 'Yes',
    });
  }, [handleActionClick]);

  const handleAssignClick = useCallback(
    (facilitatorId: string) => {
      const creditsWithFacilitators = selectedRows.filter(
        (credit: Credit) => credit.facilitatorId,
      ).length;

      if (creditsWithFacilitators) {
        modal.confirm({
          title: 'Assign Facilitator',
          content:
            'One or more BCUs are already assigned to the Facilitator. If you proceed, the assignment will be overwritten by the selected Facilitator',
          onOk: () => handleActionClick(Action.Assign, facilitatorId),
          okText: 'Assign',
          cancelText: 'Cancel',
        });
      } else {
        handleActionClick(Action.Assign, facilitatorId);
      }

      setSelectedRows([]);
    },
    [credits, selectedRows],
  );

  const onTitleClick = (item: any) => {
    dispatch(setDetailCredit(item));
    navigate(`/bcu/${item.id}/bcu-info`, {
      state: { from: location.pathname },
    });
  };

  const columns = useMemo(
    () => getColumns(onTitleClick, isFacilitator),
    [onTitleClick, isFacilitator],
  );

  const onChangeStatus = (options: any) => {
    const optionsStr = options.join(", ");
    setStatus(optionsStr)
  };

  const onChangeRegistration = (options: any) => {
    setRegistrationYear(options?.value);
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onClear = () => setSearch(null);

  const dateRangeProps = {
    placeholder: 'Expiry range',
    options: EXPIRE_DATE_RANGE,
  };

  const getTotalEmissions = () => {
    return `${credits[0]?.totalEmissions?.toLocaleString() || totalEmissions.toLocaleString() || 0} t`;
  }

  return (
    <div className={classNames('user-balance', className)} style={style}>
      <div
        className={classNames('user-balance__header', {
          opened: isOpened,
        })}
        onClick={toggleCollapse}
        aria-controls="user-balance-credits"
        aria-expanded={isOpened}>
        <div className="user-balance__header__info">
          <ViewElement label="Company" description={company.name} />
          <div className="totalContainer">
            <ViewElement
              label="Total Emissions Reduction (CO2eq value)"
              description={getTotalEmissions()}
            />
            <ViewElement
              label="Total Balance"
              description={`${balance.toLocaleString()} BCU`}
            />
          </div>
        </div>
        <div className="user-balance__header__arrow">
          <span className="material-icons-outlined">
            {`keyboard_arrow_${isOpened ? 'up' : 'down'}`}
          </span>
        </div>
      </div>
      <Collapse className="user-balance__body" in={isOpened}>
        <div id="user-balance-credits" className="user-balance-credits">
          <GenericTable
            rowKey="id"
            tableTitle="User Balance"
            tableName={TABLES.USER_BALANCE}
            columns={columns}
            dataSource={sortedCredits}
            rowSelection={{
              type: 'checkbox',
              onChange(
                selectedRowsIds: string[],
                _selectedRows: Credit[],
                { type }: any,
              ) {
                if (type === 'all') {
                  setSelectedRows(
                    selectedRows.length === sortedCredits.length
                      ? []
                      : sortedCredits,
                  );
                } else {
                  setSelectedRows(_selectedRows as Credit[]);
                }
              },
              selectedRowKeys: selectedRows.map(row => row.id),
            }}
          >
            <div className="d-flex align-items-center" style={{ justifyContent: "space-between", width: "100%"}}>
              {!isFacilitator ? (
                <div className="me-3">
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'assign',
                          label: 'Assign Facilitator',
                          onClick: ({ key }) => handleAssignClick(key),
                          children: facilitatorsOptions,
                        },
                        {
                          key: 'unassign',
                          onClick: handleUnassignClick,
                          label: 'Unassign Facilitator',
                        },
                      ],
                    }}>
                    <Button>Actions</Button>
                  </Dropdown>
                </div>
              ) : (
                <div></div>
              )}
              <div className="d-flex flex-row align-items-center">
                <div className="me-3">
                  <MultiSelect
                    placeholder='Statuses'
                    options={BALANCE_STATUSES}
                    value={status}
                    onChange={onChangeStatus}
                  />
                </div>
                <div className="me-3">
                  <DateSelect
                    inputName="Expiry date"
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                    isForDate={true}
                    dateRangeProps={dateRangeProps}
                    onDateChange={onDateChange}
                  />
                </div>
                <div className='wide-select_wrap me-3'>
                  <Select
                    className="users__header_filter"
                    placeholder="Registration year"
                    options={REGISTRATION_YEARS}
                    isClearable={false}
                    value={registrationYear}
                    onChange={onChangeRegistration}
                  />
                </div>
                <div>
                  <Search
                    isClearable={Boolean(search)}
                    className="search__input"
                    defaultValue={search ? search : ''}
                    onClear={onClear}
                    onChange={e => setSearch(e.target.value.trim())}
                  />
                </div>
              </div>
            </div>
          </GenericTable>
        </div>
      </Collapse>
      {contextHolder}
    </div>
  );
};
