import { CompanyType, UserRole, UserStatus } from '@prisma/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserBalance } from '../components/UserBalance/UserBalance';
import { fetchUserTransactions } from '../../API/user-transactions';
import { selectTransactions, selectUserTransactionsStatus } from '../../store/reducers/user/transactionsReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { useSearchParamsState } from '../components/Table/Pagination/PaginationHook';
import { COLUMNS } from './models/columns.constant';
import { downloadUserTransactions, fetchCompanyData } from '../../API/user';
import { DateQueryTypes, DateSelect } from '../components/Table/DateSelect';
import { Select } from '../components/Form/Select/Select';
import {
  TransactionTypeOptions,
  TransactionType,
} from '../../structure/models/transaction/transaction';
import { SortOrder } from '../components/DefaultTable/types';
import { SubTable, TRetirementStatement } from '../home/Statements';
import { RETIREMENT_STATEMENTS_COLUMNS } from '../home/models/columns.constant';
import AsyncTable from '../components/DefaultTable/async';
import {
  initPublicList,
  selectRetirementStatementsPrivateList,
  setRetirementStatementsPrivateList,
} from '../../store/reducers/user/retirementStatementsReducer';
import { isInTypes } from '../../helpers/types';
import { DownloadFileButton } from '../components/Buttons/DownloadFileButton/DownloadFileButton';
import { useCombinedFilter } from '../../hooks/useLocalStorageFilter';
import { TABLES } from '../../constants/tables';
import { setDetailCredit } from '../../store/reducers/user/creditsReducer';
import { PaginatedTable } from '../components/Table/Table';
import { Search } from '../components/Table/Search/Search';
import { fetchPrivateRetirementStatements } from '../../API/retirement-statements';

import './TransactionsStyle.scss';

export const Transactions: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const list = useAppSelector(selectRetirementStatementsPrivateList);
  const user = useAppSelector(selectUser);

  const isUserSuspended = useMemo(
    () => user?.status === UserStatus.Suspended,
    [user],
  );

  const [type, setType] = useCombinedFilter(
    TABLES.TRANSACTIONS,
    'type',
    null,
  ) as any;
  const [startDate, setStartDate] = useCombinedFilter(TABLES.TRANSACTIONS, 'startDate', '') as any;
  const [endDate, setEndDate] = useCombinedFilter(TABLES.TRANSACTIONS, 'endDate', '') as any;

  const [activeRows, setActiveRows] = useState<{ [key: string]: boolean }>({});
  const [sortBy, setSortBy] = useState<{ key?: string; direction: SortOrder }>({
    key: 'id',
    direction: 'desc',
  });
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );

  const [page, setPage] = useCombinedFilter(TABLES.RETIREMENT, 'page', 1);
  const [limit, setLimit] = useCombinedFilter(TABLES.RETIREMENT, 'limit', 50);
  
  const onSearch = (e: any) => setSearch(e.target.value);

  const onTypeChange = (option: any) => {
    setType(option.value);
  };

  const onDateChange = ({ startDate, endDate }: DateQueryTypes) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (!user) return;

    dispatch(fetchCompanyData());
  }, []);

  const transactionTypeOptions = TransactionTypeOptions.filter(option => {
    const isSupplier = user?.company?.type === CompanyType.Supplier;

    if (
      (!isSupplier && option.value === TransactionType.Register) ||
      (isSupplier && option.value === TransactionType.Retirement)
    ) {
      return false;
    }

    return option;
  }) as any;

  const onClear = () => setSearch(null);

  const handleSortChange = ({ column }: { column: any }) => {
    setSortBy(prevState => {
      let direction: SortOrder = 'asc';

      if (prevState.key === column.keyItem) {
        direction = prevState.direction === 'asc' ? 'desc' : 'asc';
      }

      return {
        ...prevState,
        direction,
        key: column.keyItem,
      };
    });
  };

  const handleRowClicked = (item: TRetirementStatement) => {
    setActiveRows({
      ...activeRows,
      [item.BCUID as any]: !activeRows[item.BCUID as any],
    });
  };

  const showRetirementTable = user?.company.type
    ? !Boolean(
        isInTypes(user?.role, [
          UserRole.SuperAdmin,
          UserRole.Admin,
          UserRole.Auditor,
        ]) || user.company.type === CompanyType.Supplier,
      )
    : false;
  
  const onBCUClick = (item: any) => {
    dispatch(setDetailCredit(item.newCredit || item.credit));
    navigate(`/bcu/${item.id}/bcu-info`, { 
      state: { from: location.pathname } 
    });
  };

  const userColumns = useMemo(
    () => COLUMNS(user, type, onBCUClick), 
    [user, type, onBCUClick]
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  useEffect(() => {
    const params = {
      page,
      limit,
      isPublic: false
    };
    dispatch(fetchPrivateRetirementStatements(params));
  }, [page, limit]);  

  return (
    <div className="transactions">
      <UserBalance company={user!.company} />
      <div className='transaction-table_wrap'>
        <div className="transactions__header">
          <h1 className="transactions__title">Transaction History</h1>
          
          <div className="transactions__filters">
            {isUserSuspended || (
              <div className="transactions__filters__download">
                <DownloadFileButton
                  fetchDownload={() =>
                    downloadUserTransactions({
                      search: search || null,
                      type: type || null,
                      startDate: startDate || null,
                      endDate: endDate || null,
                    })
                  }
                />
              </div>
            )}
            <div className='wide-filter'>
              <Select
                placeholder="Type of the transaction"
                options={transactionTypeOptions}
                value={type}
                onChange={onTypeChange}
              />
            </div>
            <DateSelect 
              initialStartDate={startDate} 
              initialEndDate={endDate}
              onDateChange={onDateChange} 
            />
            
            <Search
              isClearable={Boolean(search)}
              onChange={onSearch}
              defaultValue={search ? search : ''}
              onClear={onClear}
            />
          </div>
        </div>
        <PaginatedTable
          columns={userColumns}
          fetchData={fetchUserTransactions}
          selectStatus={selectUserTransactionsStatus}
          selectData={selectTransactions}
          filters={{ search, startDate, endDate, type }}
          tableName={TABLES.TRANSACTIONS}
        />
      </div>
      
      {showRetirementTable && (
        <div className="transactions__operations">
          <h1 className="transactions__operations_title">
            Account retirement operations
          </h1>
          <AsyncTable<any>
            isLoading={list.loading}
            noSortings={true}
            showSearch={false}
            slots={{
              rowAfter: SubTable,
            }}
            slotProps={{
              head: {
                current: sortBy,
                onSortChange: handleSortChange,
              },
              rowAfter: { activeRows },
            }}
            onRowClicked={handleRowClicked}
            showPagination={true}
            headers={RETIREMENT_STATEMENTS_COLUMNS}
            pagination={{
              current: page,
              pageSize: limit,
              total: list.total,
              showSizeChanger: true,
              onChange: handlePageChange,
              onShowSizeChange: (_, size) => handleLimitChange(size),
            }}
            {...list}></AsyncTable>
        </div>
      )}
    </div>
  );
});
