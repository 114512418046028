import { Credit, Prisma } from '@prisma/client';
import { createContext, Dispatch } from 'react';
import { CreditOption } from '../transfer/Transfer';

export type TransferContextType = {
  setSelectedBcu: Dispatch<CreditOption | undefined>;
  selectedBcu: CreditOption | undefined;
};

export const TransferContext = createContext<TransferContextType | null>(
  null,
);
