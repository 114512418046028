import React, { useMemo } from 'react';
import { Credit, CreditStatus, Prisma } from '@prisma/client';

import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/reducers/user/userReducer';
import { CompanyType } from '../../structure/models/company/company';

import { CorporatePage } from './Pages/CorporatePage';
import { useGetCreditsQuery } from '../../API';
import { AirlinePage } from './Pages/AirlinePage';
import { ForwarderPage } from './Pages/ForwarderPage';
import { RetirementContext } from './context';
import { CreditOption } from '../transfer/Transfer';

import './RetirementStyle.scss';

export const Retirement: React.FC = React.memo(() => {
  const user = useAppSelector(selectUser);
  const { data: credits } = useGetCreditsQuery();

  const [selectedBcu, setSelectedBcu] = React.useState<CreditOption>();

  const filteredCredits = useMemo(
    () =>
      credits?.filter(
        (credit: Prisma.CreditGetPayload<{ include: { company: true } }>) =>
          credit.company.type !== CompanyType.Intermediary &&
          credit.company.type !== CompanyType.Supplier,
      ),
    [credits],
  );

  const creditsOptions = useMemo(
    () =>
      (user?.company.type === CompanyType.Facilitator
        ? filteredCredits
        : credits
      )
        ?.filter(({ status }: Credit) => status === CreditStatus.Active)
        .map((credit: any) => ({
          credit,
          value: credit.id,
          label: credit.BCUID,
        })),
    [user, filteredCredits, credits],
  );

  const pageComponents = useMemo(
    () => ({
      [CompanyType.Airline]: AirlinePage,
      [CompanyType.Corporate]: CorporatePage,
      [CompanyType.Forwarder]: ForwarderPage,
    }),
    [],
  );

  const companyType = useMemo(
    () =>
      user?.company?.type === CompanyType.Facilitator
        ? selectedBcu?.credit?.company?.type
        : user?.company?.type,
    [user?.company?.type, selectedBcu],
  );

  const PageComponent = useMemo(
    () =>
      pageComponents[companyType as keyof typeof pageComponents] ||
      ForwarderPage,
    [pageComponents, companyType],
  );

  return (
    <div>
      <RetirementContext.Provider value={{ setSelectedBcu, selectedBcu }}>
        <PageComponent creditsOptions={creditsOptions} />
      </RetirementContext.Provider>
    </div>
  );
});
