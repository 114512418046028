import React from 'react';
import { ContactUs } from '../../../ContactUs/ContactUs';
import { UserMenu } from '../UserMenu/UserMenu';
import { BaseButton } from '../../../Buttons/BaseButton/BaseButton';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { UserRole } from '@prisma/client';
import { isInTypes } from '../../../../../helpers/types';
import { Feedback } from '../../../Feedback/Feedback';

import './HeaderActionsStyles.scss';

export const HeaderActions: React.FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <div className="main-header__actions">
      {user && user?.role !== 'SuperAdmin' && (
        <Feedback />
      )}
      {(!user ||
        (user && !isInTypes(user!.role, [UserRole.Admin, UserRole.SuperAdmin]))) && (
        <ContactUs />
      )}
      {user && <UserMenu />}
      {!user && (
        <>
          <BaseButton link={'/registration'} active={true} className='header-button'>
            Sign Up
          </BaseButton>
          <BaseButton 
            className="main-header__actions-login header-button" 
            link={'/login'} 
          >
            Log in
          </BaseButton>
        </>
      )}
    </div>
  );
};
