import Icon1 from '../../../../assets/pepicons-pop_handshake_2.svg';
import Icon2 from '../../../../assets/tabler_zoom-check.svg';
import Icon3 from '../../../../assets/cil_factory.svg';
import Icon4 from '../../../../assets/pepicons-pop_handshake.svg';
import Icon5 from '../../../../assets/mingcute_airplane-line.svg';
import Icon6 from '../../../../assets/icon-park-outline_transport.svg';
import Icon7 from '../../../../assets/mdi_office-building-cog.svg';
import IconCheck from '../../../../assets/gridicons_checkmark.svg';
import IconCross from '../../../../assets/radix-icons_cross-1.svg';

import './AccountTableSection.scss';
import { Link } from 'react-router-dom';

const CHECK_ENUM = {
    'CHECK': 0,
    'CROSS': 1,
    'BRACKETS': 2
}

export const AccountTableSection = () => {
  const rows = [
    {
      role: 'Supplier',
      description: 'Fuel producers, fuel suppliers',
      actions: { register: CHECK_ENUM.CHECK, transfer: CHECK_ENUM.CHECK, hold: CHECK_ENUM.CHECK, retire: CHECK_ENUM.BRACKETS },
      mode: 'Air',
      claimant: 'N/A',
      icon: Icon3,
    },
    {
      role: 'Intermediary',
      description: 'Fuel suppliers, supplier subsidiaries',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.CHECK, hold: CHECK_ENUM.CHECK, retire: CHECK_ENUM.BRACKETS },
      mode: 'Air',
      claimant: 'N/A',
      icon: Icon4,
    },
    {
      role: 'Transport Service Provider (TSP)',
      description: 'Airline, charterer, private jet owner',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.CHECK, hold: CHECK_ENUM.CHECK, retire: CHECK_ENUM.CHECK },
      mode: 'Air',
      claimant: 'Scope 1',
      icon: Icon5,
    },
    {
      role: 'Logistics Service Provider (LSP)',
      description: 'Freight forwarder',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.CHECK, hold: CHECK_ENUM.CHECK, retire: CHECK_ENUM.CHECK },
      mode: 'Air',
      claimant: 'Scope 3',
      icon: Icon6,
    },
    {
      role: 'Corporate End-User',
      description: 'Aviation end-customers',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.CHECK, hold: CHECK_ENUM.CHECK, retire: CHECK_ENUM.CHECK },
      mode: 'Air',
      claimant: 'Scope 3',
      icon: Icon7,
    },
    {
      role: 'Facilitator',
      description: 'Offtake facilitator',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.BRACKETS, hold: CHECK_ENUM.CROSS, retire: CHECK_ENUM.BRACKETS },
      mode: 'All',
      claimant: 'N/A',
      icon: Icon1,
    },
    {
      role: 'Sustainability Auditor',
      description: 'Auditor',
      actions: { register: CHECK_ENUM.CROSS, transfer: CHECK_ENUM.CROSS, hold: CHECK_ENUM.CROSS, retire: CHECK_ENUM.CROSS },
      mode: 'All',
      claimant: 'N/A',
      icon: Icon2,
    },
  ];

  return (
    <div className="account-options">
      <h3 className='account-options-title'>Explore Our Account Options</h3>
      <p className='account-options-desc'>Discover RSB Registry accounts options tailored for every role in sustainable Book & Claim transactions</p>
      <div className="account-options-table-wrap">
        <table className="account-options-table">
            <h5 className='account-table-float-title'>BCU</h5>
            <thead>
            <tr>
                <th className='first-header-cell'>Account Role</th>
                <th className='grouped-cell'>Register</th>
                <th className='grouped-cell'>Transfer</th>
                <th className='grouped-cell'>Hold</th>
                <th className='grouped-cell'>Retire</th>
                <th>Mode</th>
                <th className='last-header-cell'>Claimant</th>
            </tr>
            </thead>
            <tbody className='table-body'>
            {rows.map((row, index) => (
                <tr key={index}>
                <td className='body-cell first-body-cell'>
                    <img src={row.icon} alt='icon' className='first-body-cell-img' />
                    <div>
                        <h5>{row.role}</h5>
                        <p>{row.description}</p>
                    </div>
                </td>
                <td className="body-cell second-body-cell">
                    {row.actions.register === CHECK_ENUM.BRACKETS ? (
                        <div className='brackets-wrap'>
                            <span className="bracket">(</span>
                            <img src={IconCheck} alt="check" />
                            <span className="bracket">)</span>
                        </div>
                    ) : row.actions.register === CHECK_ENUM.CHECK ? (
                        <img src={IconCheck} alt="check" />
                    ) : (
                        <img src={IconCross} alt="cross" />
                    )}
                </td>
                <td className='body-cell second-body-cell'>
                    {row.actions.transfer === CHECK_ENUM.BRACKETS ? (
                        <div className='brackets-wrap'>
                            <span className="bracket">(</span>
                            <img src={IconCheck} alt="check" />
                            <span className="bracket">)</span>
                        </div>
                    ) : row.actions.transfer === CHECK_ENUM.CHECK ? (
                        <img src={IconCheck} alt="check" />
                    ) : (
                        <img src={IconCross} alt="cross" />
                    )}
                </td>
                <td className='body-cell second-body-cell'>
                    {row.actions.hold === CHECK_ENUM.BRACKETS ? (
                        <div className='brackets-wrap'>
                            <span className="bracket">(</span>
                            <img src={IconCheck} alt="check" />
                            <span className="bracket">)</span>
                        </div>
                    ) : row.actions.hold === CHECK_ENUM.CHECK ? (
                        <img src={IconCheck} alt="check" />
                    ) : (
                        <img src={IconCross} alt="cross" />
                    )}
                </td>
                <td className='body-cell second-body-cell'>
                    {row.actions.retire === CHECK_ENUM.BRACKETS ? (
                        <div className='brackets-wrap'>
                            <span className="bracket">(</span>
                            <img src={IconCheck} alt="check" />
                            <span className="bracket">)</span>
                        </div>
                    ) : row.actions.retire === CHECK_ENUM.CHECK ? (
                        <img src={IconCheck} alt="check" />
                    ) : (
                        <img src={IconCross} alt="cross" />
                    )}
                </td>
                <td className='body-cell third-body-cell'>{row.mode}</td>
                <td className='body-cell last-body-cell'>{row.claimant}</td>
                </tr>
            ))}
            </tbody>
        </table>
      </div>
      <div className='table-note'>
        <div className='brackets-wrap' style={{ justifyContent: 'flex-start' }}>
            <span className="bracket">(</span>
            <img src={IconCheck} alt="check" />
            <span className="bracket">)</span>
        </div>
        <span className='on-behalf'>= on-behalf</span>
      </div>

      <button className='intro-btn'>
        <Link to="/resources/faq/general" target="_blank" rel="noopener noreferrer">
            Learn more about Account Roles
        </Link>
      </button>
    </div>
  );
};
