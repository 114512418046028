import { FC, useState, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import './SelfFormStyles.scss';
import { MyFormValues } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import FormTitle from '../../FormTitle/FormTitle';
import Field from '../../Field/Field';
import {
  CHAIN_OF_CUSTODY_MODEL,
  COUNTRY,
} from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  setField,
  setSameAddress,
} from '../../../../../../store/reducers/user/bcuFormReducer';
import { selectUser } from '../../../../../../store/reducers/user/userReducer';
import {
  handleEnterLatinAndNumbers,
  handleEnterLatinLetters,
} from '../../../../../../helpers/handleKeyPress';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';

interface ISelfFormProps {
  handleClick: () => void;
}

const SelfForm: FC<ISelfFormProps> = ({ handleClick }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const formValues = useAppSelector(state => state.form);
  const sameAddress = useAppSelector(state => state.form.sameAddress);
  const [errorVisible, setErrorVisible] = useState(false);
  const [thirdPartyManaged, setThirdPartyManaged] = useState(!!formValues.fields.companyName);

  const handleValidation = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};

    if (values.fieldName.length === 0) {
      errors.fieldName = 'Field Name is required';
    } else if (values.fieldName.length < 2) {
      errors.fieldName = 'Field name must have at least 2 characters';
    }

    if (!values.fieldStreet1) {
      errors.fieldStreet1 = 'Field street 1 is required';
    }

    if (!values.fieldCountry) {
      errors.fieldCountry = 'Field country is required';
    }

    // if (!values.fieldCountryOf) {
    //   errors.fieldCountryOf = 'Field is required';
    // }

    if (!values.fieldChain) {
      errors.fieldChain = 'Field chain of custody model is required';
    }

    if (!values.fieldPost || values.fieldPost.length < 3) {
      errors.fieldPost = 'Field must have at least 3 characters';
    }

    if (!values.fieldCity) {
      errors.fieldCity = 'Field city is required';
    }

    if (thirdPartyManaged && !values.companyName) {
      errors.companyName = 'Company name is required';
    }

    formik.setErrors(errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });
  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };
  const handleSameAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSameAddress(event.target.checked);
    dispatch(setSameAddress(event.target.checked));
    if (user?.company && isChecked) {
      const { address, addressNumber, country, zip, city, name } = user.company;
      dispatch(setField({ fieldName: 'fieldName', value: name }));
      dispatch(setField({ fieldName: 'fieldStreet1', value: address }));
      dispatch(setField({ fieldName: 'fieldStreet2', value: addressNumber || '' }));
      dispatch(setField({ fieldName: 'fieldCountry', value: country }));
      dispatch(setField({ fieldName: 'fieldPost', value: zip }));
      dispatch(setField({ fieldName: 'fieldCity', value: city }));

      formik.setFieldValue('fieldName', name);
      formik.setFieldValue('fieldStreet1', address);
      formik.setFieldValue('fieldStreet2', addressNumber);
      formik.setFieldValue('fieldCountry', country);
      formik.setFieldValue('fieldPost', zip);
      formik.setFieldValue('fieldCity', city);
    } else {
      [
        'fieldName',
        'fieldStreet1',
        'fieldStreet2',
        'fieldCountry',
        'fieldPost',
        'fieldCity',
      ].forEach(field => {
        dispatch(
          setField({ fieldName: field as keyof MyFormValues, value: '' }),
        );
        formik.setFieldValue(field, '');
      });
    }
  };

  const isRequiredFieldEmpty = () => {
    const requiredFields: Array<keyof MyFormValues> = [
      'fieldName',
      'fieldStreet1',
      'fieldCountry',
      // 'fieldCountryOf',
      'fieldChain',
      'fieldPost',
      'fieldCity',
    ];

    return requiredFields.some(field => !formik.values[field]);
  };

  const handleFormClick = () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    const errors = handleValidation(formik.values);
    const isErrors = !!Object.keys(errors).length;

    setErrorVisible(hasEmptyRequiredFields || isErrors);
    if (!hasEmptyRequiredFields && !isErrors) {
      handleClick();
    }
  };

  return (
    <div>
      <FormTitle title="2. Site information" />
      <p>Site where Sustainability Attributes are decoupled from the physical product</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="first">
          <label className="field-l" htmlFor="sameAddress">
            <input
              className="checkbox"
              id="sameAddress"
              name="sameAddress"
              type="checkbox"
              checked={sameAddress}
              onChange={event => handleSameAddressChange(event)}
            />
            <span className="checkbox-label">Same as account address</span>
          </label>
        </div>

        <Field
          handleKeyPress={handleEnterLatinLetters}
          required
          label="Name"
          id="fieldName"
          value={formik.values.fieldName}
          onChange={e => handleFieldChange('fieldName', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldName}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          required
          label="Street address"
          placeholder="Street address"
          id="fieldStreet1"
          value={formik.values.fieldStreet1}
          onChange={e => handleFieldChange('fieldStreet1', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldStreet1}
          errorVisible={errorVisible}
        />

        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          label="Street address line 2"
          placeholder="Street address line 2"
          id="fieldStreet2"
          value={formik.values.fieldStreet2}
          onChange={e => handleFieldChange('fieldStreet2', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldStreet2}
          errorVisible={errorVisible}
        />

        <div className="field-cp">
          <Field
            handleKeyPress={handleEnterLatinAndNumbers}
            required
            label="Post code"
            placeholder='Post code'
            id="fieldPost"
            value={formik.values.fieldPost}
            onChange={e => handleFieldChange('fieldPost', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.fieldPost}
            errorVisible={errorVisible}
          />

          <Field
            required
            handleKeyPress={handleEnterLatinLetters}
            label="City"
            placeholder='City'
            id="fieldCity"
            value={formik.values.fieldCity}
            onChange={e => handleFieldChange('fieldCity', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.fieldCity}
            errorVisible={errorVisible}
          />
        </div>

        <DefaultSelect
          required
          isSearchable
          inputId="fieldCountry"
          name="fieldCountry"
          label="Country"
          placeholder="Country"
          error={formik.errors.fieldCountry}
          options={COUNTRY as any}
          value={formik.values.fieldCountry}
          onChange={(option: any) => {
            handleFieldChange('fieldCountry', option.value);
          }}
        />

        <div className="second">
          <p className="rad">
            Does a third-party manage the site<span style={{ color: 'red' }}>*</span>
          </p>
          <label className="rad-1">
            <input
              type="radio"
              name="radioButton"
              value="Supplier"
              checked={thirdPartyManaged}
              onChange={() => 
                setThirdPartyManaged(true)
              }
            />
            <span className="input">Yes</span>
          </label>
          <label className="rad-2">
            <input
              type="radio"
              name="radioButton"
              value="Self-production"
              checked={!thirdPartyManaged}
              onChange={() => {
                setThirdPartyManaged(false);
                handleFieldChange('companyName', '');
              }}
            />
            <span className="input">No</span>
          </label>
        </div>

        {thirdPartyManaged && (
          <Field
            required
            handleKeyPress={handleEnterLatinAndNumbers}
            label="Company name"
            placeholder="Company name"
            id="companyName"
            value={formik.values.companyName}
            onChange={e => handleFieldChange('companyName', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.companyName}
            errorVisible={errorVisible}
          />
        )}

        {/* <DefaultSelect
          required
          isSearchable
          inputId="fieldCountryOf"
          name="fieldCountryOf"
          label="Country of the last production/processing site"
          placeholder="Country of the last production/processing site"
          error={formik.errors.fieldCountryOf}
          options={COUNTRY as any}
          value={formik.values.fieldCountryOf}
          onChange={(option: any) => {
            handleFieldChange('fieldCountryOf', option.value);
          }}
        /> */}

        <DefaultSelect
          required
          isSearchable
          inputId="fieldChain"
          name="fieldChain"
          label="Chain of custody model"
          placeholder="Chain of custody model"
          error={formik.errors.fieldChain}
          options={CHAIN_OF_CUSTODY_MODEL as any}
          value={formik.values.fieldChain}
          onChange={(option: any) => {
            handleFieldChange('fieldChain', option.value);
          }}
        />

        <NextButton handleClick={handleFormClick} text="Next step" />
      </form>
    </div>
  );
};

export default SelfForm;
