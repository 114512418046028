import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyType, Prisma } from '@prisma/client';
import { FormikErrors, useFormik } from 'formik';

import { STATEMENTS } from '../../../../structure/routes/routes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { fetchTransfer } from '../../../../API/transfer';

import NextButton from '../../../components/Buttons/NextButton/NextButton';
import {
  DefaultSelect,
  SelectOption,
} from '../../../components/Form/DefaultSelect/DefaultSelect';
import { useGetContactListQuery } from '../../../../API';
import { TransferInfoEntry } from '../../../components/TransferInfoEntry/TransferInfoEntry';
import { CreditOption } from '../../Transfer';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { fetchCompanyData } from '../../../../API/user';
import { useNotification } from '../../../../hooks/useNotification';
import {
  AmountSelectorOptions,
  useAmountSelector,
} from '../../helpers/use-amount-selector';
import { TransferContext } from '../../context';
import { selectUser } from '../../../../store/reducers/user/userReducer';

type FormValues = {
  BCUID: string;
  receiverId: string;
  volume: string;
  volumeType: string;
};

interface IntermediaryPageProps {
  creditsOptions: CreditOption[];
}

export const IntermediaryPage: React.FC<IntermediaryPageProps> = ({
  creditsOptions,
}) => {
  const transferContext = useContext(TransferContext);
  const user = useAppSelector(selectUser);
  const toast = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: contactListData } = useGetContactListQuery();
  const [show, setShow] = useState(false);
  const [selectedBCU, setSelectedBCU] = useState<CreditOption | undefined>(
    transferContext?.selectedBcu,
  );

  useEffect(() => {
    if (transferContext) {
      transferContext.setSelectedBcu(selectedBCU);
    }
  }, [selectedBCU]);

  useEffect(() => {
    handleBcuIdChange(transferContext?.selectedBcu);
  }, [transferContext]);

  const filteredContactList = useMemo(
    () =>
      contactListData?.filter(
        (contact: Prisma.ContactGetPayload<{ include: { to: true } }>& {
          isCreatedByFacilitator: boolean;
        }) =>
          contact.toId !== selectedBCU?.credit.companyId &&
          contact.to.type !== 'Facilitator' &&
          !contact.isCreatedByFacilitator,
      ),
    [contactListData, selectedBCU],
  );

  const contactListOptions: SelectOption<string>[] = (
    filteredContactList || []
  ).map((entry: any) => ({
    label: entry.to?.name,
    value: entry.toId,
  }));

  const form = useFormik<FormValues>({
    initialValues: {
      volume: '',
      volumeType: AmountSelectorOptions.BCU.value,
      BCUID: '',
      receiverId: '',
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validate: values => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.BCUID) {
        errors.BCUID = 'BCU ID field is required.';
      }

      const volumeValue = Number(values.volume.replaceAll(',', ''));

      let maxValue;

      switch (values.volumeType) {
        case AmountSelectorOptions.BCU.value:
          maxValue = parseFloat(selectedBCU?.credit?.volume || '0');
          break;
        case AmountSelectorOptions.CO2.value:
          maxValue = parseFloat(selectedBCU?.credit?.tonnesOfCO2eq || '0');
          break;
        case AmountSelectorOptions.MJ.value:
          maxValue = Math.round(selectedBCU?.credit?.neatAmountInMJ || '0');
          break;
        default:
          maxValue = parseFloat(selectedBCU?.credit?.volume || '0');

          break;
      }

      if (
        values.volume === '' ||
        isNaN(volumeValue) ||
        volumeValue < 0 ||
        volumeValue > maxValue
      ) {
        errors.volume = `Value must be a number between 0 and ${
          maxValue.toLocaleString('en-US', {
            maximumFractionDigits:
              values.volumeType === AmountSelectorOptions.MJ.value ? 0 : 3,
            minimumFractionDigits:
              values.volumeType === AmountSelectorOptions.MJ.value ? 0 : 3,
          }) || '?'
        }`;
      }

      return errors;
    },
    onSubmit: async values => {
      await dispatch(
        fetchTransfer({
          BCUID: values.BCUID,
          amount: parseFloat(values.volume.replaceAll(',', '') || '0'),
          amountType: values.volumeType || AmountSelectorOptions.BCU.value,
          receiverId: values.receiverId,
        }),
      );

      toast.success({
        message: 'Success',
        description: 'Your transfer request has been sent',
      });
      dispatch(fetchCompanyData());
      navigate(STATEMENTS);
    },
  });

  const handleBcuIdChange = (option: any) => {
    if (option && option.value) {
      form.setFieldValue('BCUID', option.value);
      setSelectedBCU(option);
    } else {
      form.setFieldValue('BCUID', '');
      setSelectedBCU(undefined);
    }
  };

  const handleSubmit = async () => {
    const errors = await form.validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    setShow(true);
  };

  const handleClose = async (state: boolean) => {
    setShow(false);

    if (!state) {
      return;
    }

    return form.submitForm();
  };

  const { amountSelector, amountType } = useAmountSelector(selectedBCU, form);

  useEffect(() => {
    form.setFieldValue('volumeType', amountType, false);
  }, [amountType]);

  return (
    <>
      <div className="c-transfer">
        <div className="c-transfer__container">
          <form className="c-transfer__form">
            <div className="c-transfer__row">
              <div className="c-transfer__col">
                <div className="c-transfer__form__header">BCU Transfer</div>

                <DefaultSelect
                  inputId="BCUID"
                  name="BCUID"
                  label="BCU ID"
                  required
                  form="transferForm"
                  error={form.errors.BCUID}
                  options={creditsOptions as any}
                  onChange={handleBcuIdChange}
                  value={form.values.BCUID}
                />

                {amountSelector}

                <DefaultSelect
                  inputId="receiverId"
                  name="receiverId"
                  label="Transfer to"
                  required
                  error={form.errors.receiverId}
                  isClearable
                  isSearchable
                  options={contactListOptions}
                  onChange={(option: any) =>
                    form.setFieldValue('receiverId', option ? option.value : '')
                  }
                  value={form.values.receiverId}
                />
              </div>

              <div className="c-transfer__col">
                <div className="c-transfer-info">
                  <div className="c-transfer-info__header">
                    Scope 1 - transport service provider info provided
                  </div>
                  <TransferInfoEntry
                    label="Transportation service provider"
                    value={
                      selectedBCU?.credit?.scope1Company?.name
                    }></TransferInfoEntry>
                  <TransferInfoEntry
                    label="Country where BCU is claimed"
                    value={
                      selectedBCU?.credit?.creditClaimCountry
                    }></TransferInfoEntry>
                  <TransferInfoEntry
                    label="Reporting"
                    value={selectedBCU?.credit?.reporting}></TransferInfoEntry>
                </div>

                <div className="c-transfer-info">
                  <div className="c-transfer-info__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none">
                      <path
                        d="M12 12L12 16.8M12 8.4422V8.40001M2.4 18L2.4 5.9999C2.4 4.01168 4.01178 2.3999 6 2.3999L18 2.3999C19.9882 2.3999 21.6 4.01168 21.6 5.9999L21.6 18C21.6 19.9882 19.9882 21.6 18 21.6H6C4.01178 21.6 2.4 19.9882 2.4 18Z"
                        stroke="#07ACA6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="c-transfer-info__text">
                    <p>
                      Once you have submitted the transfer, it will be reviewed
                      by the RSB System Admin.
                    </p>
                    <p>
                      This process may take up to 48 hours. When the transfer is
                      complete, both you and the recipient will receive an email
                      notification.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="align-right mt-5">
              <NextButton
                handleClick={handleSubmit}
                text="Submit transfer"
                style={{ margin: '0', width: '147px' }}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmationPrompt onClosed={handleClose} show={show}>
        {selectedBCU?.credit.facilitatorId &&
        user?.company.type !== CompanyType.Facilitator ? (
          <p>
            The BCU is assigned to the {selectedBCU?.credit.facilitator.name}{' '}
            for administration. Do you want to proceed regardless?
          </p>
        ) : (
          <p>
            Please confirm your BCU transfer to "
            <strong>
              {contactListOptions.find(v => v.value === form.values.receiverId)
                ?.label || ''}
            </strong>
            "?
          </p>
        )}
      </ConfirmationPrompt>
    </>
  );
};
