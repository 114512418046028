
import Intro from '../../../../assets/intro.svg';
import { Link } from 'react-router-dom';

import './IntroSectionStyles.scss';

export const IntroSection = () => {
  return (
    <div className='intro-section'>
      <div className='intro-inner-wrap'>
        <div className='intro-inner'>
          <div className='intro-left'>
              <h3 className='intro-title'>What is the RSB Registry?</h3>
              <p className='intro-desc mb-4'>
                  The RSB Book & Claim Registry is a secure cloud application that allows companies to participate in the Book & Claim system, enabling the decoupling the sustainability attributes from the physical product and register it as a Book & Claim Unit (BCU).
                  This innovative registry streamlines the registration, tracking, and retirement of BCUs worldwide, providing companies with retirement statements that enhance the credibility of their sustainability claims.
              </p>
              <p className='intro-desc'>
                  To better understand how the Book & Claim program works, click below to learn more.
              </p>
          </div>
          <div className='intro-right'>
              <img
                  className="intro-image"
                  src={Intro}
                  alt="IntroImage"
              />
          </div>
          
        </div>
        <div className='intro-btn-wrap'>
          <button className='intro-btn'>
              <Link to="https://rsb.org/programmes/book-and-claim/" target="_blank" rel="noopener noreferrer">
                  Learn more
              </Link>
          </button>
        </div>
      </div>
    </div>
  );
}
