/**
 * @param {Date} dateTimeString
 * @returns {Date}
 */

export const extractTime = (dateTimeString: Date | null, isForDate: boolean) => {
  const date = dateTimeString ? new Date(dateTimeString) : null;

  if (!date || isForDate) return "";

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

export const formatDateTime = (dateTimeString: Date | null) => {
  if (!dateTimeString) return null;

  const formatedDate = new Date(dateTimeString);

  if (isNaN(formatedDate.getTime())) return null;

  return formatedDate ? formatedDate : null; 
}