import { useEffect, useMemo, useState } from "react";
import { Input, TablePaginationConfig } from "antd";
import {
  FilterValue,
  SortOrder,
  SorterResult,
} from 'antd/es/table/interface';
import { SearchOutlined } from "@ant-design/icons";

import { useGetAdminContactListQuery } from "../../../../../API";
import { selectedRow } from "../../../../../store/reducers/admin/adminReducer";
import { useAppSelector } from "../../../../../store/hooks";
import { Contact } from "../../../../components/ContactList";
import { BaseTable } from "../../../../components/BaseTable/BaseTable";
import { ACCOUNT_CONTACT_COLUMNS } from "../models/columns.constant";

interface TableParams {
  pagination?: TablePaginationConfig;
  order?: SortOrder;
  field?: React.Key | readonly React.Key[];
  filters?: Record<string, FilterValue | null>;
}

export const Contacts = () => {
  const account = useAppSelector(selectedRow);
  const { data } = useGetAdminContactListQuery({ id: account.id});  
  const [items, setItems] = useState((data || []) as Contact[]);
  
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
      position: ['bottomRight'],
    },
    field: 'createdAt',
    order: 'ascend',
  });

  useEffect(() => {
    setItems(data);
  }, [data]);

  const handleSearch = (query: string) => {
    if (query === '') {
      return setItems(data);
    }

    setItems(
      (data as Contact[]).filter((item, index) => {
        return `${item.to?.name} ${item.to?.country} ${item.to?.type} ${item.to?.status}`
          .toLowerCase()
          .includes(query.toLowerCase());
      }),
    );
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Contact> | SorterResult<Contact>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const columns = useMemo(
    () => ACCOUNT_CONTACT_COLUMNS(account?.certifiedFacilitatorIds, account?.type),
    [account?.certifiedFacilitatorIds, account?.type]
  );
  
  return (
    <div className="contact-list-wrapper">
      <div className="contact-list-token">
        <div className="contact-list-token_header">Contact List</div>
      </div>

      <div className="contact-list-table">
        <Input
          suffix={<SearchOutlined />}
          className="contact-list-search"
          size="large"
          placeholder="Search"
          onChange={e => handleSearch(e.target.value)}
          allowClear
        />
        <BaseTable
          className="has-striped-rows"
          rowKey={record => record.toId || ''}
          dataSource={items || []}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          columns={columns}
        />
      </div>
    </div>
  )
}
