import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginatedTable } from '../../../components/Table/Table';
import {
  fetchSubmissionsTransactions,
  fetchSubmissionsUsers,
  fetchSubmissionsUserUpdates,
  Submission,
} from '../../../../API/admin/submissions';
import { SubmissionsType } from '../../../../structure/request/admin';
import {
  selectSubmissionsStatus,
  selectSubmissionsTransactions,
  selectSubmissionsUsers,
  selectSubmissionsUserUpdates,
  selectTransactionType,
} from '../../../../store/reducers/admin/submissionsReducer';
import { Option, Select } from '../../../components/Form/Select/Select';
import {
  PROFILE_UPDATE_COLUMNS,
  REGISTRATION_COLUMNS,
  TRANSACTIONS_COLUMNS,
} from './models/columns.constant';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES,
} from '../../../../structure/routes/routes';
import { STATUSES, TYPES } from './models/options.constant';
import { Search } from '../../../components/Table/Search/Search';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useSSE } from '../../../../hooks/useSSE';
import { TableStructure } from '../../../../helpers/updateTableData';
import { useCombinedFilter } from '../../../../hooks/useLocalStorageFilter';
import { SingleValue } from 'react-select';
import { TABLES } from '../../../../constants/tables';
import { useSearchParamsState } from '../../../components/Table/Pagination/PaginationHook';

import './SubmissionsListStyle.scss';

const getTableProps = (
  type: SubmissionsType,
  navigate: NavigateFunction,
): any => {
  const props: any = {
    [SubmissionsType.BCURegistration]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS.filter(
        ({ keyItem }) => keyItem !== 'receiver',
      ),
      onRowClick: (item: Submission) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO.replace(
            ':id',
            item.id.toString(),
          ),
        );
      },
    },
    [SubmissionsType.BCUTransfer]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS,
      onRowClick: (item: Submission) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO.replace(
            ':id',
            item.id.toString(),
          ),
        );
      },
    },
    [SubmissionsType.BCURetirement]: {
      fetchData: fetchSubmissionsTransactions,
      selectData: selectSubmissionsTransactions,
      columns: TRANSACTIONS_COLUMNS.filter(
        ({ keyItem }) => keyItem !== 'receiver',
      ),
      onRowClick: (item: Submission) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO.replace(
            ':id',
            item.id.toString(),
          ),
        );
      },
    },
    [SubmissionsType.Registration]: {
      fetchData: fetchSubmissionsUsers,
      selectData: selectSubmissionsUsers,
      columns: REGISTRATION_COLUMNS,
      onRowClick: (item: Submission) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO.replace(
            ':id',
            item.id.toString(),
          ),
        );
      },
    },
    [SubmissionsType.ProfileUpdate]: {
      fetchData: fetchSubmissionsUserUpdates,
      selectData: selectSubmissionsUserUpdates,
      columns: PROFILE_UPDATE_COLUMNS,
      onRowClick: (item: Submission) => {
        navigate(
          ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.PROFILE_INFO.replace(
            ':id',
            item.id.toString(),
          ),
        );
      },
    },
  };

  return props[type];
};

export const SubmissionList: React.FC = () => {
  const eventsSubject = useSSE();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const transactionType = useAppSelector(selectTransactionType);

  const [type, setType] = useCombinedFilter(
    TABLES.SUBMISSION,
    'type',
    transactionType as SubmissionsType,
  ) as any;
  const [status, setStatus] = useCombinedFilter(
    TABLES.SUBMISSION,
    'status',
    null,
  ) as any;

  const [page, setPage] = useSearchParamsState<number>('page', 1);
  const [search, setSearch] = useSearchParamsState<string | null>(
    'search',
    null,
  );

  const [innerType, setInnerType] = useState(type);
  const [lastEventId, setLastEventId] = useState<TableStructure | undefined>();

  useMemo(() => {
    switch (lastEventId) {
      case TableStructure.SubmissionsTransferTransactions:
        if (innerType === SubmissionsType.BCUTransfer) {
          dispatch(
            fetchSubmissionsTransactions({
              type: SubmissionsType.BCUTransfer,
            } as any),
          );
        }
        break;

      case TableStructure.SubmissionsRetirementTransactions:
        if (innerType === SubmissionsType.BCURetirement) {
          dispatch(
            fetchSubmissionsTransactions({
              type: SubmissionsType.BCURetirement,
            } as any),
          );
        }
        break;

      case TableStructure.SubmissionsRegisterTransactions:
        if (innerType === SubmissionsType.BCURegistration) {
          dispatch(
            fetchSubmissionsTransactions({
              type: SubmissionsType.BCURegistration,
            } as any),
          );
        }
        break;

      case TableStructure.SubmissionsUsers:
        if (innerType === SubmissionsType.Registration) {
          dispatch(fetchSubmissionsUsers({} as any));
        }
        break;

      case TableStructure.SubmissionsUserUpdates:
        if (innerType === SubmissionsType.ProfileUpdate) {
          dispatch(fetchSubmissionsUserUpdates({} as any));
        }
        break;
    }
  }, [lastEventId, innerType, dispatch]);

  useEffect(() => {
    if (!eventsSubject) return;

    const subscription = eventsSubject.subscribe(({ id, data }) => {
      setLastEventId(id);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [eventsSubject]);

  const handleStatusChange = useCallback(
    (option: SingleValue<Option<any>>) => {
      const newStatus = option?.value || null;
      setStatus(newStatus);
    },
    [setStatus],
  );

  const handleTypeChange = useCallback(
    (option: SingleValue<Option<SubmissionsType>>) => {
      const newType = option?.value || SubmissionsType.BCURegistration;
      setInnerType(newType);
      setType(newType);
    },
    [setInnerType, setType],
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || null;
      setSearch(value);
    },
    [setSearch],
  );

  const handleClearSearch = useCallback(() => {
    setSearch(null);
  }, [setSearch]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  return (
    <div className="submissions-list">
      <div className="submissions-list__header">
        <h1 className="submissions-list__header_text">Submissions List</h1>
        <div className="submissions-list__header__controls">
          <Select
            placeholder="Type"
            options={TYPES}
            isClearable={false}
            value={type as any}
            onChange={handleTypeChange}
          />
          <Select
            isClearable={false}
            placeholder="Status"
            options={STATUSES}
            value={status}
            onChange={handleStatusChange}
          />
          <Search
            isClearable={Boolean(search)}
            defaultValue={search || ''}
            onClear={handleClearSearch}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <PaginatedTable
        variant="submissionList"
        {...getTableProps(type, navigate)}
        selectStatus={selectSubmissionsStatus}
        filters={{
          type,
          status,
          search: search,
        }}
        tableName={TABLES.SUBMISSION}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};
