import { useEffect, useState } from 'react';

export const useAntPagination = (tableName: string, defaultLimit: number = 10) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(defaultLimit);

  useEffect(() => {
    const storedFilters = localStorage.getItem('tableFilters');
    if (storedFilters) {
      const filters = JSON.parse(storedFilters);
      const savedPage = filters?.[tableName]?.page;
      const savedLimit = filters?.[tableName]?.limit;
      if (savedPage) setCurrentPage(savedPage);
      if (savedLimit) setLimit(savedLimit);
    }
  }, [tableName]);

  const savePageToLocalStorage = (page: number, limit: number) => {
    const storedFilters = localStorage.getItem('tableFilters');
    const filters = storedFilters ? JSON.parse(storedFilters) : {};
    filters[tableName] = { page, limit };
    localStorage.setItem('tableFilters', JSON.stringify(filters));
  };

  const handlePageChange = (page: number, limit: number) => {
    setCurrentPage(page);
    setLimit(limit || defaultLimit);
    savePageToLocalStorage(page, limit || defaultLimit);
  };

  return {
    currentPage,
    limit,
    handlePageChange,
  };
};
