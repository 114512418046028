import { PaginatedData } from '../../../structure/request/pagination';
import {
  SubmissionsCompanyInfo,
  SubmissionsCompany,
} from '../../../structure/models/admin/submissions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SubmissionsType } from '../../../structure/request/admin';
import { Submission } from '../../../API/admin/submissions';

interface SubmissionsState {
  users: PaginatedData<Submission>;
  userUpdates: PaginatedData<Submission>;
  transactions: PaginatedData<Submission>;
  details: SubmissionsCompanyInfo | any;
  status: 'idle' | 'loading' | 'failed';
  transactionType: SubmissionsType;
  logs: any;
}

const initialState: SubmissionsState = {
  status: 'idle',
  transactionType: SubmissionsType.Registration,
  users: {
    count: 0,
    results: [],
  },
  userUpdates: {
    count: 0,
    results: [],
  },
  transactions: {
    count: 0,
    results: [],
  },
  logs: [],
  details: {} as any,
};

export const adminSubmissionsSlice = createSlice({
  name: 'admin-submissions',
  initialState,
  reducers: {
    setSubmissionsUsers: (
      state,
      action: PayloadAction<PaginatedData<Submission>>,
    ) => {
      state.users = action.payload;
    },
    setSubmissionsUserUpdates: (
      state,
      action: PayloadAction<PaginatedData<Submission>>,
    ) => {
      state.userUpdates = action.payload;
    },
    setSubmissionsTransactions: (
      state,
      action: PayloadAction<PaginatedData<Submission>>,
    ) => {
      state.transactions = action.payload;
    },
    // TODO fix it
    setStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'failed'>,
    ) => {
      state.status = action.payload;
    },
    setSubmissionsDetails: (state, action: PayloadAction<Submission>) => {
      state.details = {
        ...action.payload,
        message: action.payload.message || '',
      };
    },
    setSubmissionsLogs: (
      state,
      action: PayloadAction<PaginatedData<Submission>>,
    ) => {
      state.logs = action.payload;
    },
    setSubmissionsMessage: (state, action) => {
      state.details.message = action.payload;
    },
    setTransactionType: (state, action) => {
      state.transactionType = action.payload;
    },
  },
});

export const {
  setStatus,
  setSubmissionsUsers,
  setSubmissionsUserUpdates,
  setSubmissionsTransactions,
  setSubmissionsDetails,
  setSubmissionsLogs,
  setSubmissionsMessage,
  setTransactionType,
} = adminSubmissionsSlice.actions;

export const selectSubmissionsUsers = (state: RootState) =>
  state.adminSubmissions.users;
export const selectSubmissionsUserUpdates = (state: RootState) =>
  state.adminSubmissions.userUpdates;
export const selectSubmissionsTransactions = (state: RootState) =>
  state.adminSubmissions.transactions;
export const selectSubmissionsStatus = (state: RootState) =>
  state.adminSubmissions.status;
export const selectSubmissionsDetailsStatus = (state: RootState) =>
  state.adminSubmissions.details.status;
export const selectSubmissionsCompany = (
  state: RootState,
): SubmissionsCompany => state.adminSubmissions.details?.company;
export const selectSubmissionsMessage = (state: RootState) =>
  state.adminSubmissions.details.message || '';
export const selectSubmissionsDetails = (state: RootState) =>
  state.adminSubmissions.details;
export const selectSubmissionsLogs = (state: RootState) =>
  state.adminSubmissions.logs;
export const selectTransactionType = (state: RootState) =>
  state.adminSubmissions.transactionType;

export default adminSubmissionsSlice.reducer;
