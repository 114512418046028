import { actions } from './fetchProvider/fetch';
import { URL_USER_FEEDBACK } from './urls/urls';

export const fetchFeedback = (data: any) => () => {
  return actions.post(URL_USER_FEEDBACK, {
    payloads: {
      feedback: data
    },
  });
};
