import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { EditableColumn } from '../../../../components/Table/Table';
import { Input } from '../../../../components/Form/Input';
import { Phone } from '../../../../components/Form/Phone';
import { Col, Row } from 'react-bootstrap';
import { ErrorBlocks } from '../../../../components/Form/FormHook';
import StatusComponent from '../../../../components/StatusComponent';
import { DefaultSelect } from '../../../../components/Form/DefaultSelect/DefaultSelect';
import { UserStatusOptions } from '../../../../../structure/models/user/user';

export const USERS_COLUMNS = (
  showErrorsBlocks: ErrorBlocks,
): EditableColumn<any>[] => [
  {
    title: <span className='active-header'>SU ID</span>,
    keyItem: 'SUID',
    sort: true,
    render: (item: any) => item.SUID,
  },
  {
    title: 'Date & time',
    keyItem: 'createdAt',
    sort: true,
    render: (item: any) =>
      item.createdAt ? formatDate(item.createdAt, DATE_WITH_TIME_FORMAT) : '',
  },
  {
    title: 'Contact name',
    keyItem: 'firstName',
    sort: true,
    render: (item: any) =>
      item?.firstName && item?.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : '',
    renderEdit: (item: any) => (
      <Row className="users__table__contact-name">
        <Col>
          <Input
            name="firstName"
            defaultValue={item?.firstName}
            error={showErrorsBlocks['firstName']}
          />
        </Col>
        <Col>
          <Input
            name="lastName"
            defaultValue={item?.lastName}
            error={showErrorsBlocks['lastName']}
          />
        </Col>
      </Row>
    ),
  },
  {
    title: 'Email',
    keyItem: 'email',
    sort: true,
    render: (item: any) => item?.email,
    renderEdit: (item: any) => (
      <Input
        name="email"
        className="users__table__email"
        defaultValue={item?.email}
        error={showErrorsBlocks['email']}
      />
    ),
  },
  {
    title: 'Phone',
    keyItem: 'cellPhone',
    sort: true,
    render: (item: any) =>
      item.cellPhone
        ? typeof item.cellPhone === 'string'
          ? item.cellPhone
          : `${item?.cellPhone?.code}${item?.cellPhone?.number}`
        : '',
    renderEdit: (item: any) => (
      <Phone
        name="cellPhone"
        className="users__table__phone"
        value={item?.cellPhone}
        error={
          showErrorsBlocks['cellPhone_number'] ||
          showErrorsBlocks['cellPhone_code']
        }
      />
    ),
  },
  {
    title: 'Position',
    keyItem: 'position',
    sort: true,
    render: (item: any) => item?.position,
    renderEdit: (item: any) => (
      <Input
        name="position"
        className="users__table__position"
        defaultValue={item?.position}
        error={showErrorsBlocks['position']}
      />
    ),
  },
  {
    title: 'Status',
    keyItem: 'status',
    sort: true,
    render: (item: any) => <StatusComponent statusOfComponent={item?.status} />,
    renderEdit: (item: any) => (
      <DefaultSelect
        name="status"
        className="users__table__status"
        defaultValue={item?.status}
        options={UserStatusOptions.filter(
          userStatusOption => userStatusOption.value,
        )}
      />
    ),
  },
];
