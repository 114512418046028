import { ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'Company Info',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ADMIN_ACCOUNTS_AND_BALANCE_DETAILS,
  },
  {
    title: 'User Info',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_USER_INFO,
  },
  {
    title: 'Transactions',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_HISTORY,
  },
  {
    title: 'Balance',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_BALANCE,
  },
  {
    title: 'Fees',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_FEE_INFORMATION,
  },
  {
    title: 'Contacts',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_CONTACTS,
  },
  {
    title: 'Notes',
    path: ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_NOTES,
  },
];
