import React, { ComponentType, ElementType, useState } from 'react';
import './styles.scss';
import './async.scss';
import { PaginationBoardInTable } from '../../transfer/PaginationBoardInTable';
import { OnFilterChange } from './TableFilters';
import { ComponentWithSlots, Slot } from '../component';
import { Column, RowAfterProps, RowBeforeProps, TableHeadProps } from './types';
import { Table, TablePaginationConfig } from 'antd';

type AsyncTableProps<T = any> = {
  items: T[];
  total: number;
  limit: number;
  page: number;
  headers: Column<any>[];
  isLoading?: boolean;
  showPagination?: boolean;
  showSearch?: boolean;
  showFilters?: boolean;
  onRowClicked?: (item: T, rowIndex?: number, columnIndex?: number) => void;
  onLimitChange?: (limit: number) => void;
  onPageChange?: (page: number) => void;
  onFilter?: (filter: OnFilterChange) => void;
  onSearch?: (query: string) => void;
  slotProps?: {
    head?: TableHeadProps<T>;
    rowBefore?: RowBeforeProps<T>;
    rowAfter?: RowAfterProps<T>;
  };
  pagination?: TablePaginationConfig;
  noSortings?: boolean;
};

type AsyncTableSlots<
  T = any,
  H extends ElementType | ComponentType<TableHeadProps<T>> = 'thead',
  Rb extends ComponentType<{ item?: T; index?: number }> = any,
  Ra extends ComponentType<{ item?: T; index?: number }> = any,
> = {
  head?: Slot<H>;
  rowBefore?: Slot<Rb>;
  rowAfter?: Slot<Ra>;
};

const AsyncTable = <T = any,>({
  items,
  limit,
  page,
  onPageChange,
  onLimitChange,
  total,
  headers,
  slots,
  slotProps,
  onRowClicked,
  isLoading = false,
  showPagination = true,
  noSortings = false,
  ...props
}: ComponentWithSlots<AsyncTableProps<T>, AsyncTableSlots<T>>) => {
  const totalPages = Math.max(Math.ceil(total / limit), 1);

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    const key = record.BCUID;

    if (expanded) {
      setExpandedRowKeys(prevKeys => [...prevKeys, key]);
    } else {
      setExpandedRowKeys(prevKeys => prevKeys.filter(k => k !== key));
    }
  };

  const handleRowsPerPageChange = (selectedRowsPerPage: number) => {
    if (onLimitChange) onLimitChange(selectedRowsPerPage);
  };

  const handlePageChange = (page: number) => {
    if (onPageChange) onPageChange(page);
  };

  const columns = headers.map(column => {
    const currentHead = slotProps?.head;

    const isCurrentColumn =
      currentHead &&
      currentHead.current &&
      currentHead.current.key === column.keyItem;
    const sortDirection =
      isCurrentColumn && currentHead.current
        ? currentHead.current.direction
        : undefined;

    return {
      title: (
        <div
          onClick={() => {
            if (slotProps?.head?.onSortChange) {
              slotProps.head.onSortChange({ column });
            }
          }}
          style={{ cursor: 'pointer' }}>
          {column.title}
        </div>
      ),
      dataIndex: column.keyItem,
      key: column.keyItem,
      sorter: !noSortings,
      defaultSortOrder: sortDirection,
      render: (text: any, record: any) => {
        const key = column?.keyItem;
        return key && record[key] ? record[key] : '-';
      },
    };
  }) as any;

  const tableData = items.map((item: any) => {
    return headers.reduce((acc: any, header) => {
      if (header?.keyItem) {
        if (header?.keyItem === 'BCUID') {
          acc[header.keyItem] = `${item?.[header.keyItem]} (${
            item?.credits.length
          })`;
        } else if (
          header?.keyItem === 'scope1' ||
          header?.keyItem === 'scope3'
        ) {
          acc[header.keyItem] = item?.[header.keyItem]
            ? `${item?.[header.keyItem]} ${
                item?.[header.keyItem] > 1 ? 'companies' : 'company'
              }`
            : '';
        } else if (header?.keyItem === 'amount') {
          acc[header.keyItem] =
            item?.[header.keyItem] !== undefined && item?.[header.keyItem] !== null
              ? Number(item?.[header.keyItem])
                  .toFixed(3)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : '';
        } else {
          acc[header.keyItem] = item?.[header.keyItem] || '';
        }
      }

      acc['credits'] = item.credits;
      return acc;
    }, {});
  });

  return (
    <div className={`c-table c-table--async${isLoading ? ' is-loading' : ''}`}>
      <Table
        columns={columns}
        dataSource={tableData}
        expandable={{
          expandedRowRender: (record, index) => {
            const RowAfter = slots?.rowAfter;
            return RowAfter ? <RowAfter item={record} index={index} /> : null;
          },
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpand,
        }}
        rowKey={(record: any) => record.BCUID}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            if (onRowClicked) {
              onRowClicked(record, rowIndex);
            }
          },
        })}
        {...props}
      />
    </div>
  );
};

export default AsyncTable;
