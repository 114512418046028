import './History.scss';

export const History: React.FC = () => {
    return (
        <div className="history-wrap">
            <div className="history-head-wrap">
                <h3 className="history-title">Version History</h3>
                <p className="history-desc">Keep track of RSB Book & Claim Registry new features and enhancements</p>
            </div>
            <div className="history-content">
                <div className="timeline">
                    <div className="timeline-item">
                        <div className="timeline-dot"></div>
                        <div className="timeline-content">
                        <h3 className="timeline-title">New BCU Registration flow</h3>
                        <p className="timeline-date">November 11th, 2024</p>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-dot"></div>
                        <div className="timeline-content">
                        <h3 className="timeline-title">Feedback submission is now available</h3>
                        <p className="timeline-date">October 25th, 2024</p>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-dot"></div>
                        <div className="timeline-content">
                        <h3 className="timeline-title">Bug fixing and enhancements</h3>
                        <p className="timeline-date">October 10th, 2024</p>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="timeline-dot"></div>
                        <div className="timeline-content">
                        <h3 className="timeline-title">New Account role: Facilitator</h3>
                        <p className="timeline-date">October 1st, 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}