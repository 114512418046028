import { Column } from '../../../../components/Table/Table';
import classNames from 'classnames';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { Tag } from 'antd';
import { Prisma, SubmissionStatus, UserType } from '@prisma/client';

export const USER_INFO_TITLE = {
  role: 'Role',
  SUID: 'SUID',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  position: 'Position',
  status: 'Status',
};

const StatusLabels = {
  [SubmissionStatus.Approved]: 'Approved',
  [SubmissionStatus.Declined]: 'Rejected',
  [SubmissionStatus.Pending]: 'For Consideration',
};

type Submission = Prisma.SubmissionGetPayload<{
  include: {
    to: true;
    company: true;
    user: true;
    userUpdate: { include: { user: true } };
    creditTransaction: {
      include: {
        credit: true;
        newCredit: true;
        company: true;
        receiver: true;
        sender: true;
      };
    };
  };
}>;

export const REGISTRATION_COLUMNS: Column<Submission>[] = [
  {
    title: <span className='active-header'>Date & time</span>,
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'SUID',
    keyItem: 'SUID',
    render: item => item.user?.SUID,
  },
  {
    title: 'User name',
    keyItem: 'userName',
    render: item =>
      [item?.user?.firstName, item?.user?.lastName].filter(v => !!v).join(' '),
  },
  {
    title: 'Type',
    keyItem: 'type',
    render: item => (
      <Tag bordered={false} color="default">
        {item.user?.type === UserType.Secondary
          ? 'Additional user'
          : 'New account'}
      </Tag>
    ),
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionStatus.Approved,
          declined: item.status === SubmissionStatus.Declined,
          consideration: item.status === SubmissionStatus.Pending,
        })}>
        {StatusLabels[item.status] || 'Unknown'}
      </span>
    ),
  },
];

export const PROFILE_UPDATE_COLUMNS: Column<Submission>[] = [
  {
    title: <span className='active-header'>Date & time</span>,
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'SUID',
    keyItem: 'SUID',
    render: item => item.userUpdate?.user?.SUID,
  },
  {
    title: 'User name',
    keyItem: 'userName',
    render: item =>
      `${item.userUpdate?.user?.firstName} ${item.userUpdate?.user?.lastName}`,
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionStatus.Approved,
          declined: item.status === SubmissionStatus.Declined,
          consideration: item.status === SubmissionStatus.Pending,
        })}>
        {StatusLabels[item.status] || 'Unknown'}
      </span>
    ),
  },
];

export const TRANSACTIONS_COLUMNS: Column<Submission>[] = [
  {
    title: <span className='active-header'>Date & time</span>,
    keyItem: 'createdAt',
    sort: true,
    render: item => formatDate(item.createdAt, DATE_WITH_TIME_FORMAT),
  },
  {
    title: 'From/by',
    keyItem: 'companySender',
    render: item => item.creditTransaction?.company?.name,
  },
  {
    title: 'To',
    keyItem: 'receiver',
    render: item => item.creditTransaction?.receiver?.name,
  },
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => {
      const credit =
        item.creditTransaction?.newCredit || item.creditTransaction?.credit;

      return credit?.BCUID;
    },
  },
  {
    title: 'User',
    keyItem: 'senderUser',
    render: item => (
      <span className="submissions-list__user">
        {item.creditTransaction?.sender.firstName}{' '}
        {item.creditTransaction?.sender.lastName}
      </span>
    ),
  },
  {
    title: 'Amount of BCUs',
    keyItem: 'amount',
    render: item =>
      (
        item.creditTransaction?.newCredit?.volume ||
        item.creditTransaction?.credit?.volume ||
        item.creditTransaction?.amount ||
        1
      ).toLocaleString('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
    meta: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => (
      <span
        className={classNames('submissions-list__status', {
          approved: item.status === SubmissionStatus.Approved,
          declined: item.status === SubmissionStatus.Declined,
          consideration: item.status === SubmissionStatus.Pending,
        })}>
        {StatusLabels[item.status] || 'Unknown'}
      </span>
    ),
  },
];
