import React, { useEffect } from 'react';
import { Table } from 'antd';

import { selectSubmissionsDetails, selectSubmissionsLogs } from '../../../../../store/reducers/admin/submissionsReducer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { fetchSubmissionAdminLogs } from '../../../../../API/admin/submissions';
import { bcuLogsColumns } from '../../BCUList/Tabs/helpers/BcuLogs.columns';

export const AdminBCULogs: React.FC = () => {
  const dispatch = useAppDispatch();
  const details = useAppSelector(selectSubmissionsDetails);
  const submissionLogs = useAppSelector(selectSubmissionsLogs);
  const BCUID = details.creditTransaction?.newCredit?.BCUID || details.creditTransaction?.credit?.BCUID; 

  useEffect(() => {
    if (BCUID) {
        dispatch(fetchSubmissionAdminLogs(BCUID))
    }
  }, [BCUID])

  return (
    <div>
      <Table
        rowKey={record => record.id || ''}
        dataSource={submissionLogs || []}
        pagination={false}
        columns={bcuLogsColumns}
      />
    </div>
  );
};
