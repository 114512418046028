import React, { useEffect, useMemo } from 'react';
import { UserRole } from '@prisma/client';

import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { TABS } from './models/tabs.constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ViewElement } from '../../../components/ViewElement/ViewElement';
import { selectedRow } from '../../../../store/reducers/admin/adminReducer';
import { fetchCompanyInfo } from '../../../../API/admin/accounts';

import './AccountDetailStyle.scss';

export const AccountDetail: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { id } = useParams();
    const account = useAppSelector(selectedRow);

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchCompanyInfo(id!));
    }, [pathname]);

    const goBack = () => navigate(-1);

    const isBalanceTab = pathname.split('/').includes('balance');

    const filteredTabs = useMemo(() => {
        return account?.type === UserRole.Admin
            ? TABS.filter(tab => tab.title !== 'Contacts')
            : TABS;
    }, [account?.type]);

    return (
        <>
        {account && (<div className="account-detail">
            <div className="account-detail__back" onClick={goBack}>
                <span className="material-icons-outlined">arrow_back_ios</span>
                Accounts
            </div>
            <div className="account-detail__header">
                {account && (<div className="account-detail__header__info">
                    <ViewElement
                        label="Company name"
                        description={account?.name}></ViewElement>
                    {isBalanceTab && (
                        <ViewElement
                            label="Total amount"
                            description={account?.balance.toLocaleString('en-US', {
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3
                            }) || 0}></ViewElement>
                    )}
                </div>)}
            </div>
            <PageWithTabsLayout tabs={filteredTabs} />
        </div>)}
        </>
    );
};
