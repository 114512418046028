import {
  SubmissionsRequest,
  SubmissionsType,
} from '../../structure/request/admin';
import { AppThunk, AppThunkDispatch } from '../../store/store';
import {
    setStatus,
    setSubmissionsDetails,
    setSubmissionsLogs,
    setSubmissionsTransactions,
    setSubmissionsUsers, setSubmissionsUserUpdates,
} from '../../store/reducers/admin/submissionsReducer';
import { actions } from '../fetchProvider/fetch';
import { AxiosResponse } from 'axios';
import { ServerResponse } from '../types';
import { PaginatedData } from '../../structure/request/pagination';
import {
    URL_ADMIN_APPROVE_USER_REGISTRATION, URL_ADMIN_APPROVE_USER_UPDATE,
    URL_ADMIN_GET_COMPANY_INFO,
    URL_ADMIN_GET_SUBMISSIONS_TRANSACTION,
    URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS,
    URL_ADMIN_GET_SUBMISSIONS_USER_UPDATE,
    URL_ADMIN_GET_SUBMISSIONS_USER_UPDATES,
    URL_ADMIN_GET_SUBMISSIONS_USERS,
    URL_ADMIN_REJECT_USER_REGISTRATION, URL_ADMIN_REJECT_USER_UPDATE,
    URL_ADMIN_SUBMISSION_LOGS,
    URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE,
    URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE,
} from '../urls/urls';
import { Prisma } from '@prisma/client';

export type Submission = Prisma.SubmissionGetPayload<{ include: { to: true, company: true, user: true, userUpdate: { include: { user: true } }, creditTransaction: { include: { credit: true, newCredit: true, company: true, receiver: true, sender: true }} }}>;

export enum TransactionType {
  Register = 'Register',
  Transfer = 'Transfer',
  Retirement = 'Retirement',
}

const SUBMISSIONS_TYPES: Record<string, string> = {
  [SubmissionsType.BCURegistration]: TransactionType.Register,
  [SubmissionsType.BCURetirement]: TransactionType.Retirement,
  [SubmissionsType.BCUTransfer]: TransactionType.Transfer,
};

export const fetchSubmissionsUsers =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<AxiosResponse<ServerResponse<PaginatedData<Submission>>>>(
        URL_ADMIN_GET_SUBMISSIONS_USERS,
        {
          queries: {
            skip: (page - 1) * limit,
            limit,
            ...query,
          },
        },
      )
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsUsers(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsUserUpdates =
    ({
         type,
         page,
         limit,
         ...query
     }: SubmissionsRequest): AppThunk<Promise<any>> =>
        (dispatch: AppThunkDispatch) => {
            dispatch(setStatus('loading'));

            return actions
                .get<AxiosResponse<ServerResponse<PaginatedData<Submission>>>>(
                    URL_ADMIN_GET_SUBMISSIONS_USER_UPDATES,
                    {
                        queries: {
                            skip: (page - 1) * limit,
                            limit,
                            ...query,
                        },
                    },
                )
                .then(response => {
                    dispatch(setStatus('idle'));
                    dispatch(setSubmissionsUserUpdates(response.data.data));

                    return response.data.data;
                })
                .catch(e => {
                    dispatch(setStatus('failed'));
                    throw e;
                });
        };

export const fetchSubmissionsTransactions =
  ({
    type,
    page,
    limit,
    ...query
  }: SubmissionsRequest): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<
        AxiosResponse<ServerResponse<PaginatedData<Submission>>>
      >(URL_ADMIN_GET_SUBMISSIONS_TRANSACTIONS, {
        queries: {
          skip: (page - 1) * limit,
          limit,
          type: SUBMISSIONS_TYPES[type],
          ...query,
        },
      })
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsTransactions(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsCompanyInfo =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_ADMIN_GET_COMPANY_INFO.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsDetails(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchSubmissionsProfileInfo =
    (id: string) => (dispatch: AppThunkDispatch) => {
        dispatch(setStatus('loading'));

        return actions
            .get<any>(URL_ADMIN_GET_SUBMISSIONS_USER_UPDATE.replace(':id', id))
            .then(response => {
                dispatch(setStatus('idle'));
                dispatch(setSubmissionsDetails(response.data.data));

                return response.data.data;
            })
            .catch(e => {
                dispatch(setStatus('failed'));
                throw e;
            });
    };

export const fetchRegistrationReject =
  ({ id, message }: any) =>
  () => {
    return actions.post(URL_ADMIN_REJECT_USER_REGISTRATION.replace(':id', id), {
      payloads: { message },
    });
  };

export const fetchRegistrationApprove =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_APPROVE_USER_REGISTRATION.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };

export const fetchUserUpdateReject =
    ({ id, message }: any) =>
        () => {
            return actions.post(URL_ADMIN_REJECT_USER_UPDATE.replace(':id', id), {
                payloads: { message },
            });
        };

export const fetchUserUpdateApprove =
    ({ id, message }: any) =>
        () => {
            return actions.post(
                URL_ADMIN_APPROVE_USER_UPDATE.replace(':id', id),
                {
                    payloads: { message },
                },
            );
        };

export const fetchSubmissionsTransactionInfo =
  (id: string) => (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get<any>(URL_ADMIN_GET_SUBMISSIONS_TRANSACTION.replace(':id', id))
      .then(response => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsDetails(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };

export const fetchTransactionReject =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_SUBMISSIONS_TRANSACTIONS_DECLINE.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };

export const fetchTransactionApprove =
  ({ id, message }: any) =>
  () => {
    return actions.post(
      URL_ADMIN_SUBMISSIONS_TRANSACTIONS_APPROVE.replace(':id', id),
      {
        payloads: { message },
      },
    );
  };

export const fetchSubmissionAdminLogs = (id: any): AppThunk<Promise<any>> =>
  (dispatch: AppThunkDispatch) => {
    dispatch(setStatus('loading'));

    return actions
      .get(URL_ADMIN_SUBMISSION_LOGS.replace(':id', id), {})
      .then((response: any) => {
        dispatch(setStatus('idle'));
        dispatch(setSubmissionsLogs(response.data.data));

        return response.data.data;
      })
      .catch(e => {
        dispatch(setStatus('failed'));
        throw e;
      });
  };
