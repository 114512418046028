import React from 'react';
import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../../core/models/contstants/format-date';
import { BCUStatuses } from '../../../../structure/models/bcu/bcu';

export const getBcuTitles = (credit: any) => [
  {
    key: 'productType',
    label: 'Product type',
    content: credit.productType,
  },
  {
    key: 'rawMaterial',
    label: 'Feedstock',
    content: credit.rawMaterial,
  },
  {
    key: 'registrationApproveDate',
    label: 'Date of registration',
    content: formatDate(credit.registrationApproveDate),
  },
  {
    key: 'volume',
    label: 'Amount of Product (t)',
    content: credit.volume.toLocaleString(),
    meta: {
      style: {
        textAlign: 'right',
        paddingRight: '75px',
      },
    },
  },
  {
    key: 'neatAmountInMJ',
    label: 'Amount of Product (MJ)',
    content: credit.neatAmountInMJ.toLocaleString(),
    meta: {
      style: {
        textAlign: 'right',
        paddingRight: '75px',
      },
    },
  },
  {
    key: 'batches',
    label: 'Batch number',
    content: credit.batchNumber,
  },
  {
    key: 'rawMaterial',
    label: 'Raw material type',
    content: credit.rawMaterial,
  },
  {
    key: 'certificationScheme',
    label: 'Certification scheme applicable',
    content: credit.certificationScheme,
  },
  {
    key: 'id',
    label: 'POS PDF',
    content: '',
  },
  {
    key: 'deliveryCountry',
    label: 'Delivery Country',
    content: credit.deliveryCountry,
  },
  {
    key: 'acquisitionDate',
    label: 'Date of Acquisition',
    content: formatDate(credit.acquisitionDate, DATE_WITH_FULL_MONTH),
  },
  {
    key: 'entryInPOChainDate',
    label:
      "Date of Entry in the PO's Chain of Custody Tracking and Management Systems",
    content: formatDate(credit.entryInPOChainDate, DATE_WITH_FULL_MONTH),
  },
  {
    key: 'supplierAddress',
    label: 'Address of Supplier',
    content: `${credit.supplierAddress} ${credit.supplierAddressNumber} ${credit.supplierCity}, ${credit.supplierCountry} ${credit.supplierZip}`,
  },
  {
    key: 'lastProductionCountry',
    label: 'Country of the last production/processing site',
    content: credit.lastProductionCountry || credit.lastProductionName,
  },
  {
    key: 'chainCustodyModel',
    label: 'Chain of custody model employed at the supplier’s last site',
    content: credit.chainCustodyModel,
  },
  {
    key: 'tonnesOfCO2eq',
    label: (
      <span>
        CO<sub>2</sub>eq reduction (t)
      </span>
    ),
    content: credit.tonnesOfCO2eq,
  },
  {
    key: 'LCAGHGValue',
    label: (
      <span>
        Average LCA GHG value (gCO<sub>2</sub>eq/MJ)
      </span>
    ),
    content: credit.LCAGHGValue,
  },
  {
    key: 'GHGEmissionReduction',
    label: 'Average GHG Emission Reduction(%)',
    content: credit.GHGEmissionReduction,
  },
  {
    key: 'additionalRSBClaim',
    label: 'Claim as allowed under the RSB certification system',
    content: credit.additionalRSBClaim,
  },
  {
    key: 'incentivesSAFDeclarationAndTaxCredits',
    label: 'Declaration of incentives and tax credits',
    content: credit.incentivesSAFDeclarationAndTaxCredits,
  },
  {
    key: 'applied"',
    label: 'CORCIA certificate',
    content: 'Corcia certification scheme applied',
  },
  {
    key: 'neatSAFProducerName',
    label: 'Name of Neat SAF producer',
    content: credit.neatSAFProducerName,
  },
  {
    key: 'neatSAFProductionLocation',
    label: 'Production location of Neat SAF',
    content: credit.neatSAFProductionLocation,
  },
  {
    key: 'conversionProcess',
    label: 'Conversion process',
    content: credit.conversionProcess,
  },
  {
    key: 'defaultILUCValue',
    label: 'Default ILUC Value',
    content: credit.defaultILUCValue,
  },
  {
    key: 'feedstockConversionProcess',
    label: 'Feedstock conversion process',
    content: credit.feedstockConversionProcess,
  },
  {
    key: 'fieldBatchCountry',
    label: 'Country of the last production/processing site',
    content: credit.fieldBatchCountry,
  },
  {
    key: 'incentivesSAFDeclarationAndTaxCredits',
    label: 'Declaration of incentives and tax credits',
    content: credit.incentivesSAFDeclarationAndTaxCredits,
  },
  {
    key: 'neatSAFProductionDate',
    label: 'Production date of Neat SAF',
    content: formatDate(credit.neatSAFProductionDate, DATE_WITH_FULL_MONTH),
  },
  {
    key: 'landfillDirectionCreditDisaggregatedValue',
    label: 'Disaggregated Value for Landfill Emission Credit (LEC)',
    content: credit.landfillDirectionCreditDisaggregatedValue,
  },
  {
    key: 'recyclingEmissionCreditDisaggregatedValue',
    label: 'Disaggregated Value Recycling Emission Credit (REC)',
    content: credit.recyclingEmissionCreditDisaggregatedValue,
  },
  {
    key: 'scope3',
    label: 'Scope 3',
    content: credit.scope3?.name,
  },
];


export const BALANCE_STATUSES = [
  { value: BCUStatuses.Active, label: BCUStatuses.Active },
  { value: BCUStatuses.Suspended, label: BCUStatuses.Suspended },
  { value: BCUStatuses.Transit, label: BCUStatuses.Transit },
  { value: BCUStatuses.Inactive, label: BCUStatuses.Inactive },
];

export const REGISTRATION_YEARS = [
  { value: null, label: 'All' },
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

export const EXPIRE_DATE_RANGE = [
  { value: null, label: 'All' },
  { value: '3_months', label: 'Expire within 3 months' },
  { value: '6_months', label: 'Expire within 6 months' },
  { value: '1_year', label: 'Expire within 1 year' },
];
