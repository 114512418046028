import React from 'react';

import { SubmissionsType } from '../../../../structure/request/admin';
import {
  fetchSubmissionsTransactionInfo,
  fetchTransactionApprove,
  fetchTransactionReject,
} from '../../../../API/admin/submissions';

import { BCU_RETIREMENTS_TABS } from './models/bcu-transfer-tabs.constant';
import { AdminSubmissionsDetailsLayout } from './AdminSubmissionsDetailsLayout';

export const AdminRetirementsLayout: React.FC = () => {
  return (
    <AdminSubmissionsDetailsLayout
      fetchData={fetchSubmissionsTransactionInfo}
      fetchReject={fetchTransactionReject}
      fetchApprove={fetchTransactionApprove}
      tabs={BCU_RETIREMENTS_TABS}
      type={SubmissionsType.BCURetirement}
    />
  );
};
