import React from 'react';
import { PageWithTabsLayout } from '../../../layouts/PageWithTabsLayout';
import { TABS } from './models/tabs.constant';

import './ReferenceValues.scss';

export const ReferenceValues: React.FC = () => {
  return (
    <div className="c-reference-values">
      <div className="c-reference-values__header">
        <h1 className="c-reference-values__header-text">Reference Values</h1>
      </div>
      <PageWithTabsLayout tabs={TABS} />
    </div>
  );
};
