import { HeroSection } from './HeroSection/HeroSection';
import { IntroSection } from './IntroSection/IntroSection';
import { BenefitsSection } from './BenefitsSection/BenefitsSection';
import { GetStartedSection } from './GetStartedSection/GetStartedSection';
import { StartUsingSection } from './StartUsingSection/StartUsingSection';
import { AccountTableSection } from './AccountTableSection/AccountTableSection';
import { DemoSection } from './DemoSection/DemoSection';

import './Instruction.scss';

export const Information = () => {
  return (
    <div className="c-information">
      <HeroSection />
      <IntroSection /> 
      <BenefitsSection />
      <GetStartedSection />
      <StartUsingSection />
      <AccountTableSection />
      <DemoSection />
    </div>
  );
}
