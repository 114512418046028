import React, { useEffect, useMemo, useState } from 'react';
import { OnFilterChange } from '../../../components/DefaultTable/TableFilters';
import classNames from 'classnames';
import { Table } from 'antd';
import { TransactionStatus } from '@prisma/client';
import { useNavigate } from 'react-router-dom';
import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../core/models/contstants/format-date';
import {
  Transaction,
  selectIncomingCredits,
} from '../../../../store/reducers/user/creditsReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fetchCredits,
  fetchIncomingCreditApprove,
  fetchIncomingCreditReject,
  fetchIncomingCredits,
} from '../../../../API/credits';
import { ConfirmationPrompt } from '../../../components/ConfirmationPrompt';
import { CreditInfoRequestStatus } from '../../../../structure/models/credits/credits';
import { fetchCompanyData } from '../../../../API/user';
import { TRANSFER_ROUTES } from '../../../../structure/routes/routes';
import { useAntPagination } from '../../../components/GenericTable/helpers';
import { Search } from '../../../components/GenericTable/Search/Search';

import './IncomingTransactions.scss';

enum ActionType {
  approve = 'approve',
  reject = 'reject',
}

const mapItemStatusToComponent = (status: string) => {
  switch (status) {
    case TransactionStatus.Approved:
      return 'Approved';
    case TransactionStatus.Pending:
      return 'For consideration';
    case TransactionStatus.Denied:
      return 'Rejected';
  }
};

export const getColumns = (onFilter: (values: any) => void) => {
  return [
    {
      title: 'Date and time',
      sort: true,
      keyItem: 'approveDate',
      dataIndex: 'approveDate',
    },
    {
      title: 'From/by',
      keyItem: 'fromBy',
      dataIndex: 'fromBy',
    },
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      dataIndex: 'BCUID',
    },
    {
      title: 'User',
      keyItem: 'user',
      dataIndex: 'user',
    },
    {
      title: 'Amount',
      keyItem: 'amount',
      dataIndex: 'amount',
    },
    {
      title: 'Status',
      keyItem: 'status',
      dataIndex: 'statusValue',
      filters: [
        { text: 'Approved', value: TransactionStatus.Approved },
        { text: 'For consideration', value: TransactionStatus.Pending },
        { text: 'Rejected', value: TransactionStatus.Denied },
      ],
      filterMultiple: true,
      onFilter: (value: any, record: any) => record.statusValue === value,
      render: (_: any, record: any) => record.statusComponent
    },
    {
      title: 'Actions',
      keyItem: 'actions',
      dataIndex: 'actions',
    },
  ];
};

export const IncomingTransactionsPage: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectIncomingCredits);

  const [items, setItems] = useState(data || []) as any;
  const [context, setContext] = useState<ActionType | null>(null);
  const [selectedId, setSelectedId] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const onConfirmationClose = (status: boolean) => {
    if (status) {
      if (context === ActionType.approve)
        dispatch(fetchIncomingCreditApprove(selectedId));
      if (context === ActionType.reject)
        dispatch(fetchIncomingCreditReject(selectedId));
    }
    setSelectedId('');
    setContext(null);
    dispatch(fetchCompanyData());
  };

  const onRowClicked = async (item: any) => {
    navigate(TRANSFER_ROUTES.INCOMING_TRANSACTION.replace(':id', item.id));
  };

  const onDateSort = (data: Transaction[]) => {
    return [...data].sort(
      (curr, next) =>
        new Date(next.approveDate || new Date()).getTime() -
        new Date(curr.approveDate || new Date()).getTime(),
    );
  };

  const onApproveClick = (id: string) => {
    setContext(ActionType.approve);
    setSelectedId(id);
  };
  const onRejectClick = (id: string) => {
    setContext(ActionType.reject);
    setSelectedId(id);
  };

  const applyFilters = (data: Transaction[], search: string, filterValues: any) => {
    const sortedData = onDateSort(data);
    const tableData = getTableData(sortedData);
  
    const filteredBySearch = (tableData || []).filter((item: any) => {
      return `${item.BCUID} ${item.user} ${item.fromBy}`
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  
    const filteredByStatus = filterValues.length
      ? filteredBySearch.filter((item: any) => filterValues.includes(item.status))
      : filteredBySearch;
  
    return filteredByStatus;
  };

  const getTableData = (sortedData: any) => {
    const onAction = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      action: ActionType,
      id: number,
    ) => {
      e.stopPropagation();
  
      if (action === ActionType.approve) {
        onApproveClick(id.toString());
        return;
      }
      onRejectClick(id.toString());
    };

    return sortedData.map((item: any) => {
      const statusValue = item.status; 

      return {
        id: item.id,
        approveDate: item?.approveDate
          ? formatDate(item?.approveDate, DATE_WITH_TIME_FORMAT)
          : null,
        fromBy: item?.company?.name,
        BCUID: item.newCredit?.BCUID || item.credit?.BCUID,
        user: `${item.sender.firstName} ${item.sender.lastName}`,
        amount: 
          `${item.amount.toLocaleString('en-US', {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}
          BCU`,
        statusValue,
        statusComponent: (
          <div
            className={classNames('status-account--wrapper', {
              pending: statusValue === TransactionStatus.Pending,
              approved: statusValue === TransactionStatus.Approved,
              denied: statusValue === TransactionStatus.Denied,
            })}
          >
            <span className="status-account--text">
              {mapItemStatusToComponent(statusValue)}
            </span>
          </div>
        ),
        actions: item.status === CreditInfoRequestStatus.Pending ? (
          <div className="c-incoming-transactions__actions">
            <button onClick={e => onAction(e, ActionType.approve, item?.id)}>
              Approve
            </button>
            <button onClick={e => onAction(e, ActionType.reject, item.id)}>
              Reject
            </button>
          </div>
        ) : (
          ''
        ),
      }
    })
  }

  useEffect(() => {
    const sortedData = onDateSort(data);
    const tableData = getTableData(sortedData);

    setItems(tableData || []);
  }, [data]);

  const { currentPage, limit, handlePageChange } = useAntPagination(
    "Incoming",
    10,
  );

  const tablePagination = useMemo(() => {
    return {
      current: currentPage,
      pageSize: limit,
      onChange: handlePageChange,
      showSizeChanger: true,
    }
  }, [currentPage, limit, handlePageChange])

  useEffect(() => {
    dispatch(fetchIncomingCredits());
    dispatch(fetchCredits({}));
  }, []);

  const [search, setSearch] = useState('');

  const onSearch = (e: any) => {
    setSearch(e.target.value);
    const filteredItems = applyFilters(data, e.target.value, selectedStatuses);
    setItems(filteredItems);
  };

  const onFilter = ({ values }: OnFilterChange) => {
    const filteredItems = applyFilters(data, search, values);
    setItems(filteredItems);
    setSelectedStatuses(values as []);
  };

  const onClear = () => setSearch('');

  const columns = getColumns(onFilter);

  return (
    <>
      <div className='search-wrap'>
        <div className='incoming-title'></div>
        <Search
          isClearable={Boolean(search)}
          defaultValue={search ? search : ''}
          onClear={onClear}
          onChange={onSearch}
        />
      </div>
      <Table
        rowKey={(record: any) => record.id} 
        columns={columns}
        dataSource={items || []}
        pagination={tablePagination}
        onRow={(record) => ({
          onClick: () => onRowClicked(record),
        })}
      />
      <ConfirmationPrompt
        onClosed={onConfirmationClose}
        show={Boolean(context)}>
        {context === ActionType.approve && (
          <p>Are you sure you want to approve?</p>
        )}
        {context === ActionType.reject && (
          <p>Are you sure you want to reject?</p>
        )}
      </ConfirmationPrompt>
    </>
  );
});
