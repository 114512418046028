import Check from '../../../../assets/CheckCircleOutlined.svg';

import './BenefitsSectionStyles.scss';

const BENEFIT_CONTENT = [
    {
        title: 'Robust',
        description: 'The RSB Registry is built on strong sustainability principles developed through genuine multi-stakeholder engagement processes, ensuring a solid foundation for all users.'
    },
    {
        title: 'Credible',
        description: 'Founded on RSB’s certification infrastructure, the registry delivers certified sustainability claims that undergo regular independent audits, instilling trust and confidence in users.'
    },
    {
        title: 'Proven',
        description: 'With over two years of testing as a prototype and successful pilots in collaboration with industry leaders, the RSB Registry has demonstrated its effectiveness and reliability in real-world scenarios.'
    },
    {
        title: 'Scalable',
        description: 'Currently encompassing various sustainable aviation fuel (SAF) use cases, the RSB Registry is committed to expanding its offerings to support additional applications in both aviation and shipping sectors.'
    }
];

type BenefitCardProps = {
    title: string;
    description: string;
};

const BenefitCard: React.FC<BenefitCardProps> = ({ title, description }) => (
    <div className='benefit-card'>
        <img src={Check} alt='Check'></img>
        <h6 className='benefit-card-title'>{title}</h6>
        <p className='benefit-card-desc'>{description}</p>
    </div>
);

export const BenefitsSection = () => {
  return (
    <div className='benefits-section'>
        <h3 className='benefits-title'>
            Key Benefits of the RSB Book & Claim Registry
        </h3>

        <h5 className='benefits-description'>
            Explore the key advantages that make the RSB Book & Claim Registry a vital tool for businesses seeking to enhance their sustainability efforts.
        </h5>

        <div className='benefits-card-wrap'>
            {BENEFIT_CONTENT.map(item => (
                <BenefitCard {...item} />
            ))}
        </div>
    </div>
  );
}
