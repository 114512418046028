import { FC } from 'react';
import { config } from '../../../config';
import FooterLogo from '../../../assets/FooterLogo.svg';
import FooterSign from '../../../assets/FooterSign.svg';

import './Footer.scss';

export const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className='footer-main'>
        <div className='footer-column first-column'>
          <div className='footer-logo-wrap'>
            <img src={FooterLogo} alt='FooterLogo' />
            <h3 className='footer-logo-title'>RSB</h3>
            <img className='footer-logo-img' src={FooterSign} alt='FooterLogo' />
          </div>
          <div className='footer-additional-wrap'>
            <p className='footer-additional-first'>Roundable on</p>
            <p className='footer-additional-second'>Sustainable Biomaterials</p>
          </div>
          <p className='footer-additional-third'>
            <a href='https://www.rsb.org' target="_blank" rel="noopener noreferrer">
              www.rsb.org
            </a>
          </p>
        </div>
        <div className='footer-column'>
          <h5 className='footer-nav-title'>RESOURCES</h5>
          <div className='footer-nav-list'>
            <h5 className='footer-nav-item'>
              <a href='/resources/docs' target="_blank" rel="noopener noreferrer">
                Documentation
              </a>
            </h5>
            <h5 className='footer-nav-item'>
              <a href='/resources/faq/general' target="_blank" rel="noopener noreferrer">
                FAQs
              </a>    
            </h5>
            <h5 className='footer-nav-item'>
              <a href='/resources/pricing' target="_blank" rel="noopener noreferrer">
                Pricing
              </a>
            </h5>
          </div>
        </div>
        <div className='footer-column'>
          <h5 className='footer-nav-title'>ABOUT</h5>
          <div className='footer-nav-list'>
            <h5 className='footer-nav-item'>
              <a href="https://rsb.org/wp-content/uploads/2023/10/RSB-BC-Registry-Terms-and-Conditions.pdf" target="_blank" rel="noopener noreferrer">
                Terms & Conditions
              </a>
            </h5>
            <h5 className='footer-nav-item'>
              <a href='https://rsb.org/privacy-policy/' target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </h5>
            <h5 className='footer-nav-item'>
              <a href='/contact-us' target="_blank" rel="noopener noreferrer">
                Contact Us
              </a>
            </h5>
          </div>
        </div>
      </div>
      <p className='footer-desc'>
        ©2024 | RSB - Roundtable on Sustainable Biomaterials Association | All
        Rights reserved | Version {config.APP_VERSION || '0.0.0'}
      </p>
    </footer>
  );
};
