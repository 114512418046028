import { FC, useMemo } from "react";
import "./blendStyles.scss";
import SummaryField from "../../SummaryField";
import { useAppSelector } from "../../../../../../store/hooks";



interface Props {
    title: string;
  }
  
  const BlendSum: FC<Props> = ({ title }) => {
    const formValues = useAppSelector((state) => state.form);
    const {
      endProduct,
      radioButton,
      blendFieldName,
      blendFieldStreet1,
      blendFieldStreet2,
      blendFieldPost,
      blendFieldCity,
      blendFieldCountry,
      fieldPoS,
      supPosIssueDate,
      finalBlendFieldName,
      finalBlendFieldStreet1,
      finalBlendFieldStreet2,
      finalBlendFieldPost,
      finalBlendFieldCity,
      finalBlendFieldCountry
    } = formValues.fields;

    const isAcquiredSelected = useMemo(() => radioButton === "Supplier", [radioButton]);

    return (
      <div className="form-sum1"> 
        <h2 className="form-title">{title}</h2>
        <SummaryField
          nameProp="End product" 
          selected={endProduct}
          border
        />
        <SummaryField
          nameProp="Product source" 
          selected={isAcquiredSelected ? "Acquired" : "Produced"}
          border
        />
        {isAcquiredSelected && (
          <>
            <SummaryField
              nameProp="PoS provided by supplier ID number" 
              selected={fieldPoS}
              border
            />
            <SummaryField
              nameProp="PoS provided by supplier issue date" 
              selected={supPosIssueDate}
              border
            />
            <SummaryField
              nameProp="PoS provided by supplier pdf upload" 
              selected={formValues.fileDataTwoStep || ""}
              border
            />
          </>
        )}
        <SummaryField
          nameProp="Name" 
          selected={blendFieldName || finalBlendFieldName}
          border
        />
        <SummaryField
          nameProp="Street 1" 
          selected={blendFieldStreet1 || finalBlendFieldStreet1}
          border
        />
        <SummaryField
          nameProp="Street 2" 
          selected={blendFieldStreet2 || finalBlendFieldStreet2}
          border
        />
        <SummaryField
          nameProp="Post code" 
          selected={blendFieldPost || finalBlendFieldPost}
          border
        />
        <SummaryField
          nameProp="City" 
          selected={blendFieldCity || finalBlendFieldCity}
          border
        />
        <SummaryField
          nameProp="Country" 
          selected={blendFieldCountry || finalBlendFieldCountry}
        />
      </div>
    );
  };

export default BlendSum;