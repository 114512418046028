import { UserRole, CompanyType, UserType, UserStatus } from '@prisma/client';

import {
  ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES,
  ADMIN_ACCOUNTS_AND_BALANCE_ROUTES,
  ADMIN_BCU_DETAILS_ROUTES,
  ADMIN_BCUS,
  ADMIN_LOGS,
  ADMIN_REFERENCE_VALUES,
  ADMIN_REFERENCE_VALUES_ROUTES,
  ADMIN_SUBMISSIONS_LIST,
  ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES,
  ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES,
  ADMIN_USERS,
  ADMIN_USERS_DETAILS_ROUTES,
  BCU_DETAILS_ROUTES,
  CHANGE_PASSWORD,
  CONTACT_US,
  DASHBOARD,
  DASHBOARD_ROUTES,
  FORGOT_PASSWORD,
  INFORMATION,
  RESOURCES,
  RESOURCES_ROUTES,
  LOGIN,
  PROFILE_ROUTES,
  REGISTRATION,
  RESET_PASSWORD,
  RETIREMENT_ROUTES,
  SET_PASSWORD,
  STATEMENTS,
  TRANSFER_REQUEST,
  TRANSFER_ROUTES,
} from '../../../../structure/routes/routes';
import { GuardProps } from '../../HOC/guard';
import { isInTypes } from '../../../../helpers/types';

const createRolesAccess = (
  roles: Array<UserRole | undefined>,
  userTypes: Array<UserType | undefined> = [],
  redirect: string = STATEMENTS,
): GuardProps => ({
  canActivate: user => {
    if (userTypes.length === 0) {
      return roles.includes(user?.role);
    }
    return roles.includes(user?.role) && userTypes.includes(user?.type);
  },
  redirect,
});

const createCompanyTypesAccess = (
  types: Array<CompanyType | undefined>,
  userTypes: Array<UserType | undefined> = [],
  redirect: string = STATEMENTS,
): GuardProps => ({
  canActivate: user => {
    return (
      types.includes(user?.company?.type) && userTypes.includes(user?.type)
    );
  },
  redirect,
});

const createStatusAccess = (
  statuses: Array<UserStatus | undefined>,
  redirect: string = STATEMENTS,
): GuardProps => ({
  canActivate: user => statuses.includes(user?.status),
  redirect,
});

const feesAccess: GuardProps = {
  canActivate: user => {
    if (user) {
      return (
        isInTypes(user.role, [UserRole.SystemUser, UserRole.Auditor]) &&
        user?.type === UserType.Primary
      );
    }
    return false;
  },
  redirect: STATEMENTS,
};

const userRolesAccess = createRolesAccess([
  UserRole.SystemUser,
  UserRole.Auditor,
]);

const supplierTypeAccess: GuardProps = {
  canActivate: user =>
    user?.company?.type === CompanyType.Supplier &&
    user?.type === UserType.Secondary,
  redirect: STATEMENTS,
};

const adminRolesAccess = createRolesAccess([
  UserRole.Admin,
  UserRole.SuperAdmin,
  UserRole.Auditor,
]);
const onlyAdminRolesAccess = createRolesAccess([
  UserRole.Admin,
  UserRole.SuperAdmin,
]);
const authorizedRolesAccess = createRolesAccess([
  UserRole.Admin,
  UserRole.SuperAdmin,
  UserRole.SystemUser,
  UserRole.Auditor,
]);

const allRolesAccess = createRolesAccess([
  undefined,
  UserRole.Admin,
  UserRole.SuperAdmin,
  UserRole.SystemUser,
  UserRole.Auditor,
]);

const allRolesExceptAdmin = createRolesAccess([
  UserRole.SystemUser,
  UserRole.Auditor,
  undefined,
]);

const unauthorizedAccess = createRolesAccess([undefined]);

const allStatusesExceptSuspended = createStatusAccess([
  UserStatus.Active,
  UserStatus.Closed,
  UserStatus.Inactive,
  UserStatus.Pending,
  UserStatus.Removed,
]);

const transferAccess = createCompanyTypesAccess(
  [
    CompanyType.Airline,
    CompanyType.Corporate,
    CompanyType.Forwarder,
    CompanyType.Supplier,
    CompanyType.Intermediary,
    CompanyType.Facilitator,
  ],
  [UserType.Secondary],
);
const transferScope1Access = createCompanyTypesAccess(
  [CompanyType.Airline, CompanyType.Facilitator],
  [UserType.Secondary],
);
const retirementAccess = createCompanyTypesAccess(
  [
    CompanyType.Airline,
    CompanyType.Corporate,
    CompanyType.Forwarder,
    CompanyType.Facilitator,
  ],
  [UserType.Secondary],
);

export const pageAccesses: Record<string, GuardProps> = {
  [DASHBOARD]: userRolesAccess,

  // Profile contact list
  [DASHBOARD_ROUTES.CONTACT_LIST]: userRolesAccess,

  // BCU registration
  [DASHBOARD_ROUTES.REGISTER]: supplierTypeAccess,

  // BCU transfer
  [DASHBOARD_ROUTES.TRANSFER]: transferAccess,
  [TRANSFER_ROUTES.REQUESTS]: transferAccess,
  [TRANSFER_ROUTES.INFO_REQUESTS]: transferScope1Access,
  [TRANSFER_ROUTES.INFO_REQUEST]: transferScope1Access,
  [TRANSFER_ROUTES.INCOMING_TRANSACTIONS]: transferAccess,
  [TRANSFER_ROUTES.INCOMING_TRANSACTION]: transferAccess,
  [TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_1]: transferAccess,
  [TRANSFER_ROUTES.INCOMING_TRANSACTION_SCOPE_3]: transferAccess,
  [TRANSFER_ROUTES.CONTACT_LIST]: transferAccess,
  [TRANSFER_REQUEST]: userRolesAccess,
  [BCU_DETAILS_ROUTES.BCU_DETAILS]: userRolesAccess,
  [BCU_DETAILS_ROUTES.BCU_VALUES]: userRolesAccess,
  [BCU_DETAILS_ROUTES.BCU_INFO]: userRolesAccess,
  [BCU_DETAILS_ROUTES.SCOPE1]: userRolesAccess,
  [BCU_DETAILS_ROUTES.SCOPE3]: userRolesAccess,
  [BCU_DETAILS_ROUTES.FACILITATOR]: userRolesAccess,
  [BCU_DETAILS_ROUTES.BCU_EVENTS]: userRolesAccess,

  // BCU retirement
  [DASHBOARD_ROUTES.RETIREMENT]: retirementAccess,
  [RETIREMENT_ROUTES.RETIREMENT]: retirementAccess,
  [RETIREMENT_ROUTES.CONTACT_LIST]: retirementAccess,

  // User profile
  [PROFILE_ROUTES.USER_PROFILE]: userRolesAccess,
  [PROFILE_ROUTES.COMPANY_PROFILE]: userRolesAccess,
  [PROFILE_ROUTES.PROFILE_INFO]: userRolesAccess,
  [PROFILE_ROUTES.USER_INFO]: userRolesAccess,
  [PROFILE_ROUTES.ACCOUNTS_FEES]: feesAccess,

  // Admin routes
  [ADMIN_BCUS]: adminRolesAccess,
  [ADMIN_LOGS]: adminRolesAccess,
  [ADMIN_USERS]: adminRolesAccess,
  [ADMIN_USERS_DETAILS_ROUTES.ADMIN_USERS_DETAILS]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.MAIN]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_ROUTES.DETAILS]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ADMIN_ACCOUNTS_AND_BALANCE_DETAILS]:
    adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_USER_INFO]:
    adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_HISTORY]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_BALANCE]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_FEE_INFORMATION]:
    adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_NOTES]: adminRolesAccess,
  [ADMIN_ACCOUNTS_AND_BALANCE_DETAILS_ROUTES.ACCOUNT_CONTACTS]:
    adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_DETAILS]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_INFO]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE1]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_SCOPE3]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_HISTORY]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_NOTE]: adminRolesAccess,
  [ADMIN_BCU_DETAILS_ROUTES.ADMIN_BCU_FACILITATOR]: adminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST]: onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.COMPANY_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USERS_INFO]: onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.USER_PROFILE]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_COMPANY_INFO_ROUTES.NOTE]: onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.PROFILE_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_PROFILE_UPDATE_INFO_ROUTES.NOTE]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.COMPANY_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.BCU_VALUES]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.USERS_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE1_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.SCOPE3_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.ADMIN_BCU_HISTORY]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_CREDIT_INFO_ROUTES.NOTE]: onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.COMPANY_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.TRANSFER_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE1_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.SCOPE3_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.BCU_VALUES]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.USERS_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.ADMIN_BCU_HISTORY]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_TRANSFER_INFO_ROUTES.NOTE]: onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.COMPANY_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.RETIREMENT_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE1_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.SCOPE3_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_VALUES]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.BCU_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.USERS_INFO]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.ADMIN_BCU_HISTORY]:
    onlyAdminRolesAccess,
  [ADMIN_SUBMISSIONS_LIST_BCU_RETIREMENT_INFO_ROUTES.NOTE]:
    onlyAdminRolesAccess,
  [ADMIN_REFERENCE_VALUES]: adminRolesAccess,
  [ADMIN_REFERENCE_VALUES_ROUTES.FEEDSTOCK_CERTIFICATION]: adminRolesAccess,
  [ADMIN_REFERENCE_VALUES_ROUTES.INCENTIVES]: adminRolesAccess,

  // General routes
  [CHANGE_PASSWORD]: allStatusesExceptSuspended,
  [RESET_PASSWORD]: unauthorizedAccess,
  [SET_PASSWORD]: unauthorizedAccess,
  [REGISTRATION]: unauthorizedAccess,
  [FORGOT_PASSWORD]: unauthorizedAccess,
  [LOGIN]: unauthorizedAccess,
  [STATEMENTS]: allRolesAccess,
  [INFORMATION]: allRolesAccess,
  [RESOURCES]: allRolesAccess,
  [RESOURCES_ROUTES.DOCS]: allRolesAccess,
  [RESOURCES_ROUTES.FAQ]: allRolesAccess,
  [RESOURCES_ROUTES.HISTORY]: allRolesAccess,
  [RESOURCES_ROUTES.PRICING]: allRolesAccess,
  [CONTACT_US]: allRolesExceptAdmin,
};
