import { FC } from 'react';
import './genStyles.scss';
import SummaryField from '../../SummaryField';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectBaseLines } from '../../../../../../store/reducers/user/abatementBaselinesReducer';
import { separateWithSpaces } from '../helpers';

interface Props {
  title: string;
}

const GenField: FC<Props> = ({ title }) => {
  const formValues = useAppSelector(state => state.form);
  const baselines = useAppSelector(selectBaseLines) || [];
  const {
    sustainabilityCertification,
    endProductType,
    sustainabilityCertificationNumber,
    fieldCertif,
    conversionProcess,
    rawMaterialFeedstock,
    fieldBatchCountry,
    lowerHeatingValue,
    fieldPoSNumber,
    posIssueDate,
    feedstockCertification
  } = formValues.fields;

  const selectedBaseline = baselines.find(
    ({ id }) => id === sustainabilityCertification,
  );

  return (
    <div className="form-sum">
      <h2 className="form-title">{title}</h2>
      <SummaryField
        nameProp="End product type"
        selected={endProductType}
        border
      />
      <SummaryField
        nameProp="Sustainability certification"
        selected={selectedBaseline ? selectedBaseline.name : ''}
        border
      />
      <SummaryField
        nameProp="Sustainability certification number"
        selected={sustainabilityCertificationNumber}
        border
      />
      <SummaryField
        nameProp="Certification body"
        selected={fieldCertif}
        border
      />
      <SummaryField
        nameProp="Feedstock certification"
        selected={feedstockCertification}
        border
      />
      <SummaryField
        nameProp="Feedstock conversion process"
        selected={conversionProcess}
        border
      />
      <SummaryField
        nameProp="Raw material (feedstock)"
        selected={separateWithSpaces(rawMaterialFeedstock)}
        border
      />
      <SummaryField
        nameProp="Country of feedstock origin"
        selected={fieldBatchCountry}
        border
      />
      <SummaryField
        nameProp="Lower heating value"
        selected={lowerHeatingValue}
        border
      />
      <SummaryField
        nameProp="PoS number of end product"
        selected={fieldPoSNumber}
        border
      />
      <SummaryField
        nameProp="PoS of end product issue date"
        selected={posIssueDate}
        border
      />
      <SummaryField
        nameProp="PoS pdf upload of end product"
        selected={formValues.fileData || ''}
      />
    </div>
  );
};

export default GenField;
