import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UserRole } from '@prisma/client';
import { Button, Form, Input, Modal } from 'antd';

import { Table } from '../../../../components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectIncentives } from '../../../../../store/reducers/user/abatementBaselinesReducer';
import {
  fetchIncentives,
  fetchIncentivesCreate,
  fetchIncentivesDelete,
  fetchIncentivesEdit,
} from '../../../../../API/credits';
import { ConfirmationPrompt } from '../../../../components/ConfirmationPrompt';
import { selectUser } from '../../../../../store/reducers/user/userReducer';
import { useNotification } from '../../../../../hooks/useNotification';
import { Role } from '../../../../../structure/models/user/user';

import { getFeedstockCertsColumns } from './models/feedstock-certs.columns';
import { MultiValue } from 'react-select';

export const IncentivesSchemes: React.FC = () => {
  const toast = useNotification();
  const dispatch = useAppDispatch();
  const incentives = useAppSelector(selectIncentives);
  const user = useAppSelector(selectUser);

  const isAuditor = useMemo(
    () => (user && user.role === Role.Auditor) || false,
    [user, Role],
  );

  const [localData, setLocalData] = useState<any[]>([]);
  const [editedRowId, setEditedRowId] = useState<number | null>(null);
  const [deletedRowId, setDeletedRowId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onEditClick = (id: number) => setEditedRowId(id);

  const onDelete = (id: number) => {
    setDeletedRowId(id);
  };

  const onEditChange = (
    data: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => {
    const { name, value } = data;
    setLocalData(
      localData.map(item =>
        item.id === id ? { ...item, [name]: value.toString() } : item,
      ),
    );
  };

  const onConfirmationClosed = (state: boolean) => {
    if (!state) {
      return setDeletedRowId(null);
    }

    return dispatch(fetchIncentivesDelete(deletedRowId!))
      .then(() => {
        toast.success({
          message: 'Success',
          description: 'Reference values has been updated',
        });
      })
      .catch((e: any) => {
        toast.error({
          message: 'Error',
          description: e.message,
        });
      })
      .finally(() => {
        setDeletedRowId(null);
        handleCloseModal();
      });
  };

  const onEditSave = () => {
    if (!localData.length) {
      setEditedRowId(null);
      return;
    }
    return dispatch(
        fetchIncentivesEdit(
        editedRowId!,
        localData.find(({ id }) => id === editedRowId),
      ),
    )
      .then(() => {
        toast.success({
          message: 'Success',
          description: 'Reference values has been updated',
        });
      })
      .catch((e: any) => {
        toast.error({
          message: 'Error',
          description: e.message,
        });
      })
      .finally(() => {
        setEditedRowId(null);
      });
  };

  useEffect(() => {
    dispatch(fetchIncentives());
  }, []);

  useEffect(() => {
    setLocalData(incentives);
  }, [incentives]);

  const columns = useMemo(
    () =>
      getFeedstockCertsColumns(onDelete, onEditChange, isAuditor).filter(
        col => !col.hidden,
      ),
    [getFeedstockCertsColumns, onDelete, isAuditor, form],
  );

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCreate = useCallback((values: { name: string }) => {
    return dispatch(
        fetchIncentivesCreate({
        ...values,
      }),
    )
      .then(() => {
        toast.success({
          message: 'Success',
          description: 'Reference Values has been created',
        });
      })
      .catch((e: any) => {
        toast.error({
          message: 'Error',
          description: e.response.data.message,
        });
      })
      .finally(() => {
        handleCloseModal();
      });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [isModalOpen]);

  return (
    <div className="feedstock-cert">
      <ConfirmationPrompt
        onClosed={onConfirmationClosed}
        show={deletedRowId !== null}>
        <p>Are you sure you want to delete this Incentive?</p>
      </ConfirmationPrompt>
      {isAuditor || (
        <Button
          className="certification-action"
          type="primary"
          onClick={handleOpenModal}>
          <span>Create Incentive</span>
        </Button>
      )}
      <Table
        isDefaultTableEdit
        showHeader
        editedRow={editedRowId as any}
        columns={columns}
        data={localData}
        onEditClick={onEditClick}
        isEdit={!isAuditor}
        onEditSave={onEditSave}
      />
      <Modal
        title="Create Incentive"
        open={isModalOpen}
        footer={false}
        onCancel={handleCloseModal}>
        <Form form={form} onFinish={handleCreate}>
          <div>
            <Form.Item label="Incentive" name="name" rules={[{ required: true }]}>
              <Input placeholder="Incentive Name" type="text" />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
