import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import userReducer, { reset } from './reducers/user/userReducer';
import adminSubmissionsReducer from './reducers/admin/submissionsReducer';
import { ThunkDispatch } from 'redux-thunk/src/types';
import abatementBaselinesReducer from './reducers/user/abatementBaselinesReducer';
import retirementStatementsReducer from './reducers/user/retirementStatementsReducer';
import creditsReducer from './reducers/user/creditsReducer';
import usersReducer from './reducers/admin/usersReducer';
import transactionsReducer from './reducers/user/transactionsReducer';
import logsReducer from './reducers/admin/logsReducer';
import accountsReducer from './reducers/admin/accountsReducer';
import bcuListReducer from './reducers/admin/bcuListReducer';
import bcuFormReducer from './reducers/user/bcuFormReducer'
import adminDashboardReducer from './reducers/admin/adminReducer';
import rowReducer from './reducers/user/rowReducer';
import {api} from "../API";
import {setupListeners} from "@reduxjs/toolkit/query";
import optionsReducer from './reducers/user/optionsReducer';
import bcuLogsReducer from './reducers/user/bcuLogsReducer';
import informationReducer from './reducers/user/informationReducer';

const appReducer = combineReducers({
  row: rowReducer,
  user: userReducer,
  form: bcuFormReducer,
  userTransactions: transactionsReducer,
  adminSubmissions: adminSubmissionsReducer,
  adminLogs: logsReducer,
  adminBCUsList: bcuListReducer,
  abatementBaselines: abatementBaselinesReducer,
  retirementStatements: retirementStatementsReducer,
  credits: creditsReducer,
  users: usersReducer,
  accounts: accountsReducer,
  adminDashboard: adminDashboardReducer,
  options: optionsReducer,
  userLogs: bcuLogsReducer,
  information: informationReducer, 
  [api.reducerPath]: api.reducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === reset.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;
