import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './store/hooks';
import {
  selectToken,
  selectUser,
  changeToken,
  logout,
} from './store/reducers/user/userReducer';
import { refreshToken } from './API/login';
import { fetchUserData } from './API/user';
import { BigLoader } from './app/components/BigLoader/BigLoader';
import { useSSE } from './hooks/useSSE';
import { TableStructure, updateTableData } from './helpers/updateTableData';
import { UserRole } from '@prisma/client';
import { ConfigProvider, App as AntdApp } from 'antd';
import { Outlet } from 'react-router-dom';
import { isInTypes } from './helpers/types';
import { useRollbarPerson } from '@rollbar/react';
import { BalanceContextProvider } from './contexts/balance.context';

function App() {
  const eventsSubject = useSSE();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useRollbarPerson(user ? { id: user.id, companyId: user.companyId, email: user.email, SUID: user.SUID }: {});

  useEffect(() => {
    if (!token) {
      const storedToken = localStorage.getItem('accessToken');

      if (storedToken) {
        dispatch(changeToken(storedToken));
      } else {
        setFirstLoad(false);
      }
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchUserData()).finally(() => {
        setFirstLoad(false);
      });
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (!user) {
      dispatch(refreshToken())
        .catch(() => {
          dispatch(logout());
        })
        .finally(() => {
          setFirstLoad(false);
        });
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!eventsSubject) {
      return;
    }

    const subscription = eventsSubject.subscribe(({ id, data }) => {
      if (id === TableStructure.RetirementStatement) {
        updateTableData({ id, data }, dispatch, undefined);

        return;
      }

      if (!user) {
        return;
      }

      if (isInTypes(id, [TableStructure.Balance, TableStructure.Counters])) {
        updateTableData({ id, data }, dispatch, user);

        return;
      }

      if (!isInTypes(user.role, [UserRole.Admin, UserRole.SuperAdmin])) {
        return;
      }

      updateTableData({ id, data }, dispatch, user);
    });

    return () => {
      subscription.unsubscribe();
    }
  }, [eventsSubject, user, dispatch]);

  if (firstLoad) {
    return <BigLoader />;
  }

  return (
    <div id="App">
        <ConfigProvider
            theme={{
              token: {
                fontFamily: '"Indivisible", sans-serif',
                colorPrimary: '#07ACA6',
                borderRadius: 8,
                colorBorder: '#9F9F9F',
                colorError: '#D64751',
                colorBgContainerDisabled: '#E6E6E6',
                colorTextDisabled: '#9F9F9F',
                colorText: '#455A64',
                green: 'rgba(10, 175, 96, 0.1)',
                red: 'rgba(255, 61, 0, 0.1)',
              },
              components: {
                Button: {
                  primaryColor: '#fff',
                  primaryShadow: 'none',
                  boxShadow: 'none',
                  contentFontSize: 12,
                  contentFontSizeSM: 10,
                  contentFontSizeLG: 14,
                  fontWeight: 500,
                },
                Table: {
                  headerBorderRadius: 0,
                  headerSortActiveBg: '#fff',
                  headerSortHoverBg: '#fff',
                  headerBg: '#fff',
                  headerSplitColor: '#fff',
                  fontWeightStrong: 500,
                  headerColor: '#5C6D76',
                  rowHoverBg: '#F3F5F7',
                },
                Tag: {
                  defaultColor: 'rgba(92, 109, 118, 0.5)',
                  defaultBg: 'rgba(246, 247, 248, 1)',
                },
              }
        }}>
        <AntdApp notification={{ placement: 'topRight', stack: true, maxCount: 3 }}>
          <BalanceContextProvider>
            <Outlet />
          </BalanceContextProvider>
        </AntdApp>
      </ConfigProvider>
    </div>
  );
}

export default App;
