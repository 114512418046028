import { ADMIN_REFERENCE_VALUES_ROUTES } from '../../../../../structure/routes/routes';

export const TABS = [
  {
    title: 'Certification Schemes',
    path: ADMIN_REFERENCE_VALUES_ROUTES.CERTIFICATION_SCHEMES,
  },
  {
    title: 'Feedstock Certification',
    path: ADMIN_REFERENCE_VALUES_ROUTES.FEEDSTOCK_CERTIFICATION,
  },
  {
    title: 'Incentives',
    path: ADMIN_REFERENCE_VALUES_ROUTES.INCENTIVES,
  },
];
