import React from 'react';
import { BaseButton } from '../Buttons/BaseButton/BaseButton';
import './ContactUsStyle.scss';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US } from '../../../structure/routes/routes';

export const ContactUs: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(CONTACT_US);
  };

  return (
    <div className="contact-us" onClick={onClick}>
      Contact us
    </div>
  );
});
