import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
  formatISO,
  compareAsc,
  setHours,
  setMinutes,
  isEqual,
  add,
} from 'date-fns';
import classNames from 'classnames';
import { DateField } from './DateField';
import { TimeField } from './TimeField';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { extractTime, formatDateTime } from '../../../../helpers/datePickerHelper';
import { Select } from '../../Form/Select/Select';

import './DateSelectStyle.scss';

export type DateQueryTypes = { startDate: string; endDate: string };

enum ExpireRange {
  ThreeMonths = '3_months',
  SixMonths = '6_months',
  OneYear = '1_year',
}

const calculateExpireRange = (value: ExpireRange | null): Date | null => {
  const today = new Date();

  switch (value) {
    case ExpireRange.ThreeMonths: {
      const dateIn3Months = new Date(today.setMonth(today.getMonth() + 3));
      return dateIn3Months;
    }
    case ExpireRange.SixMonths: {
      const dateIn6Months = new Date(today.setMonth(today.getMonth() + 6));
      return dateIn6Months;
    }
    case ExpireRange.OneYear: {
      const dateIn1Year = new Date(today.setFullYear(today.getFullYear() + 1));
      return dateIn1Year;
    }
    default:
      return null;
  }
};

interface DateSelectProps {
  initialStartDate: Date | null,
  initialEndDate: Date | null,
  isForDate?: boolean,
  dateRangeProps?: any,
  inputName?: string,
  onDateChange: (value: DateQueryTypes) => void;
}

export const DateSelect: FC<DateSelectProps> = ({ 
  initialStartDate,
  initialEndDate,
  isForDate = false,
  inputName = 'Date and Time',
  dateRangeProps,
  onDateChange 
}) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [isFilled, setFilled] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(formatDateTime(initialStartDate) || null);
  const [isStartCalendarOpen, setStartCalendarOpen] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(formatDateTime(initialEndDate) || null);
  const [isEndCalendarOpen, setEndCalendarOpen] = useState(false);
  const [startTime, setStartTime] = useState(extractTime(initialStartDate, isForDate) || '');
  const [endTime, setEndTime] = useState(extractTime(initialEndDate, isForDate) || '');
  const [isError, setError] = useState(false);
  const [expireRange, setExpireRange] = useState(null);

  const onStartCalendarToggle = () => {
    setStartCalendarOpen(true);
    setEndCalendarOpen(false);
  };

  const onSetStartDate = (date: Date) => {
    setStartDate(date);
    setStartCalendarOpen(false);
    setExpireRange(null);
    setEndCalendarOpen(true);
  };

  const onEndCalendarToggle = () => {
    setStartCalendarOpen(false);
    setEndCalendarOpen(true);
  };

  const onSetEndDate = (date: Date) => {
    setEndDate(date);
    setStartCalendarOpen(false);
    setExpireRange(null);
    setEndCalendarOpen(false);
  };

  const onStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  };

  const onEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
  };

  const onFilterReset = () => {
    setStartDate(null);
    setEndDate(null);
    setStartTime('');
    setEndTime('');
    setFilled(false);
    setOpen(false);
    setExpireRange(null);

    onDateChange({
      startDate: '',
      endDate: '',
    });
  };

  const setHoursAndMinutes = (time: string, date = new Date()) => {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    const formattedStartHours = setHours(date, parseInt(hours));

    return setMinutes(formattedStartHours, parseInt(minutes));
  };

  const onTimeValidate = () => {
    const startHoursAndMinutes = setHoursAndMinutes(startTime);
    const endHoursAndMinutes = setHoursAndMinutes(endTime);

    return Boolean(compareAsc(startHoursAndMinutes, endHoursAndMinutes) > 0);
  };

  const onFilterApply = () => {
    const isTheSameDay = isEqual(startDate as Date, endDate as Date);
    const isTimeError = isTheSameDay ? onTimeValidate() : false;

    if (isTimeError) {
      setError(true);
      return;
    }

    if (Boolean(startDate && endDate)) {
      const formattedEndDay = !Boolean(startTime || endTime)
        ? add(endDate!, { days: 1 })
        : endDate!;
      const formattedStartDate = formatISO(
        startTime ? setHoursAndMinutes(startTime, startDate!) : startDate!,
      );
      const formattedEndDate = formatISO(
        endTime
          ? setHoursAndMinutes(endTime, formattedEndDay)
          : formattedEndDay,
      );
      const query = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      setOpen(false);
      setFilled(true);

      onDateChange(query);
    }
  };

  const onClickOutside = (e: any) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target)) {
      setOpen(false);
      setStartCalendarOpen(false);
      setEndCalendarOpen(false);
    }
  };

  const onChangeDateRange = (options: any) => {
    setExpireRange(options?.value);
    const selectedRange = options?.value;
    const endDate = calculateExpireRange(selectedRange) as any;
    const today = new Date();

    if (endDate) {
      setStartDate(today);
      setEndDate(endDate);
    };
  }

  useEffect(() => {
    if (isError) setError(false);
  }, [startDate, endDate, startTime, endTime]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    if (initialStartDate || initialEndDate) setFilled(true);

    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const isResetDisabled = !Boolean(
    startDate || endDate || startTime || endTime,
  );
  const isApplyDisabled = !Boolean(startDate && endDate) || isError;

  return (
    <div className="date-select" ref={calendarRef}>
      <div
        className={classNames('date-select__field', {
          active: isOpen,
          filled: isFilled,
        })}
        onClick={() => setOpen(!isOpen)}>
        {isFilled && (
          <span
            className={classNames(
              'material-icons-outlined',
              'date-select__filter-icon',
            )}>
            filter_list
          </span>
        )}
        <input readOnly value={inputName} />
        <span className="material-icons-outlined">expand_more</span>
      </div>

      {isOpen && (
        <div 
          className="date-select__panel" 
          style={
            isForDate 
              ? { minHeight: 'auto' } 
              : {}
          }
        >
          <div className="date-select__row">
            <DateField
              isCalendarOpen={isStartCalendarOpen}
              maxDate={endDate}
              date={startDate}
              onClick={onStartCalendarToggle}
              onCalendarToggle={value => setStartCalendarOpen(value)}
              onDateSelect={onSetStartDate}
            />
            <DateField
              isCalendarOpen={isEndCalendarOpen}
              minDate={startDate}
              date={endDate}
              onClick={onEndCalendarToggle}
              onCalendarToggle={value => setEndCalendarOpen(value)}
              onDateSelect={onSetEndDate}
            />
          </div>
          {!isForDate ? 
            <div
              className={classNames('date-select__row', {
                timeRowError: isError,
              })}>
              <TimeField
                time={startTime}
                error={isError}
                onTimeChange={onStartTimeChange}
              />
              <TimeField
                time={endTime}
                error={isError}
                onTimeChange={onEndTimeChange}
              />
            </div>
            : null
          }
          {dateRangeProps && (
            <div>
              <Select
                placeholder={dateRangeProps.placeholder}
                options={dateRangeProps.options}
                isClearable={false}
                value={expireRange}
                onChange={onChangeDateRange}
              />
            </div>
          )}
          {isError && (
            <div className="error">
              The start range time can’t be later than the end range time
            </div>
          )}
          <div className="date-select__actions">
            <span
              className={classNames('date-select__button', {
                disabled: isResetDisabled,
              })}
              onClick={onFilterReset}>
              Reset
            </span>
            <span
              className={classNames('date-select__button', {
                disabled: isApplyDisabled,
              })}
              onClick={onFilterApply}>
              Apply
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
