import React, { useState } from 'react';
import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';
import FaqIconClose from '../../assets/FaqIconOpen.svg';
import FaqIconOpen from '../../assets/FaqIconClose.svg';

import './Resources.scss';

export const RESOURCES_ROUTES = {
  DOCS: {
    path: 'docs',
    title: 'Documentation',
  },
  FAQ: {
    path: 'faq',
    title: 'FAQs',
    subRoutes: [
      { path: 'general', title: 'General Overview' },
      { path: 'account_management', title: 'Account Management' },
      { path: 'bcu', title: 'BCU (Book & Claim Units)' },
      { path: 'carbon', title: 'Carbon Markets & Emissions Reductions' },
      { path: 'technical', title: 'Technical Information' },
    ],
  },
  PRICING: {
    path: 'pricing',
    title: 'Pricing',
  },
  // HISTORY: {
  //   path: 'history',
  //   title: 'Version History',
  // },
};

export const Resources: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [faqDropdownOpen, setFaqDropdownOpen] = useState(location.pathname.includes('faq'));

  const toggleFaqDropdown = () => {
    setFaqDropdownOpen(!faqDropdownOpen);
    if (!faqDropdownOpen) {
      navigate('/resources/faq/general');
    }
  };

  const isActive = (path: string) => location.pathname.includes(path);

  return (
    <div className="resources-wrap">
      <div className="resources">
        <nav className="resources-sidebar">
          {Object.entries(RESOURCES_ROUTES).map(([key, route]) => {
            if (key === 'FAQ' && 'subRoutes' in route) {
              return (
                <div key={key} className="faq-dropdown">
                  <div
                    className={`resources-link ${faqDropdownOpen ? 'active' : ''}`}
                    onClick={toggleFaqDropdown}
                  >
                    {route.title}
                    <span className="faq-toggle-icon">
                      {faqDropdownOpen 
                        ? (<img alt="iconOpen" src={FaqIconOpen} />) 
                        : (<img alt="iconClose" src={FaqIconClose} />)}
                    </span>
                  </div>
                  {faqDropdownOpen && (
                    <div className="faq-subroutes">
                      {route.subRoutes.map((subRoute) => (
                        <Link
                          key={subRoute.path}
                          to={`${route.path}/${subRoute.path}`}
                          className={`resources-link ${
                            isActive(`${route.path}/${subRoute.path}`) ? 'active' : ''
                          }`}
                        >
                          {subRoute.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              );
            }
            return (
              <Link
                key={key}
                to={route.path}
                className={`resources-link ${isActive(route.path) ? 'active' : ''}`}
                onClick={() => setFaqDropdownOpen(false)}
              >
                {route.title}
              </Link>
            );
          })}
        </nav>

        <div className="resources-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
