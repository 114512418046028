import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../store/hooks';

import './UserInfoStyle.scss';
import NextButton from '../../components/Buttons/NextButton/NextButton';
import { Column, Table } from '../../components/Table/Table';
import {
  useGetCompanyUsersListQuery,
  useInviteCompanyUserMutation,
  useUpdateCompanyUserMutation,
} from '../../../API';
import { User, UserType, UserStatus } from '@prisma/client';
import { ConfirmationPrompt } from '../../components/ConfirmationPrompt';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { useNotification } from '../../../hooks/useNotification';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Flex, Input, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Status, { IStatusProps } from '../../components/StatusComponent';

const { Text } = Typography;

enum UserActionType {
  Cancel,
  Remove,
}

const UserActions: React.FC<{
  item: User;
  onButtonClicked: (item: User, action: UserActionType) => Promise<void>;
}> = ({ item, onButtonClicked }) => {
  switch (item.status) {
    case UserStatus.Inactive:
    case UserStatus.Suspended:
    case UserStatus.Removed:
      return null;
    case UserStatus.Pending:
      if (item.isEmailConfirmed || item.isAdminApproved) {
        return null; // Can't cancel confirmed user
      }
      return (
        <button
          onClick={() => onButtonClicked(item, UserActionType.Cancel)}
          style={{
            padding: 0,
            fontSize: '1em',
            color: '#EFC900',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
          }}>
          Cancel invite
        </button>
      );
    default:
      return (
        <button
          onClick={() => onButtonClicked(item, UserActionType.Remove)}
          style={{
            padding: 0,
            fontSize: '1em',
            color: '#07ACA6',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
          }}>
          Remove user
        </button>
      );
  }
};

enum ConfirmationContext {
  Hidden,
  Form,
  RetireOrCancel,
}

export const UserInfo: React.FC = () => {
  const toast = useNotification();
  const { data: users } = useGetCompanyUsersListQuery();
  const user = useAppSelector(selectUser);
  const [context, setContext] = useState(ConfirmationContext.Hidden);
  const [updateUser] = useUpdateCompanyUserMutation();
  const [inviteUser] = useInviteCompanyUserMutation();
  const [selectedUser, setSelectedUser] = useState<
    { user: User; action: UserActionType } | undefined
  >();

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      email: '',
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        email: z
          .string({ required_error: 'Email is required' })
          .email({ message: 'Please enter a valid email' }),
      }),
    ),
    onSubmit: async values => {
      if (!values.email) {
        toast.error({
          message: 'Error',
          description: 'Please enter a valid email',
        });
        return;
      }

      setContext(ConfirmationContext.Form);
    },
  });

  const handleUserActions = async (item: User, action: UserActionType) => {
    setSelectedUser({ user: item, action });
    setContext(ConfirmationContext.RetireOrCancel);
  };

  const handleClose = async (state: boolean) => {
    const submitContext = context;
    setContext(ConfirmationContext.Hidden);

    if (!state) {
      return;
    }

    if (submitContext === ConfirmationContext.Form) {
      const response: any = await inviteUser({ email: form.values.email });

      if (response.data && response.data.success) {
        // Clear the field after successful invite
        form.setValues({ email: '' });

        toast.success({
          message: 'Success',
          description: 'User has been invited',
        });
      }

      if (response.error) {
        toast.error({
          message: 'Error',
          description: response.error?.message || 'Unknown error',
        });
      }

      return;
    }

    if (submitContext === ConfirmationContext.RetireOrCancel && selectedUser) {
      const { user: userToRemove, action } = selectedUser;
      let response: any;

      switch (action) {
        case UserActionType.Remove:
          response = await updateUser({
            user: userToRemove?.id,
            action: 'remove',
          });

          if (response.data && response.data.success) {
            toast.success({
              message: 'Success',
              description: 'User has been removed',
            });
          }

          if (response.error) {
            toast.error({
              message: 'Error',
              description: response.error?.message || 'Unknown error',
            });
          }

          return;
        case UserActionType.Cancel:
          response = await updateUser({
            user: userToRemove?.id,
            action: 'cancel',
          });

          if (response.data && response.data.success) {
            toast.success({
              message: 'Success',
              description: 'Invite has been cancelled',
            });
          }

          if (response.error) {
            toast.error({
              message: 'Error',
              description: response.error?.message || 'Unknown error',
            });
          }

          return;
        default:
          return;
      }
    }
  };

  const columns: Column<User>[] = [
    {
      title: 'Role',
      keyItem: 'role',
      render: item => (
        <span>{item.type === UserType.Primary ? 'Account owner' : 'User'}</span>
      ),
    },
    {
      title: 'Name',
      keyItem: 'name',
      render: item => (
        <span>
          {item.firstName} {item.lastName}
        </span>
      ),
    },
    {
      title: 'Email',
      keyItem: 'email',
      render: item => item.email,
    },
    {
      title: 'Phone',
      keyItem: 'phone',
      render: item => (
        <span>
          {(item.cellPhone as any)?.code}
          {(item.cellPhone as any)?.number}
        </span>
      ),
    },
    {
      title: 'Position',
      keyItem: 'position',
      render: item => item.position,
    },
    {
      title: 'Status',
      keyItem: 'status',
      render: (item: { status: IStatusProps['statusOfComponent'] }) => (
        <Status statusOfComponent={item.status} />
      ),
    },
  ];

  if (user && user.type === UserType.Primary) {
    columns.push({
      title: 'Action',
      keyItem: 'action',
      render: item =>
        item.id !== user.id ? (
          <UserActions onButtonClicked={handleUserActions} item={item} />
        ) : (
          ''
        ),
    });
  }

  return (
    <div className="user-info">
      {user && user.type === UserType.Primary && (
        <div className="user-info__field">
          <Flex vertical gap="small" style={{ width: '100%' }}>
            <label htmlFor="field-add-user">
              <Text>
                Email <Text type="danger">*</Text>
              </Text>
            </label>
            <Input
              id="field-add-user"
              size="large"
              value={form.values.email}
              onChange={e => form.setFieldValue('email', e.target.value)}
              status={
                form.values?.email && form.errors?.email ? 'error' : undefined
              }
              autoComplete="off"
              suffix={
                <>
                  {form.values?.email && form.errors?.email && (
                    <Tooltip
                      defaultOpen
                      color="#d64751"
                      title={form.errors?.email}>
                      <InfoCircleOutlined style={{ color: '#d64751' }} />
                    </Tooltip>
                  )}
                </>
              }
            />
          </Flex>
          <NextButton
            text="Add new user"
            handleClick={form.submitForm}
            style={{ margin: '0', padding: '10px 24px', width: '155px' }}
            disabled={
              (users || []).filter(
                (u: any) =>
                  [UserStatus.Active, UserStatus.Pending].indexOf(u.status) >
                  -1,
              ).length >= 10 || user.status === UserStatus.Suspended
            }
          />
        </div>
      )}

      <div className="user-info__table">
        <Table
          columns={columns}
          data={users}
          rowKey={item => item.id}
          showHeader
        />
      </div>

      <ConfirmationPrompt
        onClosed={handleClose}
        show={
          [
            ConfirmationContext.RetireOrCancel,
            ConfirmationContext.Form,
          ].indexOf(context) > -1
        }>
        {context === ConfirmationContext.Form && (
          <p>
            Are you sure you want to add a new user to the account with the
            following email address: <strong>{form.values.email}</strong>?
          </p>
        )}
        {context === ConfirmationContext.RetireOrCancel && (
          <p>Are you sure you want to perform this operation?</p>
        )}
      </ConfirmationPrompt>
    </div>
  );
};
