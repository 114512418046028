import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { selectStatus } from '../../store/reducers/user/userReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchLogin } from '../../API/login';
import './Login.scss';
import { BaseButtonSubmit } from '../components/Buttons/BaseButtonSubmit/BaseButtonSubmit';
import { useFormActions } from '../components/Form/FormHook';
import { getFormLoginData, validate } from './helpers/login';
import {
  LoginOtpResponse,
  LoginTokenResponse,
} from '../../structure/request/login';
import { AxiosResponse } from 'axios';
import { fetchUserData } from '../../API/user';
import {
  DASHBOARD,
  FORGOT_PASSWORD,
} from '../../structure/routes/routes';
import { Input } from '../components/Form/Input';
import { Password } from '../components/Form/Password';

export const Login: React.FC = React.memo(() => {
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [regOtp, setRegOtp] = useState('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [otpVisible, setOtpVisible] = useState<boolean>(false);
  const statusLogin = useAppSelector(selectStatus);
  const loading = statusLogin === 'loading';

  const transformAndDispatchData = (formData: FormData) => {
    return dispatch(fetchLogin(getFormLoginData(formData))).then(
      (resp: AxiosResponse<LoginTokenResponse | LoginOtpResponse>) => {
        if (typeof resp.data.data === 'string') {
          setOtpVisible(true);
        } else {
          return dispatch(fetchUserData()).then(() => navigate(DASHBOARD));
        }
      },
    );
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { onSubmit, showErrorsBlock } = useFormActions({
    validate,
    transformAndDispatchData,
  });

  return (
    <div className="login_screen">
      <div className="login_screen__form">
        <form onSubmit={onSubmit}>
          <h1 className="login_screen__form__header">Log In</h1>
          <Input
            required
            id="email"
            label="E-mail"
            placeholder="E-mail"
            name="email"
            type="email"
            value={regEmail}
            onChange={e => {
              setRegEmail(e.target.value);
            }}
            error={showErrorsBlock['email']}
          />
          <Password
            required
            id="password"
            label="Password"
            placeholder="Password"
            name="password"
            value={regPassword}
            onChange={e => {
              setRegPassword(e.target.value);
            }}
            error={showErrorsBlock['password']}
          />
          {otpVisible && (
            <Input
              required
              id="otp"
              label="Verification code"
              placeholder="Verification code"
              name="otp"
              type="otp"
              value={regOtp}
              onChange={e => {
                setRegOtp(e.target.value);
              }}
              error={showErrorsBlock['otp']}
              description="Code sent to your email"
            />
          )}
          <div className="login_buttons">
            <div className="login_button">
              <BaseButtonSubmit active lock={loading} loading={loading}>
                Log In
              </BaseButtonSubmit>
            </div>
            <div className="forgot-password">
              <Link className="link" to={FORGOT_PASSWORD}>
                Forgot password?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});
