import {
  DATE_WITH_TIME_FORMAT,
  formatDate,
} from '../../../../core/models/contstants/format-date';
import { ErrorBlocks } from '../../../../components/Form/FormHook';
import { Column, EditableColumn } from '../../../../components/Table/Table';
import { Input } from '../../../../components/Form/Input';
import StatusComponent from '../../../../components/StatusComponent';
import { COMPANY_TYPE_NAMES, CompanyType } from '../../../../../structure/models/company/company';
import { Contact } from '../../../../components/ContactList';

export const ACCOUNTS_COLUMNS = (
  showErrorsBlocks: ErrorBlocks,
): EditableColumn<any>[] => [
  {
    title: <span className='active-header'>Company name</span>,
    keyItem: 'name',
    sort: true,
    render: (item: any) => item.name,
  },
  {
    title: 'Company type',
    keyItem: 'type',
    sort: true,
    render: (item: any) => COMPANY_TYPE_NAMES[item.type] || '',
  },
  {
    title: 'Account ID',
    keyItem: 'id',
    sort: true,
    render: (item: any) => item.id,
  },
  {
    title: 'RSB PO number',
    keyItem: 'RSBPONumber',
    sort: true,
    render: (item: any) => item.RSBPONumber,
  },
  {
    title: 'Registration date',
    keyItem: 'createdAt',
    sort: true,
    render: (item: any) =>
      item.createdAt ? formatDate(item.createdAt, DATE_WITH_TIME_FORMAT) : '-',
  },
  {
    title: 'Status',
    keyItem: 'statusOfAccount',
    render: (item: any) => <StatusComponent statusOfComponent={item.status} />,
    renderEdit: (item: any) => (
      <div>
        <Input
          name="statusOfAccount"
          defaultValue={item.statusOfAccount}
          error={showErrorsBlocks['statusOfAccount']}
        />
      </div>
    ),
  },
];

export const ACCOUNT_BALANCE_COLUMNS: Column<any>[] = [
  {
    title: 'Date of registration',
    keyItem: 'registrationApproveDate',
    render: item =>
      item.registrationApproveDate
        ? formatDate(item.registrationApproveDate, DATE_WITH_TIME_FORMAT)
        : '',
  },
  {
    title: 'BCU ID',
    keyItem: 'BCUID',
    render: item => item.BCUID,
  },
  {
    title: 'Product type',
    keyItem: 'productType',
    render: item => item.productType,
  },
  {
    title: 'Amount of product (t)',
    keyItem: 'amountMT',
    render: item =>
      parseFloat(item.volume || '0').toLocaleString('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
    meta: {
      style: {
        textAlign: 'right',
        paddingRight: '75px',
      },
    },
  },
  {
    title: 'Amount of product (MJ)',
    keyItem: 'amountMJ',
    render: item =>
      (item.neatAmountInMJ || 0).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    meta: {
      style: {
        textAlign: 'right',
        paddingRight: '75px',
      },
    },
  },
  {
    title: 'Status',
    keyItem: 'status',
    render: item => <StatusComponent statusOfComponent={item.status} />,
  },
];

export const ACCOUNT_CONTACT_COLUMNS = (
  companyCertifiedFacilitators: string[] | undefined, 
  accountType: CompanyType
) => {
  const isShowPermissionCols = 
    [CompanyType.Forwarder, CompanyType.Airline, CompanyType.Corporate].includes(accountType as any);

  const isAuditorAcc = accountType === CompanyType.Auditor;
  const isFacilitatorAcc = accountType === CompanyType.Facilitator;

  const renderPermissionColumn = (item: any, field: 'isPublicAnonymity' | 'isAllowOnBehalf') => {
    const isCorporateVsForwarderOrAirline = 
      (accountType === CompanyType.Forwarder || accountType === CompanyType.Airline) && 
      item.to?.type === CompanyType.Corporate;

    const isForwarderOrAirlineVsCorporate = 
      accountType === CompanyType.Corporate && 
      (item.to?.type === CompanyType.Forwarder || item.to?.type === CompanyType.Airline);

    if (isCorporateVsForwarderOrAirline || isForwarderOrAirlineVsCorporate) {
      return item?.[field] ? 'Yes' : 'No';
    }

    return null;
  };

  const columns = [
    {
      title: 'Company name',
      render: (item: any) => item.to?.name,
    },
    {
      title: 'Country',
      render: (item: any) => item.to?.country,
    },
    {
      title: 'Company type',
      render: (item: any) => COMPANY_TYPE_NAMES[item.to?.type],
    },
  ];

  if (isShowPermissionCols && !isAuditorAcc) {
    columns.push(
      {
        title: 'Public anonymity',
        render: (item: any) => renderPermissionColumn(item, 'isPublicAnonymity'),
      },
      {
        title: 'Permission on behalf',
        render: (item: any) => renderPermissionColumn(item, 'isAllowOnBehalf'),
      }
    );
  }

  columns.push(
    {
      title: 'Account status',
      render: (item: Contact) => <StatusComponent statusOfComponent={item.to?.status} />,
    }
  )

  if (!isAuditorAcc && !isFacilitatorAcc) {
    columns.push({
      title: 'Permit on behalf',
      render: (item: any) => {
        if (item.to?.type === CompanyType.Facilitator) {
          return companyCertifiedFacilitators?.includes(item.to.id) ? 'Yes' : 'No';
        }
        return null;
      },
    });
  }

  return columns;
};
