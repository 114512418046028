import classNames from 'classnames';
import { Contact } from '@prisma/client';

import {
  DATE_WITH_FULL_MONTH,
  formatDate,
} from '../../core/models/contstants/format-date';
import { TransactionType } from '../../../API/admin/submissions';

// TOCHECK double check contact fromId and toId because appeared a contact with the same company
const checkAnonymity = (item: any, user: any) => {
  return item.receiver?.contacts?.find(
    (contact: Contact) =>
      (contact.toId == user.companyId || contact.toId == item.receiver.id) &&
      contact.toId !== contact.fromId,
  )?.isCreatedByFacilitator;
};

export const COLUMNS = (
  user: any, 
  type?: string, 
  onBCUClick?: (item: any) => void
) => {
  if (type && type === 'Scope1') {
    return [
      {
        title: 'From/by',
        keyItem: 'fromBy',
        render: (item: any) => (
          <div className="transactions__info">
            <div className="transactions__info__details">
              {item.company.name}
            </div>
          </div>
        ),
      },
      {
        title: 'To',
        keyItem: 'to',
        render: (item: any) => {
          const isAnonymous = checkAnonymity(item, user);

          return (
            <div className="transactions__info">
              <div className="transactions__info__details">
                {isAnonymous ? 'Anonymous' : item.receiver?.name}
              </div>
            </div>
          );
        },
      },
      {
        title: 'User',
        keyItem: 'user',
        render: (item: any) => (
          <div className="transactions__info">
            <div className="transactions__info__details">
              {item.sender.firstName} {item.sender.lastName}
            </div>
          </div>
        ),
      },
      {
        title: 'BCU ID',
        keyItem: 'BCUID',
        render: (item: any) => (
          <div className="transactions__info">
            <div 
              className="transactions__info__details transactions__info__link" 
              onClick={() => {
                if (onBCUClick) {
                  onBCUClick(item);
                }
              }}
            >
              {item.credit?.BCUID || item?.newCredit?.BCUID}
            </div>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: 'Register date',
      sort: true,
      keyItem: 'approveDate',
      render: (item: any) => {
        const plus =
          item.type === TransactionType.Register ||
          (item.type === TransactionType.Transfer &&
            user?.companyId === item.receiver?.id);

        const transactionType = item.type === 'Transfer' && plus 
          ? 'Incoming Transfer' 
          : item.type;
        
        return (
          <div className="transactions__info">
            <div className="transactions__info__details">{transactionType}</div>
            <div className="transactions__info__header">
              {formatDate(item.approveDate, DATE_WITH_FULL_MONTH)}
            </div>
          </div>
        )
      },
    },
    {
      title: 'From/by',
      keyItem: 'fromBy',
      render: (item: any) => (
        <div className="transactions__info">
          <div className="transactions__info__details">{item.company.name}</div>
        </div>
      ),
    },
    {
      title: 'To',
      keyItem: 'to',
      render: (item: any) => {
        const isAnonymous = checkAnonymity(item, user);

        return (
          <div className="transactions__info">
            <div className="transactions__info__details">
              {isAnonymous ? 'Anonymous' : item.receiver?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: 'User',
      keyItem: 'user',
      render: (item: any) => (
        <div className="transactions__info">
          <div className="transactions__info__details">
            {item.sender.firstName} {item.sender.lastName}
          </div>
        </div>
      ),
    },
    {
      title: 'BCU ID',
      keyItem: 'BCUID',
      render: (item: any) => {
        const credit = item?.newCredit || item?.credit;

        return (
          <div className="transactions__info">
            <div 
              className="transactions__info__details transactions__info__link" 
              onClick={() => {
                if (onBCUClick) {
                  onBCUClick(item);
                }
              }}
            >
              {credit?.BCUID}
            </div>
          </div>
        )
      },
    },
    {
      title: (
        <span>
          CO<sub>2</sub>eq reduction
        </span>
      ),
      keyItem: 'tonnesOfCO2eq',
      render: (item: any) => (
        <div className="transactions__info">
          <div className="transactions__info__details">
            {(
              item?.newCredit?.tonnesOfCO2eq ||
              item.credit?.tonnesOfCO2eq ||
              0
            ).toLocaleString('en-US', {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}
          </div>
        </div>
      ),
    },
    {
      title: 'Amount',
      keyItem: 'amount',
      render: (item: any) => {
        if (!item.type) {
          return null;
        }

        const plus =
          item.type === TransactionType.Register ||
          (item.type === TransactionType.Transfer &&
            user?.companyId === item.receiver?.id);

        return (
          <div className="transactions__info">
            <div
              className={classNames('transactions__info__volume', {
                plus,
                minus: !plus,
              })}>
              {plus ? '+' : '-'}
              {parseFloat(item.amount || '0').toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}{' '}
              BCUs
            </div>
          </div>
        );
      },
    },
  ];
};
