import React, { useState } from "react";
import FaqIconClose from '../../../../assets/FaqIconOpen.svg'
import FaqIconOpen from '../../../../assets/FaqIconClose.svg'
import { useLocation } from "react-router-dom";

import "./Faq.scss";

interface Question {
  id: number;
  question: string;
  answer: string;
}

interface FaqProps {
  category?: 'general' | 'technical' | 'account_management' | 'bcu' | 'carbon';
}

const faqQuestions = {
  general: [
    {
      id: 1,
      question: "What Is The RSB Book & Claim Registry?",
      answer: "The Registry is a standardised digital application that facilitates the registration of entity accounts according to their role and issuance, holding, transfer, and retirement of Book & Claim Units (BCUs). Within the company accounts, users can perform activities such as registering BCU-related information or executing transactions."
    },
    {
      id: 2,
      question: "How Does The Registry Work?",
      answer: `
          <p>The RSB Registry is a platform that allows suppliers of sustainably certified fuels to separate the sustainability attributes from the physical fuel product. These sustainability attributes are registered as Book & Claim Units (BCUs) in the registry.</p>
          <br />
          <p>Once a BCU is registered, it can be sold or transferred to any buyer, anywhere in the world, regardless of the physical fuel’s location or usage. This system helps track sustainability claims even when the buyer does not physically receive the product.</p>
          <br />
          <p>The RSB Registry performs two key functions:</p>
          <ul>
              <li><strong>Tracking BCUs:</strong> The registry allows the transferring and tracking of BCUs between suppliers and buyers, ensuring transparency throughout the process.</li>
              <li><strong>Issuing Retirement Statements:</strong> When companies use BCUs to make sustainability claims, the registry allows to issue retirement statements, providing credibility to their claims.</li>
          </ul>
          <br />
          <p>The RSB Registry is divided into two sections:</p>
          <br />
          <p><b>Public Section</b>: This area allows anyone to consult the public retirement table and related documentation. It
ensures transparency by allowing stakeholders to verify sustainability claims.</p>
          <br />
          <p><b>Private Section</b>: This section is accessible only to registered users (referred to as “System Users”). Users can log
into their profiles and access different features based on pre-defined role settings. Information within the private
section is visible only to users associated with a specific System Account, ensuring confidentiality.</p>
      `
    }
  ],
  account_management: [
    {
      id: 3,
      question: "How Do I Open A RSB Registry Account?",
      answer: "To open a registry account, an Account Owner (company representative) needs to use the sign-up section of the registry available on the main page. During the sign-up, the Account Owner must provide information such as, company address, company legal identifier, its own position and upload the declaration of commitment."
    },
    {
      id: 4,
      question: "What Address Shall I Use When Signing Up For A Registry Account?",
      answer: "The address used during the registration shall refer to the legal entity or company that registers for registry account. The address during sign-up must also be in accordance whit the address linked to the company legal identifier."
    },
    {
      id: 6,
      question: "What Is The Difference Between An Account Owner And An Account User?",
      answer: `
        <ul>
            <li><strong>Account Owner:</strong> A System User who, with company permission, is responsible for signing up the
  company for a System Account. The Account Owner can manage Account Users and company
  information but cannot perform BCU issuances, transactions, or retirements.</li>
            <li><strong>Account User:</strong> A System User who is invited by the Account Owner. The Account User can perform
  BCU transactions in the Registry but does not have the ability to invite other users or make changes to
  the company’s System Account.</li>
        </ul>
        `
    }
  ],
  bcu: [
    {
      id: 7,
      question: "What Is A BCU?",
      answer: "A BCU (Book & Claim Unit) represents the sustainability attributes of a certified fuel, separate from the physical product. BCUs allow companies to trade or transfer these sustainability attributes independently from the actual fuel. This facilitates the tracking of sustainable fuel use without requiring the physical movement of the fuel itself."
    },
    {
      id: 8,
      question: "Who Can Register And Issue BCUs?",
      answer: "Authorised organisations can register and issue BCUs. The organisation can be of different nature depending on its role in the supply chain (e.g., supplier, blender, distributer, etc.)."
    },
    {
      id: 9,
      question: "How Can I Purchase A BCU?",
      answer: "To purchase BCUs, you’ll first need to establish a commercial agreement outside of the RSB Registry, as it’s primarily a chain of custody tracking system. Once your commercial partnerships are in place, you can start using the Registry to handle the issuance, transfer, and retirement of BCUs."
    },
    {
      id: 10,
      question: "What Is A Retirement Statement?",
      answer: "A retirement statement is an official document issued by the RSB Registry when a Book & Claim Unit (BCU) is retired. A BCU retirement signifies that a specific claim, such as a Scope 1 or Scope 3 claim, made by a specific company, is removed from the Registry and recorded as a retirement statement. Once a BCU is retired, the claim associated with it cannot be further transferred or used again, ensuring that is permanently tied to that company’s sustainability efforts."
    },
    {
      id: 11,
      question: "Can A Retirement Statement Be Changed Once The BCU Has Been Retired?",
      answer: "No, once a BCU has been retired and a retirement statement has been issued, it cannot be changed."
    },
    {
      id: 12,
      question: "What Does It Mean When A Company Is Anonymous In The Retirement Statement?",
      answer: "When a company chooses to remain anonymous in the retirement statement, their name is not displayed publicly in the RSB Registry’s retirement table. However, the retirement is still fully tracked and verified by the system, ensuring the integrity of the sustainability claims while protecting the company’s privacy."
    }
  ],
  carbon: [
    {
      id: 13,
      question: "What Is A Carbon Credit?",
      answer: `
      <p>A carbon credit (or carbon offset) represents the certified reduction or removal of one tonne of carbon dioxide
equivalent (tCO2e) from the atmosphere. This reduction can be seen as an equivalent to the average monthly
carbon footprint of a person living in Europe.</p>
      </br>
      <p>
      Purchasing carbon credits, after reducing emissions as much as possible, is a way to be accountable for their
environmental impact and to take climate action beyond one's own area of influence.
      </p>
      `
    }
  ],
  technical: [
    {
      id: 14,
      question: "Is There An Api To The Registry?",
      answer: "Currently, the registry does not have public APIs available. All actions, such as BCU registering, transfers and retirements, must be carried out through the platform’s user interface."
    }
  ]
};

export const Faq: React.FC<FaqProps> = ({ category = 'general' }) => {
  const { pathname } = useLocation();
  const targetSegment = (pathname.split('/').filter(Boolean).pop() || 'general') as keyof typeof faqQuestions;

  const [activeId, setActiveId] = useState<number | null>(null);

  const toggleAccordion = (id: number) => {
    setActiveId(activeId === id ? null : id);
  };
  
  const questions: Question[] = faqQuestions[targetSegment] || [];

  return (
    <div className="faq-wrap">
      <div className="faq-head-wrap">
        <h3 className="faq-title">Frequently asked questions</h3>
        <div className="faq-desc-wrap">
          <p className="faq-desc">
            These are the most commonly asked questions about RSB Book & Claim
            Registry. Can't find what you're looking for? Contact our support
            team
          </p>
        </div>
      </div>
      <div className="faq-questions">
        {questions.map((item) => (
          <div key={item.id} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAccordion(item.id)}
            >
              <span>{activeId === item.id ? (
                    <img alt="iconOpen" src={FaqIconOpen} />
                ) : (
                    <img alt="iconClose" src={FaqIconClose} />
                )}
              </span>
              <span className="faq-question-text">{item.question}</span>
            </div>
            {activeId === item.id && (
              <div
                className="faq-answer"
                dangerouslySetInnerHTML={{ __html: item.answer }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
