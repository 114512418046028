import GetStarted1 from '../../../../assets/getStarted1.png';
import GetStarted2 from '../../../../assets/getStarted2.png';
import GetStarted3 from '../../../../assets/getStarted3.png';

import './GetStartedSectionStyles.scss';

export const GetStartedSection = () => {
  return (
    <div className='started-section'>
      <div className='started-inner'>
        <h3 className='started-inner-title'>
            Get Started with the RSB Registry in Three Steps
        </h3>

        <div className='started-cards'>
            <img src={GetStarted1} alt='GetStarted' />
            <img src={GetStarted2} alt='GetStarted' />
            <img src={GetStarted3} alt='GetStarted' />
        </div>

        <p className='started-inner-description'>
            If you have questions or need guidance, our {" "}
            <a 
                href="/contact-us" 
                target="_blank" 
                rel="noopener noreferrer"
                className="support-link"
            >
                Customer Support team {" "}
            </a> 
            is ready to help via the Contact Us form in the menu.
        </p>
      </div>
    </div>
  );
}
