import React, { useEffect, useMemo, useState } from 'react';
import { CompanyType, Prisma, UserStatus } from '@prisma/client';
import { useFormik } from 'formik';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, TablePaginationConfig } from 'antd';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';

import {
  useDeleteContactMutation,
  useGetContactListQuery,
  useUpdateContactListMutation,
  useUpdateContactByIdMutation,
} from '../../../API';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useNotification } from '../../../hooks/useNotification';
import { BaseTable } from '../../components/BaseTable/BaseTable';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectUser,
  selectUserAccountData,
} from '../../../store/reducers/user/userReducer';
import { CONTACT_LIST_COLUMNS } from './columns.constant';

import {
  fetchCompanyInfo,
  fetchSetCompanyFacilitators,
} from '../../../API/company';

import './style.scss';

export type Contact = Prisma.ContactGetPayload<{
  include: {
    isAllowOnBehalf: true;
    isPublicAnonymity: true;
    to: {
      select: {
        id: true;
        name: true;
        type: true;
        country: true;
        status: true;
      };
    };
  };
}>;

type ContactFormValues = {
  token: string;
};

interface TableParams {
  pagination?: TablePaginationConfig;
  order?: SortOrder;
  field?: React.Key | readonly React.Key[];
  filters?: Record<string, FilterValue | null>;
}

const ContactList: React.FC = React.memo(() => {
  const toast = useNotification();
  const { data, refetch } = useGetContactListQuery();
  const user = useAppSelector(selectUser);
  const account = useAppSelector(selectUserAccountData);

  const dispatch = useAppDispatch();

  const {
    isFacilitator,
    isUserSuspended,
    isCorporate,
    isShowPermissionCols,
    isTransportCompany,
    isAuditor,
  } = useMemo(() => {
    const isFacilitator = account?.type === CompanyType.Facilitator;
    const isUserSuspended = user?.status === UserStatus.Suspended;
    const isCorporate = account?.type === CompanyType.Corporate;
    const isShowPermissionCols =
      account?.type === CompanyType.Forwarder ||
      account?.type === CompanyType.Airline ||
      account?.type === CompanyType.Corporate;
    const isTransportCompany =
      account?.type === CompanyType.Forwarder ||
      account?.type === CompanyType.Airline;
    const isAuditor = account?.type === CompanyType.Auditor;

    return {
      isFacilitator,
      isUserSuspended,
      isCorporate,
      isShowPermissionCols,
      isTransportCompany,
      isAuditor,
    };
  }, [account, user]);

  const [updateContactList] = useUpdateContactListMutation();
  const [updateContactById] = useUpdateContactByIdMutation();
  const [deleteContactFromList] = useDeleteContactMutation();

  const [items, setItems] = useState((data || []) as Contact[]);
  const [idValueForRemoving, setIdValueForRemoving] = useState<string | null>(
    null,
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
      position: ['bottomRight'],
    },
    field: 'createdAt',
    order: 'ascend',
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Contact> | SorterResult<Contact>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    dispatch(fetchCompanyInfo());
  }, []);

  useEffect(() => {
    setItems(data);
  }, [data]);

  const form = useFormik({
    initialValues: {
      token: '',
    },
    onSubmit: async values => {
      // We want to omit the form validation
      // in this case, so just triggering toasts manually
      if (!values.token) {
        toast.error({
          message: 'Error',
          description: 'Token field is required',
        });
        return;
      }

      const result: any = await updateContactList({
        data: { token: values.token },
      });

      handleFieldChange('token', '');

      if (result.error) {
        if (result.error?.code === 412) {
          return toast.success({
            message: result?.error?.message ? 'Error' : 'Success',
            description: `${result?.error?.message || ''}`,
          });
        }

        return toast.error({
          message: 'Error',
          description: result?.error?.message || 'Unknown error',
        });
      }

      toast.success({
        message: 'Success',
        description: 'Company has been added',
      });
    },
  });

  const handleFieldChange = (field: keyof ContactFormValues, value: string) => {
    form.setFieldValue(field, value);
  };

  const handleSearch = (query: string) => {
    if (query === '') {
      return setItems(data);
    }

    setItems(
      (data as Contact[]).filter((item, index) => {
        return `${item.to?.name} ${item.to?.country} ${item.to?.type} ${item.to?.status}`
          .toLowerCase()
          .includes(query.toLowerCase());
      }),
    );
  };

  const handleRemove = (id: string) => {
    setIdValueForRemoving(id);
  };

  const onRemoveItem = async (value: boolean) => {
    if (value) {
      await deleteContactFromList({ id: idValueForRemoving });
    }
    setIdValueForRemoving(null);
  };

  const handleSwitchFacilitator = async (id: string, isChecked: boolean) => {
    let certifiedFacilitatorIds = [];

    if (isChecked) {
      certifiedFacilitatorIds = [
        ...(account?.certifiedFacilitatorIds || []),
        id,
      ];
    } else {
      certifiedFacilitatorIds = (account?.certifiedFacilitatorIds || []).filter(
        (facilitatorId: string) => facilitatorId !== id,
      );
    }

    await dispatch(fetchSetCompanyFacilitators(id, certifiedFacilitatorIds));
    refetch();
  };

  const handleAllowOnBehalfSwitch = async (id: string, isChecked: boolean) => {
    const result: any = await updateContactById({
      id,
      data: {
        isAllowOnBehalf: isChecked,
      },
    });

    if (result.error) {
      return toast.error({
        message: 'Error',
        description: result?.error?.message || 'Unknown error',
      });
    }

    refetch();
  };

  const handlePublicAnonymitySwitch = async (
    id: string,
    isChecked: boolean,
  ) => {
    const result: any = await updateContactById({
      id,
      data: {
        isPublicAnonymity: isChecked,
      },
    });

    if (result.error) {
      return toast.error({
        message: 'Error',
        description: result?.error?.message || 'Unknown error',
      });
    }

    refetch();
  };

  const columns = CONTACT_LIST_COLUMNS(
    handleRemove,
    account?.certifiedFacilitatorIds,
    handleSwitchFacilitator,
    handleAllowOnBehalfSwitch,
    handlePublicAnonymitySwitch,
    isUserSuspended,
    isFacilitator,
    isAuditor,
    isCorporate,
    isShowPermissionCols,
    isTransportCompany,
  );

  return (
    <div className="contact-list-wrapper">
      <div className="contact-list-token">
        <div className="contact-list-token_header">Contact List</div>
        <div className="contact-list-token_body">
          <Input
            id="field-contact-list-token"
            placeholder="Token"
            size="large"
            required
            value={form.values.token}
            onChange={e => handleFieldChange('token', e.target.value)}
          />
          <Button
            icon={<PlusOutlined />}
            shape="round"
            size="large"
            type="primary"
            color="primary"
            onClick={form.submitForm}
            disabled={isUserSuspended}
            style={{ minWidth: '155px' }}>
            Add contact
          </Button>
        </div>
      </div>

      <div className="contact-list-table">
        <Input
          suffix={<SearchOutlined />}
          className="contact-list-search"
          size="large"
          placeholder="Search"
          onChange={e => handleSearch(e.target.value)}
          allowClear
        />
        <BaseTable
          rowKey={record => record.toId || ''}
          dataSource={items || []}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          columns={columns}
        />
      </div>
      <ConfirmationPrompt
        onClosed={onRemoveItem}
        show={Boolean(idValueForRemoving)}>
        <p>Are you sure you want to delete this contact?</p>
      </ConfirmationPrompt>
    </div>
  );
});

export default ContactList;
