import { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { MyFormValues } from './types';
import FormTitle from '../../FormTitle/FormTitle';
import {
  CONVERSION_PROCESSES,
  COUNTRY,
  END_PRODUCT,
  RAW_MATERIALS,
} from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import PdfFileUpload from '../../PdfFileUpload/PdfFileUpload';
import Field from '../../Field/Field';
import {
  handleEnterLatinAndNumbers,
  handleEnterNumbers,
} from '../../../../../../helpers/handleKeyPress';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';
import {
  selectBaseLines,
  selectFeedstockCerts,
} from '../../../../../../store/reducers/user/abatementBaselinesReducer';
import { checkPoSNumberExist } from '../../../../../../API/credits';

import './GeneralFormStyles.scss';

interface IGeneralFormProps {
  handleClick: () => void;
  onFileUpload: (file: File) => void;
  onDragAndDrop: (file: File | null) => void;
  fileUpload: File | null;
  dragAndDrop: File | null;
}
const GeneralForm: FC<IGeneralFormProps> = ({
  handleClick,
  onFileUpload,
  onDragAndDrop,
  dragAndDrop,
  fileUpload,
}) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);
  const [errorVisible, setErrorVisible] = useState(false);
  const isFileUploaded = formValues.isFileUploaded;

  const baselines = useAppSelector(selectBaseLines);
  const baselinesOptions = (baselines || []).map((b: any) => ({
    label: b.name,
    value: b.id,
  }));

  const feedstockCerts = useAppSelector(selectFeedstockCerts);
  const feedstockCertsOptions = useMemo(
    () =>
      (feedstockCerts || []).map(f => ({
        label: f.name,
        value: f.name,
      })),
    [feedstockCerts],
  );

  const validatePoSNumber = async (fieldPoSNumber: string) => {
    const { data: isPoSIdExist } = await dispatch<any>(
      checkPoSNumberExist(fieldPoSNumber),
    );
    return isPoSIdExist;
  };

  const handleValidation = async (values: any) => {
    const errors = {} as any;

    if (values.endProductType.length === 0) {
      errors.endProductType = 'Product type is required';
    }
    if (values.sustainabilityCertification.length === 0) {
      errors.sustainabilityCertification =
        'Sustainability certification is required';
    }
    if (!values.feedstockCertification) {
      errors.feedstockCertification = 'Feedstock certification is required';
    }
    if (!values.posIssueDate) {
      errors.posIssueDate = 'PoS issue date is required';
    }
    if (!values.rawMaterialFeedstock) {
      errors.rawMaterialFeedstock = 'Raw material is required';
    }
    if (!values.fieldBatchCountry) {
      errors.fieldBatchCountry = 'Country of feedstock origin';
    }
    if (!values.conversionProcess) {
      errors.conversionProcess = 'Feedstock conversion process is required';
    }
    if (!values.sustainabilityCertificationNumber) {
      errors.sustainabilityCertificationNumber =
        'Sustainability certification number is required';
    }
    if (!values.lowerHeatingValue) {
      errors.lowerHeatingValue = 'Lower heating value is required';
    }
    if (!isFileUploaded) {
      errors.fieldUpDateProd = 'Please upload pdf file';
    }
    if (!values.fieldCertif || values.fieldCertif.length < 3) {
      errors.fieldCertif =
        'Field certification body must be longer than or equal to 3 characters';
    }
    if (!values.fieldPoSNumber || values.fieldPoSNumber.length < 3) {
      errors.fieldPoSNumber =
        'Field PoS number of end product must be longer than or equal to 3 characters';
    }

    const isPoSIdExist = values.fieldPoSNumber
      ? await validatePoSNumber(values.fieldPoSNumber)
      : false;

    if (isPoSIdExist) {
      errors.fieldPoSNumber = 'PoS ID already exists in the system'
    }

    formik.setErrors(errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  const getFilteredData = (value: string) => {
    return (
      value.split('').filter(item => item === '.' || item === ',').length >= 2
    );
  };

  const handleFieldChange = (field: keyof MyFormValues, value: string) => {
    dispatch(setField({ fieldName: field, value: value }));
    formik.setFieldValue(field, value);
  };

  const isRequiredFieldEmpty = () => {
    const requiredFields: Array<keyof MyFormValues> = [
      'fieldPoSNumber',
      'endProductType',
      'sustainabilityCertification',
      'feedstockCertification',
      'posIssueDate',
      'conversionProcess',
      'rawMaterialFeedstock',
      'fieldBatchCountry',
      'fieldCertif',
      'sustainabilityCertificationNumber',
      'lowerHeatingValue',
    ];
    return (
      requiredFields.some(field => !formik.values[field]) || !isFileUploaded
    );
  };

  const handleGeneralForm = async () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    const errors = await handleValidation(formik.values);
    const isErrors = !!Object.keys(errors).length;

    setErrorVisible(hasEmptyRequiredFields || isErrors);

    if (!hasEmptyRequiredFields && !isErrors) {
      handleClick();
    }
  };

  return (
    <div>
      <FormTitle title="1. General information" />
      <form onSubmit={formik.handleSubmit}>
        <DefaultSelect
          required
          inputId="field1"
          name="field1"
          label="End product type"
          placeholder="End product type"
          error={formik.errors.endProductType}
          isSearchable
          options={END_PRODUCT as any}
          value={formik.values.endProductType}
          onChange={(option: any) => {
            handleFieldChange('endProductType', option.value);
          }}
        />
        <DefaultSelect
          required
          isSearchable
          inputId="field2"
          name="field2"
          label="Sustainability certification"
          placeholder="Sustainability certification"
          error={formik.errors.sustainabilityCertification}
          options={baselinesOptions as any}
          value={baselinesOptions.find(
            ({ value }) => value === formik.values.sustainabilityCertification,
          )}
          onChange={(option: any) => {
            handleFieldChange('sustainabilityCertification', option.value);
          }}
        />
        <Field
          required
          type="text"
          label="Sustainability certification number"
          placeholder="Sustainability certification number"
          id="sustainabilityCertificationNumber"
          showOptional={false}
          value={formik.values.sustainabilityCertificationNumber}
          onChange={e =>
            handleFieldChange(
              'sustainabilityCertificationNumber',
              e.target.value,
            )
          }
          onBlur={formik.handleBlur}
          error={formik.errors.sustainabilityCertificationNumber}
          errorVisible={errorVisible}
        />
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          type="text"
          label="Certification body"
          placeholder="Certification body"
          id="fieldCertif"
          required
          value={formik.values.fieldCertif}
          onChange={e => handleFieldChange('fieldCertif', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldCertif}
          errorVisible={errorVisible}
        />
        <DefaultSelect
          required
          isSearchable
          inputId="feedstockCertification"
          name="feedstockCertification"
          label="Feedstock certification"
          placeholder="Feedstock certification"
          error={formik.errors.feedstockCertification}
          options={feedstockCertsOptions as any}
          value={formik.values.feedstockCertification}
          onChange={(option: any) => {
            handleFieldChange('feedstockCertification', option.value);
          }}
        />
        <div className="second">
          <DefaultSelect
            required
            isSearchable
            inputId="field3"
            name="field3"
            label="Feedstock (raw material)"
            placeholder="Feedstock (raw material)"
            error={formik.errors.rawMaterialFeedstock}
            options={RAW_MATERIALS as any}
            value={formik.values.rawMaterialFeedstock}
            onChange={(option: any) => {
              handleFieldChange('rawMaterialFeedstock', option.value);
            }}
          />
        </div>
        <DefaultSelect
          required
          isSearchable
          inputId="fieldBatchCountry"
          name="fieldBatchCountry"
          label="Country of feedstock origin"
          placeholder="Country of feedstock origin"
          error={formik.errors.fieldBatchCountry}
          options={COUNTRY as any}
          value={formik.values.fieldBatchCountry}
          onChange={(option: any) => {
            handleFieldChange('fieldBatchCountry', option.value);
          }}
        />
        <DefaultSelect
          required
          isSearchable
          inputId="field4"
          name="field4"
          label="Feedstock conversion process"
          placeholder="Feedstock conversion process"
          error={formik.errors.conversionProcess}
          options={CONVERSION_PROCESSES as any}
          value={formik.values.conversionProcess}
          onChange={(option: any) => {
            handleFieldChange('conversionProcess', option.value);
          }}
        />
        <Field
          handleKeyPress={handleEnterNumbers}
          required
          label="Lower heating value [MJ/kg]"
          placeholder="Lower heating value [MJ/kg]"
          id="lowerHeatingValue"
          showOptional={false}
          value={formik.values.lowerHeatingValue}
          onChange={e => handleFieldChange('lowerHeatingValue', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.lowerHeatingValue}
          errorVisible={errorVisible}
          formatOnBlur
          decimals={3}
        />
        <Field
          required
          type="text"
          label="PoS number of end product"
          placeholder="PoS number of end product"
          id="fieldPoSNumber"
          value={formik.values.fieldPoSNumber}
          onChange={e => handleFieldChange('fieldPoSNumber', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.fieldPoSNumber}
          errorVisible={errorVisible}
        />
        <Field
          required
          handleKeyPress={handleEnterNumbers}
          type="date"
          label="PoS of end product issue date"
          id="posIssueDate"
          value={formik.values.posIssueDate}
          max={format(new Date(), 'yyyy-MM-dd')}
          onChange={e => handleFieldChange('posIssueDate', e.target.value)}
          onBlur={formik.handleBlur}
          error={formik.errors.posIssueDate}
          errorVisible={errorVisible}
        />
        <PdfFileUpload
          setFieldValue={formik.setFieldValue}
          onDragAndDrop={onDragAndDrop}
          onFileUpload={onFileUpload}
          label="PoS pdf upload of end product"
          required
          id="fieldUpDateProd"
          name="fieldUpDateProd"
          error={formik.errors.fieldUpDateProd}
          errorVisible={errorVisible}
        />
        <NextButton handleClick={handleGeneralForm} text="Next step" />
      </form>
    </div>
  );
};

export default GeneralForm;
