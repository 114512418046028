import './HeroSectionStyles.scss';

export const HeroSection = () => {
  return (
    <div className='hero-section'>
      <div className='hero-inner'>
        <h1 className='hero-inner-title'>
          Empowering Sustainability Claims <br/> with the RSB Book & Claim Registry
        </h1>

        <h5 className='hero-inner-description'>
          A secure backbone for tracking and transferring sustainability attributes globally, the RSB <br/> Registry enables transparent and credible Book & Claim Unit (BCU) transactions.
        </h5>
      </div>
    </div>
  );
}
