import React, { useMemo } from 'react';
import { Pagination } from 'antd';
import './Pagination.scss';

type Props = {
  total: number;
  page: number;
  limit: number;
  changePage: (page: number) => void;
  lock: boolean;
};

export const PaginationNavigator: React.FC<Props> = React.memo(
  ({ total, page, changePage, limit = 50, lock }) => {
    const noData = useMemo(() => total === 0, [total]);

    return (
      <div className={`pagination_navigation ${lock ? 'lock' : ''} ${noData ? 'noData' : ''}`}>
        <Pagination
          current={page}
          total={total}
          pageSize={limit}
          disabled={lock}
          onChange={changePage}
          showSizeChanger={false}
          showLessItems={true}
        />
      </div>
    );
  },
);
