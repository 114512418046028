import { Link } from 'react-router-dom';

import './DemoSectionStyles.scss';

export const DemoSection = () => {
  return (
    <div className='demo-section'>
      <div className='demo-inner'>
        <div>
            <h3 className='demo-inner-title'>
                Discover the full scale of RSB's Registry capabilities 
            </h3>

            <span className='orange-line'></span>
        </div>

        <button className='demo-btn'>
          <Link to="/contact-us" target="_blank" rel="noopener noreferrer">
            Request a demo
          </Link>
        </button>
      </div>
    </div>
  );
}
