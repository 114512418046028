import React from 'react';
import { Select } from 'antd';
import { Col, Row } from 'react-bootstrap';
import './Pagination.scss';

type Props = {
  limit: number;
  changeLimit: (newLimit: number) => void;
};

type Ops = {
  readonly value: number;
  readonly label: string;
};

const options: Ops[] = [
  { value: 10, label: '10 / page' },
  { value: 20, label: '20 / page' },
  { value: 50, label: '50 / page' },
  { value: 100, label: '100 / page' },
];

export const ShowBy: React.FC<Props> = React.memo(({ limit, changeLimit }) => {
  const onChange = (value: number) => {
    changeLimit(value);
  };

  return (
    <div className="show_by">
      <Row>
        <Col>
          <Select
            value={limit}
            onChange={onChange}
            options={options.map(opt => ({ label: opt.label, value: opt.value }))}
            style={{ width: 120 }}
          />
        </Col>
      </Row>
    </div>
  );
});
