import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { actions } from './fetchProvider/fetch';
import {
  URL_ABATEMENT_BASELINES,
  URL_ADD_USER_ACCOUNT,
  URL_ADMIN_CREDIT_TRANSACTIONS_HISTORY,
  URL_ADMIN_EDIT_FEE,
  URL_ADMIN_FEE,
  URL_BCU_REGISTRATION,
  URL_COMMON_COUNTRIES,
  URL_COMPANY_CREDITS,
  URL_COMPANY_INCOMING_CREDITS,
  URL_CONTACT_DELETE,
  URL_CONTACTS_LIST_GET,
  URL_CONTACTS_LIST_POST,
  URL_CONTACT_PATCH,
  URL_CREDITS_RETIRE,
  URL_LIST_USERS_ACCOUNT,
  URL_POS_FILE,
  URL_RETIREMENT,
  URL_SCOPE1_INFORMATION_REQUEST,
  URL_SCOPE1_INFORMATION_REQUEST_COUNT,
  URL_SCOPE1_INFORMATION_REQUEST_LIST,
  URL_SCOPE1_INFORMATION_REQUEST_UPDATE,
  URL_TRANSFER,
  URL_UPDATE_USER_ACCOUNT,
  URL_CONTACT_LIST_ADMIN_GET,
} from './urls/urls';
import { AxiosPromise } from 'axios';

interface Country {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}

export const baseQuery =
  (): BaseQueryFn<
    {
      action: () => AxiosPromise<any>;
    },
    unknown,
    unknown
  > =>
  async ({ action }) =>
    action().then(
      response => ({ data: response }),
      error => ({
        error: error?.response?.data || error,
      }),
    );

export const transformResponse = (value: any) => {
  return value?.data?.data || value?.data || value;
};

export const api = createApi({
  baseQuery: baseQuery(),
  keepUnusedDataFor: 30,
  tagTypes: [
    'Airports',
    'RetirementStatements',
    'CreditTransactions',
    'Notes',
    'Countries',
    'CompanyUsers',
    'CompanyFees',
    'Contacts',
    'AbatementBaselines',
    'Credits',
    'CreditRetirement',
    'BCURegistration',
    'Retirements',
    'Transfers',
    'InformationRequests',
    'InformationRequestsCount',
    'IncomingCreditCount',
  ],
  endpoints: builder => ({
    getCountries: builder.query<Country[], void>({
      providesTags: ['Countries'],
      query: () => ({
        action: () => actions.get(URL_COMMON_COUNTRIES),
      }),
      transformResponse,
    }),
    //
    getCompanyUsersList: builder.query<any, void>({
      providesTags: ['CompanyUsers'],
      query: () => ({
        action: () => actions.get(URL_LIST_USERS_ACCOUNT),
      }),
      transformResponse,
    }),
    inviteCompanyUser: builder.mutation<any, any>({
      invalidatesTags: ['CompanyUsers'],
      query: data => ({
        action: () => actions.post(URL_ADD_USER_ACCOUNT, { payloads: data }),
      }),
      transformResponse,
    }),
    updateCompanyUser: builder.mutation<any, any>({
      invalidatesTags: ['CompanyUsers'],
      query: ({ action, user }) => ({
        action: () =>
          actions.post(URL_UPDATE_USER_ACCOUNT, {
            payloads: { action, id: user },
          }),
      }),
      transformResponse,
    }),
    getContactList: builder.query<any, void>({
      providesTags: ['Contacts'],
      query: () => ({
        action: () =>
          actions.get(URL_CONTACTS_LIST_GET),
      }),
      transformResponse,
    }),
    getAdminContactList: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        action: () =>
          actions.get(URL_CONTACT_LIST_ADMIN_GET.replace(':id', id)),
      }),
      transformResponse,
    }),
    updateContactList: builder.mutation<any, any>({
      query: ({ data }) => ({
        action: () => actions.post(URL_CONTACTS_LIST_POST, { payloads: data }),
      }),
      transformResponse,
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            api.util?.updateQueryData(
              'getContactList',
              undefined,
              () => response,
            ),
          );
        } catch {}
      },
    }),
    updateContactById: builder.mutation<any, { id: string; data: Partial<{ isAllowOnBehalf: boolean; isPublicAnonymity: boolean }> }>({
      query: ({ id, data }) => ({
        action: () => actions.patch(URL_CONTACT_PATCH.replace(':id', id), { payloads: data }),
      }),
      transformResponse,
      async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        try {
          const { data: response } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              'getContactList',
              undefined,
              (draft) => {
                const index = draft.findIndex((contact: { id: string; }) => contact.id === id);
                if (index !== -1) {
                  draft[index] = { ...draft[index], ...response };
                }
              }
            ),
          );
        } catch (error) {}
      },
    }),    
    deleteContact: builder.mutation<any, any>({
      invalidatesTags: ['Contacts'],
      query: ({ id }) => ({
        action: () => actions.delete(URL_CONTACT_DELETE.replace(':id', id), {}),
      }),
      transformResponse,
    }),
    //
    getAbatementBaselines: builder.query<any, void>({
      providesTags: ['AbatementBaselines'],
      query: () => ({
        action: () => actions.get(URL_ABATEMENT_BASELINES),
      }),
      transformResponse,
    }),
    getCredits: builder.query<any, void>({
      providesTags: ['Credits'],
      query: () => ({
        action: () => actions.get(URL_COMPANY_CREDITS),
      }),
      transformResponse,
    }),
    getCreditRetirement: builder.query<any, any>({
      providesTags: ['CreditRetirement'],
      query: ({ id, ...payloads }) => ({
        action: () =>
          actions.post(URL_CREDITS_RETIRE.replace(':id', id), { payloads }),
      }),
      transformResponse(response: any) {
        return response?.data;
      },
    }),
    getPdfToDownload: builder.query<any, any>({
      query: ({ id }) => ({
        action: () =>
          actions.get(URL_POS_FILE.replace(':id', id), {
            responseType: 'blob',
          } as any),
      }),
      transformResponse(response: any) {
        return response?.data;
      },
    }),
    getBCURegistration: builder.query<any, any>({
      providesTags: ['BCURegistration'],
      query: ({ registrationData }) => ({
        action: () => {
          const data = new FormData();
          Object.entries(registrationData).forEach(([key, value]) => {
            data.append(key, value as any);
          });

          return actions.post<any>(URL_BCU_REGISTRATION, {
            payloads: data,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        },
      }),
    }),
    getCreditHistory: builder.query<any, any>({
      // providesTags: ['CreditHistory'],
      query: ({ id, ...payloads }) => ({
        action: () =>
          actions.get(URL_ADMIN_CREDIT_TRANSACTIONS_HISTORY.replace(':id', id)),
      }),
      transformResponse(response: any) {
        return response?.data;
      },
    }),
    //
    saveTransfer: builder.mutation<any, any>({
      invalidatesTags: ['Transfers'],
      query: ({ id, ...payloads }) => ({
        action: () =>
          actions.post(URL_TRANSFER.replace(':id', id), { payloads }),
      }),
      transformResponse,
    }),
    //
    saveRetirement: builder.mutation<any, any>({
      invalidatesTags: ['Retirements'],
      query: ({ id, ...payloads }) => ({
        action: () =>
          actions.post(URL_RETIREMENT.replace(':id', id), { payloads }),
      }),
      transformResponse,
    }),
    //
    countInformationRequests: builder.query<any, void>({
      providesTags: ['InformationRequestsCount'],
      query: () => ({
        action: () => actions.get(URL_SCOPE1_INFORMATION_REQUEST_COUNT),
      }),
      transformResponse,
    }),
    countIncomingCredit: builder.query<any, void>({
      providesTags: ['IncomingCreditCount'],
      query: () => ({
        action: () => actions.get(URL_COMPANY_INCOMING_CREDITS),
      }),
      transformResponse,
    }),
    getCompanyFees: builder.query<
      any,
      { companyId: string; page: number; perPage: number }
    >({
      keepUnusedDataFor: 60,
      providesTags: (r, e, { companyId, page, perPage }) => [
        { type: 'CompanyFees', id: `${companyId}_${page}_${perPage}` },
      ],
      query: ({ companyId, page, perPage }) => ({
        action: () =>
          actions.get(URL_ADMIN_FEE.replace(':id', companyId.toString()), {
            queries: {
              skip: (page - 1) * perPage,
              limit: perPage,
            },
          }),
      }),
      transformResponse,
    }),
    createCompanyFee: builder.mutation<any, any>({
      invalidatesTags: ['CompanyFees'],
      query: ({ companyId, data }) => ({
        action: () =>
          actions.post(URL_ADMIN_FEE.replace(':id', companyId), {
            payloads: data,
          }),
      }),
      transformResponse(value: any) {
        return value?.data?.data || value?.data || value;
      },
    }),
    editCompanyFee: builder.mutation<any, any>({
      invalidatesTags: ['CompanyFees'],
      query: ({ companyId, feeId, data }) => ({
        action: () =>
          actions.post(
            URL_ADMIN_EDIT_FEE.replace(':id', companyId).replace(
              ':feeId',
              feeId,
            ),
            {
              payloads: data,
            },
          ),
      }),
      transformResponse(value: any) {
        return value?.data || value;
      },
    }),
    deleteCompanyFee: builder.mutation<any, any>({
      invalidatesTags: ['CompanyFees'],
      query: ({ companyId, feeId }) => ({
        action: () =>
          actions.delete(
            URL_ADMIN_EDIT_FEE.replace(':id', companyId).replace(
              ':feeId',
              feeId,
            ),
          ),
      }),
      transformResponse(value: any) {
        return value?.data || value;
      },
    }),
    getInformationRequests: builder.query<any, void>({
      providesTags: ['InformationRequests'],
      query: () => ({
        action: () => actions.get(URL_SCOPE1_INFORMATION_REQUEST_LIST),
      }),
      transformResponse,
    }),
    sendInformationRequest: builder.mutation<any, any>({
      invalidatesTags: ['InformationRequests', 'InformationRequestsCount'],
      query: ({ ...payloads }) => ({
        action: () =>
          actions.post(URL_SCOPE1_INFORMATION_REQUEST, { payloads }),
      }),
      transformResponse,
    }),
    updateInformationRequest: builder.mutation<any, any>({
      invalidatesTags: ['InformationRequests', 'InformationRequestsCount'],
      query: ({ id, ...payloads }) => ({
        action: () =>
          actions.post(
            URL_SCOPE1_INFORMATION_REQUEST_UPDATE.replace(':id', id),
            { payloads },
          ),
      }),
      transformResponse,
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetContactListQuery,
  useGetAdminContactListQuery,
  useUpdateContactListMutation,
  useUpdateContactByIdMutation,
  useDeleteContactMutation,
  useGetAbatementBaselinesQuery,
  useGetCreditRetirementQuery,
  useGetPdfToDownloadQuery,
  useLazyGetAbatementBaselinesQuery,
  useGetCreditsQuery,
  useLazyGetContactListQuery,
  useLazyGetCreditRetirementQuery,
  useLazyGetCountriesQuery,
  useLazyGetCreditsQuery,
  useLazyGetPdfToDownloadQuery,
  useGetBCURegistrationQuery,
  useLazyGetBCURegistrationQuery,
  useSaveRetirementMutation,
  useSaveTransferMutation,
  useGetInformationRequestsQuery,
  useLazyGetInformationRequestsQuery,
  useSendInformationRequestMutation,
  useCountIncomingCreditQuery,
  useCountInformationRequestsQuery,
  useLazyCountInformationRequestsQuery,
  useUpdateInformationRequestMutation,
  useGetCompanyUsersListQuery,
  useUpdateCompanyUserMutation,
  useLazyGetCompanyUsersListQuery,
  useInviteCompanyUserMutation,
  useGetCompanyFeesQuery,
  useLazyGetCompanyFeesQuery,
  useLazyCountIncomingCreditQuery,
  useDeleteCompanyFeeMutation,
  useEditCompanyFeeMutation,
  useCreateCompanyFeeMutation,
  useGetCreditHistoryQuery,
  useLazyGetCreditHistoryQuery,
} = api;
