import { FC } from 'react';
import './stepsStyle.scss';
import Step from './Step/Step';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBaseLines } from '../../../../../store/reducers/user/abatementBaselinesReducer';

interface IBCUFormStepsProps {
  step: number;
  handleCurrentStep: (step: number) => void;
  neatProductSource: string;
  batchNumber: number;
  isVisibleStep: number[];
}

const BCUFormSteps: FC<IBCUFormStepsProps> = ({
  step,
  handleCurrentStep,
  neatProductSource,
  batchNumber,
  isVisibleStep,
}) => {
  const fields = useAppSelector(state => state.form.fields);
  const baselines = useAppSelector(selectBaseLines);
  const selectedBaseline = (baselines || []).find(b => b.id === fields.sustainabilityCertification);
  const hasCORSIAStep = selectedBaseline && selectedBaseline.name.length > 0 && String(selectedBaseline.name).includes('CORSIA');

  return (
    <div className="steps-reg">
      <Step
        number={1}
        title="Step 1"
        description="General Information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 1}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={2}
        title="Step 2"
        description="Site Information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 2}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={3}
        title="Step 3"
        description="Product Information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 3}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      <Step
        number={4}
        title="Step 4"
        description="Batch Information"
        currentStep={step}
        handleCurrentStep={handleCurrentStep}
        isFinishedStep={step > 4}
        blockStep={neatProductSource === ''}
        isVisibleStep={isVisibleStep}
      />
      {hasCORSIAStep && (
        <Step
          number={5}
          title="Step 5"
          description="ICAO CORSIA Information"
          currentStep={step}
          handleCurrentStep={handleCurrentStep}
          isFinishedStep={step > 5}
          blockStep={neatProductSource === ''}
          isVisibleStep={isVisibleStep}
        />
      )}
    </div>
  );
};

export default BCUFormSteps;
