import React, { useState } from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../../assets/header_logo_rsb.svg';
import { NavigationTabs } from '../NavigationTabs/NavigationTabs';
import { HeaderActions } from './components/HeaderActions/HeaderActions';
import { TABS } from './models/tabs.constant';
import { DASHBOARD_ROUTES } from '../../../structure/routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import { Role } from '../../../structure/models/user/user';
import { CompanyType } from '../../../structure/models/company/company';

import './HeaderStyle.scss';

export const Header: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const selectedUserData = useAppSelector(selectUser);

  const [isMenuActive, setMenuActive] = useState(false);

  const handleClick = () => {
    navigate('/');
  };

  const showRetirementTab =
    selectedUserData?.role !== Role.SuperAdmin ||
    selectedUserData?.company?.type !== CompanyType.Supplier;
  const modifiedTabs = showRetirementTab
    ? TABS
    : TABS.filter(tab => tab.path !== DASHBOARD_ROUTES.RETIREMENT);

  const onMobileMenuToggle = () => {
    setMenuActive(!isMenuActive);
  };

  return (
    <header className="header">
      <div className="main-header">
        <div className="main-header__company-name">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="logo" width="160" />
            </Link>
          </div>
          <h6 className='header-title' onClick={handleClick}>
            Book & Claim Registry
          </h6>
        </div>
        <HeaderActions />
      </div>
      <div className="header__navigation">
        <NavigationTabs tabs={modifiedTabs} />
        <div className="header__burger" onClick={onMobileMenuToggle}>
          <span></span>
        </div>
      </div>
      {isMenuActive && (
        <div className="header__mobile-menu">
          <span
            className={classNames(
              'material-icons-outlined',
              'header__mobile-close-button',
            )}
            onClick={onMobileMenuToggle}>
            close
          </span>
          <NavigationTabs tabs={modifiedTabs} onClick={onMobileMenuToggle} />
        </div>
      )}
    </header>
  );
});
