import React, { useState } from 'react';
import { Modal, Input, message } from 'antd';
import { fetchFeedback } from '../../../API/feedback';
import { useAppDispatch } from '../../../store/hooks';
import { useNotification } from '../../../hooks/useNotification';
import Draggable from 'react-draggable';

import './Feedback.scss';

const { TextArea } = Input;

export const Feedback: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useNotification();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isDragging, setIsDragging] = useState(false);

  const showModal = () => {
    // if (!isDragging) {
      setIsModalVisible(true);
    // }
  };

  const handleSendFeedback = async () => {
    if (!feedback.trim()) {
      message.error('Feedback cannot be empty');
      return;
    }

    setIsSubmitting(true);

    try {
      await dispatch(fetchFeedback(feedback));

      toast.success({
        message: 'Success',
        description: 'Thank you for your feedback!',
      });

      setIsModalVisible(false);
      setIsSubmitting(false);
      setFeedback('');
    } catch (error) {
      toast.error({
        message: 'Error',
        description: 'There was an issue sending your feedback. Please try again later.',
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFeedback('');
  };

  return (
    <>
      <div className="feedback" onClick={showModal}>
        Feedback
      </div>
      {/* <Draggable
        onStart={() => {
          setIsDragging(true);
        }}
        onStop={() => {
          setIsDragging(false);
        }}
      >
        <div className="feedback-container">
          <div className="drag-handle">
            {[...Array(6)].map((_, i) => (
              <span key={i} className="drag-dot" />
            ))}
          </div>

          <span
            className="material-icons-outlined header__icon icon-wrap"
            onClick={showModal}
          >
            lightbulb
            <span className="inner-icon">F</span>
          </span>
        </div>
      </Draggable> */}

      <Modal
        title="Feedback"
        visible={isModalVisible}
        onOk={handleSendFeedback}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Close"
        confirmLoading={isSubmitting}
      >
        <TextArea
          required
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Enter your feedback here"
        />
      </Modal>
    </>
  );
};
