import { MultiValue } from 'react-select';
import { DeleteOutlined } from '@ant-design/icons';

import { Input } from '../../../../../components/Form/Input';
import { EditableColumn } from '../../../../../components/Table/Table';
import { InputNumber } from 'antd';

export const getColumns = (
  onChange?: (
    value: { name: string; value: string | MultiValue<any> },
    id: number,
  ) => void,
  onDelete?: (id: number) => void,
  isEditable = false,
  isAuditor?: boolean,
): EditableColumn<any>[] => {
  const onValueChange = (
    name: string,
    value: string | MultiValue<any>,
    id: number,
  ) => {
    const formattedValue = Array.isArray(value)
      ? value.map(item => item.label)
      : value;
    if (onChange) onChange({ name, value: formattedValue }, id);
  };

  return [
    {
      title: 'Scheme',
      keyItem: 'name',
      render: item => item.name,
      ...(isEditable
        ? {
            renderEdit: (item: any) => (
              <Input
                name="name"
                placeholder="Scheme Name"
                value={item.name}
                onChange={e => onValueChange('name', e.target.value, item.id)}
              />
            ),
          }
        : {}),
    },
    {
      title: (
        <span>
          Baseline value gCO<sub>2</sub>eq/MJ
        </span>
      ),
      keyItem: 'baselineValue',
      render: item => (
        <span style={{ paddingRight: '60px' }}>
          {parseFloat(item.baselineValue).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      ),
      ...(isEditable
        ? {
            renderEdit: (item: any) => (
              <div className="reference-values-input">
                <InputNumber<number>
                  defaultValue={item.baselineValue}
                  step={0.01}
                  placeholder="Baseline Value"
                  formatter={val => (val || 0)?.toString()?.replaceAll(',', '')}
                  onChange={e =>
                    onValueChange('baselineValue', e?.toString() || '', item.id)
                  }
                />
              </div>
            ),
          }
        : {}),
    },
    ...(isEditable
      ? [
          {
            title: 'Remove',
            keyItem: 'name',
            hidden: isAuditor,
            render: (item: any) => (
              <DeleteOutlined onClick={() => onDelete && onDelete(item.id)} />
            ),
          },
        ]
      : []),
  ];
};
