import { FC, useEffect } from 'react';
import './totalStyles.scss';
import SummaryField from '../../SummaryField';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import convertToNumber from '../../../../../../helpers/convertToNumber';
import { setField } from '../../../../../../store/reducers/user/bcuFormReducer';
import { selectBaseLines } from '../../../../../../store/reducers/user/abatementBaselinesReducer';
import { Big } from '../../../../../../helpers/big';

interface Props {
  title: string;
}

// TODO: this whole logic is unbelievably bad, calculations should be delegated to the backend.
const TotalSum: FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(state => state.form);
  const batchSummary = useAppSelector(state => state.form.batchForm);
  const sumCO2 = batchSummary.reduce((total: number, batchItem) => {
    return total + convertToNumber(batchItem.fieldCO2);
  }, 0);
  const sumTonnes = batchSummary.reduce((total: number, batchItem) => {
    return total + convertToNumber(batchItem.fieldTonnes);
  }, 0);
  const sumMJ = batchSummary.reduce((total: number, batchItem) => {
    return total + convertToNumber(batchItem.fieldMJ);
  }, 0);
  const baselines = useAppSelector(selectBaseLines) || [];
  const selectedBaseline = baselines.find(
    ({ id }) => id === formValues.fields.sustainabilityCertification,
  );
  const baselineValue = selectedBaseline ? selectedBaseline?.baselineValue : 0;

  const averageCO2 = new Big(sumCO2 / batchSummary.length).round(2).toNumber();
  const averageTonnes = new Big(sumTonnes / batchSummary.length)
    .round(5)
    .toNumber();
  const averageMJ = new Big(sumMJ / batchSummary.length).round(5).toNumber();

  const weightedGHGEmissionReduction = batchSummary.reduce((acc, batchItem) => {
    const neatAmountInMJ = convertToNumber(batchItem.fieldMJ);
    const GHGEmissionReduction = convertToNumber(batchItem.fieldGHG);
    const weighted = neatAmountInMJ * GHGEmissionReduction;

    return acc + weighted;
  }, 0);

  const averageWeightedGHG = new Big(weightedGHGEmissionReduction / sumMJ)
    .round(3)
    .toNumber();

  let weightedTonnesOfCO2eq = 0;

  if (baselineValue !== undefined && baselineValue > 0) {
    weightedTonnesOfCO2eq = new Big(
      ((averageMJ * baselineValue * (averageWeightedGHG / 100)) / 1000000) *
        batchSummary.length,
    )
      .round(3)
      .toNumber();
  }

  useEffect(() => {
    dispatch(
      setField({ fieldName: 'LCAGHGValue', value: averageCO2.toFixed(2) }),
    );
    dispatch(
      setField({
        fieldName: 'GHGEmissionReduction',
        value: averageWeightedGHG.toFixed(3),
      }),
    );
    dispatch(
      setField({
        fieldName: 'volume',
        value: new Big(averageTonnes).toFixed(3),
      }),
    );
    dispatch(
      setField({
        fieldName: 'neatAmountInMJ',
        value: new Big(averageMJ).toFixed(0),
      }),
    );
  }, [dispatch, averageCO2, averageWeightedGHG, averageTonnes, averageMJ]);

  return (
    <div className="form-sum1">
      <h2 className="form-title">{title}</h2>
      <SummaryField
        nameProp={
          <span>
            Average LCA GHG value (gCO<sub>2</sub>eq/MJ)
          </span>
        }
        selected={averageCO2.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
        border
      />
      <SummaryField
        nameProp="Average GHG Emission Reduction (weighted, %)"
        selected={averageWeightedGHG.toLocaleString('en-US', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        })}
        border
      />
      <SummaryField
        nameProp="Average neat amount (t)"
        selected={averageTonnes.toLocaleString('en-US', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        })}
        border
      />
      <SummaryField
        nameProp="Average neat amount (MJ)"
        selected={parseFloat(new Big(averageMJ).toString()).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          },
        )}
        border
      />
      <SummaryField
        nameProp="Total neat amount (t)"
        selected={sumTonnes?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        })}
        border
      />

      <SummaryField
        nameProp={
          <span>
            Total CO<sub>2</sub>eq reduction (t)
          </span>
        }
        selected={weightedTonnesOfCO2eq.toLocaleString('en-US', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        })}
        border
      />
    </div>
  );
};

export default TotalSum;
