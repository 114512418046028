import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface AbatementBaselinesState {
  baseLines: any[];
  feedstockCerts: any[];
  incentives: any[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AbatementBaselinesState = {
  baseLines: [],
  status: 'idle',
  feedstockCerts: [],
  incentives: [],
};

const abatementBaselinesReducer = createSlice({
  name: 'abatement-base-lines',
  initialState,
  reducers: {
    setBaseLines: (state, action) => {
      state.baseLines = action.payload;
    },
    setFeedstockCerts: (state, action) => {
      state.feedstockCerts = action.payload;
    },
    setIncentives: (state, action) => {
      state.incentives = action.payload;
    },
  },
});

export const { 
  setBaseLines, 
  setFeedstockCerts, 
  setIncentives 
} = abatementBaselinesReducer.actions;

export const selectBaseLines = (state: RootState) =>
  state.abatementBaselines.baseLines;
export const selectFeedstockCerts = (state: RootState) =>
  state.abatementBaselines.feedstockCerts;
export const selectIncentives = (state: RootState) =>
  state.abatementBaselines.incentives;

export default abatementBaselinesReducer.reducer;
