import './Pricing.scss';

export const Pricing: React.FC = () => {
    return (
        <div className="price-wrap">
            <div className="price-head-wrap">
                <h3 className="price-title">Pricing</h3>
                <p className="price-desc">Explore our pricing options</p>
            </div>
            <div className="price-content">
                <p>Based on the account role on the RSB Registry, an invoice will be issued after successfully applying for a registry account. 
                <br />
                For more details on the pricing regulations, please refer to the <a className="price-link" href="https://rsb.org/wp-content/uploads/2024/02/24-02-23-RSB-BC-Registry-Fee-Schedule-v.1.1.pdf" target="_blank" rel="noopener noreferrer">Fee Schedule and Terms & Conditions</a>.
                </p>
            </div>
        </div>
    )
}