import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers/user/userReducer';
import './AccountFees.scss';
import { useGetCompanyFeesQuery } from '../../../API';
import { Table, TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Fee, CompanyType } from '@prisma/client';
import { ColumnType } from 'antd/lib/table';
import { formatDate } from '../../core/models/contstants/format-date';
import { useCombinedFilter } from '../../../hooks/useLocalStorageFilter';
import { TABLES } from '../../../constants/tables';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

export const AccountFees: FC = () => {
  const user = useAppSelector(selectUser);

  const supplierFields = [
    'volumeFeePaid',
    'volumeFee',
    'bcuIssued',
    'issueRate',
  ];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  const [page, setPage] = useCombinedFilter(TABLES.FEES, 'page', 1);
  const [limit, setLimit] = useCombinedFilter(TABLES.FEES, 'limit', 50);

  const {
    data: feesResult,
    isLoading,
    isFetching,
  } = useGetCompanyFeesQuery({
    companyId: user?.company?.id || '',
    page: page || 1,
    perPage: limit || 50,
  });

  const fees: Fee[] = feesResult?.results || [];

  useEffect(() => {
    setTableParams(t => ({
      ...t,
      pagination: {
        ...t.pagination,
        total: feesResult?.count || 0,
      },
    }));
  }, [feesResult]);

  const isSupplier =
    user && user.company && user.company.type === CompanyType.Supplier;

  const columns: ColumnType<Fee>[] = [
    {
      title: 'Period',
      dataIndex: 'period',
      render: (_: any, item: Fee) => {
        return `${typeof item.period !== 'undefined' ? item.period : ''}`;
      },
    },
    {
      title: 'Dates',
      dataIndex: 'date',
      render: (_: any, item: Fee) => {
        return `${formatDate(item.start!)} - ${formatDate(item.end!)}`;
      },
    },
    {
      title: 'Annual fee',
      dataIndex: 'accountFee',
      render: (_: any, item: Fee) => {
        return `${
          typeof item.accountFee !== 'undefined'
            ? item.accountFee.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : ''
        }`;
      },
    },
    {
      title: 'Annual paid',
      dataIndex: 'accountFeePaid',
      render: (_: any, item: Fee) => {
        return (
          <Tag
            bordered={false}
            color={item.accountFeePaid ? 'green' : 'default'}>
            {item.accountFeePaid ? 'Yes' : 'No'}
          </Tag>
        );
      },
    },
    {
      title: 'BCU issued',
      dataIndex: 'bcuIssued',
      render: (_: any, item: Fee) => {
        return `${
          typeof item.bcuIssued !== 'undefined'
            ? item.bcuIssued.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })
            : ''
        }`;
      },
    },
    {
      title: 'BCU issued rate',
      dataIndex: 'issueRate',
      render: (_: any, item: Fee) => {
        return `${
          typeof item.issueRate !== 'undefined'
            ? item.issueRate.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : ''
        }`;
      },
    },
    {
      title: 'Volume fee',
      dataIndex: 'volumeFee',
      render: (_: any, item: Fee) => {
        return `${
          typeof item.volumeFee !== 'undefined' ? item.volumeFee.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) : ''
        }`;
      },
    },
    {
      title: 'Volume paid',
      dataIndex: 'volumeFeePaid',
      render: (_: any, item: Fee) => {
        return (
          <Tag
            bordered={false}
            color={item.volumeFeePaid ? 'green' : 'default'}>
            {item.volumeFeePaid ? 'Yes' : 'No'}
          </Tag>
        );
      },
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Fee> | SorterResult<Fee>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };


  return (
    <div className="account-fees" style={{ marginLeft: 20, marginRight: 20 }}>
      <Table
        rowKey={record => record.id || ''}
        loading={isLoading || isFetching}
        dataSource={fees}
        onChange={handleTableChange}
        pagination={{
          current: page,
          pageSize: limit,
          total: feesResult?.count,
          showSizeChanger: true,
          onChange: handlePageChange,
          onShowSizeChange: (_, size) => handleLimitChange(size),
        }}
        columns={
          isSupplier
            ? columns
            : columns.filter(
                ({ dataIndex }) =>
                  !dataIndex || !supplierFields.includes(dataIndex as string),
              )
        }
      />
    </div>
  );
};
